import React from "react";

function Home() {
	return (
		<div className="home-page">
			<video className="home-vid" autoPlay muted loop controls>
				<source src="media/videos/lawki_web.webm" type="video/webm"></source>
			</video>
		</div>
    );
}

export default Home;
