import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigation, Footer, Home, About, Press, Resources, SearchTerms, Algorithms } from "./components";



ReactDOM.render(
    <Router>
    	<Navigation />
		<div className="main-content">
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/about" element={<About />} />
				<Route path="/press" element={<Press />} />
				<Route path="/resources" element={<Resources />} />
				<Route path="/algorithms" element={<Algorithms />} />
				<Route path="/searchphrases" element={<SearchTerms />} />
			</Routes>
		</div>
    	<Footer />
    </Router>,
  document.getElementById('root')
);