import React from "react";
import { NavLink } from "react-router-dom";

import shuffleArray from "./shuffleArray";


function Resources() {

	const showCaption = (e) => {
		const caption = e.currentTarget.nextElementSibling;
		if(caption.style.height){
			caption.style.height = null;
		} else {
			caption.style.height = caption.scrollHeight + 'px';
		}

	}


	let images = [
		["ARK_LAWKI_A-Kerk_Okt 2021_PT-7400_LowRes.jpg",    "LAWKI-NOW at the Akerk - Photo (c) Peter Tijhuis 2021"],
		["ARK_LAWKI_A-Kerk_Okt 2021_PT-7410_LowRes.jpg",    "LAWKI-NOW at the Akerk - Photo (c) Peter Tijhuis 2021"],
		["ARK_LAWKI_A-Kerk_Okt 2021_PT-7426_LowRes.jpg",    "LAWKI-NOW at the Akerk - Photo (c) Peter Tijhuis 2021"],
		["ARK_LAWKI_A-Kerk_Okt 2021_PT-7470_LowRes.jpg",    "LAWKI-NOW at the Akerk - Photo (c) Peter Tijhuis 2021"],
		["ARK_LAWKI_A-Kerk_Okt 2021_PT-7475_LowRes.jpg",    "LAWKI-NOW at the Akerk - Photo (c) Peter Tijhuis 2021"],
		["ARK_LAWKI_A-Kerk_Okt 2021_PT-7561_LowRes.jpg",    "LAWKI-NOW at the Akerk - Photo (c) Peter Tijhuis 2021"],
		["ARK_LAWKI_A-Kerk_Okt 2021_PT-7570_LowRes.jpg",    "LAWKI-NOW at the Akerk - Photo (c) Peter Tijhuis 2021"],
		["R Klap_LAWKI_Sept 2021_PT-6579_LowRes.jpg",       "LAWKI-NOW at the Akerk - Photo (c) Peter Tijhuis 2021"],
		["R Klap_LAWKI_Sept 2021_PT-6598_LowRes.jpg",       "LAWKI-NOW at the Akerk - Photo (c) Peter Tijhuis 2021"],
		["R Klap_LAWKI_Sept 2021_PT-6612_LowRes.jpg",       "LAWKI-NOW at the Akerk - Photo (c) Peter Tijhuis 2021"],
		["R Klap_LAWKI_Sept 2021_PT-6637_LowRes.jpg",       "LAWKI-NOW at the Akerk - Photo (c) Peter Tijhuis 2021"],
		["R Klap_LAWKI_Sept 2021_PT-6701_LowRes.jpg",       "LAWKI-NOW at the Akerk - Photo (c) Peter Tijhuis 2021"],
		["LAWKI - (c) Hanneke Wetzer, 2021-16.jpg",         "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI - (c) Hanneke Wetzer, 2021-22.jpg",         "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI - (c) Hanneke Wetzer, 2021-33.jpg",         "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI - (c) Hanneke Wetzer, 2021-34.jpg",         "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI - (c) Hanneke Wetzer, 2021-36.jpg",         "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI - (c) Hanneke Wetzer, 2021-46.jpg",         "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI - (c) Hanneke Wetzer, 2021-53.jpg",         "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI - (c) Hanneke Wetzer, 2021-63.jpg",         "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI - (c) Hanneke Wetzer, 2021-75.jpg",         "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI opening - (c) Hanneke Wetzer, 2021-10.jpg", "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI opening - (c) Hanneke Wetzer, 2021-14.jpg", "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI opening - (c) Hanneke Wetzer, 2021-1.jpg",  "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI opening - (c) Hanneke Wetzer, 2021-22.jpg", "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI opening - (c) Hanneke Wetzer, 2021-26.jpg", "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI opening - (c) Hanneke Wetzer, 2021-29.jpg", "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI opening - (c) Hanneke Wetzer, 2021-31.jpg", "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI opening - (c) Hanneke Wetzer, 2021-35.jpg", "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI opening - (c) Hanneke Wetzer, 2021-52.jpg", "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI opening - (c) Hanneke Wetzer, 2021-55.jpg", "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
		["LAWKI opening - (c) Hanneke Wetzer, 2021-68.jpg", "LAWKI-ALIVE at Mu Hybrid Art House - Photo (c) Hanneke Wetzer 2021"],
	];


	images = shuffleArray(images);

	let image_elements = images.map(function (data, index) {
		const image_url = 'media/images/images_resources/' + data[0];
		return <div key={index} >
			<img className="press-image" src={image_url} onClick={showCaption}></img>
			<p className="caption">{data[1]}</p>
		</div>
	});


	const showVideo = (video) => {
		const vc = document.getElementById('video-container');
		const vp = document.getElementById('video-player');
		const right_panel = document.getElementsByClassName('panel-right')[0];
		const source = document.getElementById('video-source');

		vc.style.display = 'block';
		vp.style.display = 'block';
		vp.style.height = right_panel.clientHeight + 'px';

		if (source.getAttribute('src') == video) {
			return;
		}

		source.setAttribute('src', video);
		source.setAttribute('type', 'video/mp4');

		vp.load();
		vp.play();
	}

	const closeVideo = () => {
		const vc = document.getElementById('video-container');
		const vp = document.getElementById('video-player');
		vp.pause();
		vc.style.display = 'none';
	}



	return (
		<div className="split-panels">
			<div className="panel-left">
				<h2>Resources</h2>
				<p className="intro">LAWKI reflects on knowledge production in a contemporary, highly mediated world. Below you can discover the main topics and terms connected to the research and production of the different LAWKI iterations. By clicking on the links with the <img className="play-button" src="/media/images/icon-play.png"></img>  icon you can check out interviews with the members of ARK about these topics.</p>
				
				<p className="resource-desc"><strong className="vid-play">Architecture</strong> – Every iteration of LAWKI is modular, working with the space it is situated in, each time creating new spatial compositions, and meaning. Instead of reaffirming the two-dimensionality of a screen, the clusters with LED tiles in MU create their own architectural shapes, rather than following the architecture itself.</p>
				
				<p className="resource-desc"><strong className="vid-play"><span className="load-video" onClick={() => showVideo('media/videos/Roosje-collab.mp4')}>ARK <img className="play-button" src="/media/images/icon-play.png"></img></span></strong> – The team of ARK are a interdisciplinary, international group of artists, designers, developers and researchers. <NavLink to="/about">Read more...</NavLink> </p>
				
				<p className="resource-desc"><strong className="vid-play"><span className="load-video" onClick={() => showVideo('media/videos/Roosje_Val_Sen-Aby.mp4')}>Artificial Intelligence (AI) <img className="play-button" src="/media/images/icon-play.png"></img></span></strong> – Several algorithms based on artificial intelligence create the compositions for LAWKI–ALIVE, taking inspiration from the methodology of the famous library of art historian <a href="https://en.wikipedia.org/wiki/Aby_Warburg" target="_blank" rel="noopener noreferrer">Aby Warburg (1866-1929)</a>. The library’s books were not organised by alphabetical order or genre but relative to what Warburg called ‘neighbours’. In the same way, the AI algorithms organise their knowledge by associating neighbours in a high-dimensional space. By doing so, the AI’s internal library challenges traditional classifications.</p>
				
				<p className="resource-desc"><strong className="vid-play"><span className="load-video" onClick={() => showVideo('media/videos/Zuzanna_languages-bias.mp4')}>Bias and Perspective <img className="play-button" src="/media/images/icon-play.png"></img></span></strong> – Trying to overcome a solely Western perspective on Life As We Know It, we developed a system that roams the internet from all corners of the world. Google Translate (an AI algorithm) translates our search phrases into 13 of the most widely used languages. Afterwards, the (AI-based) search algorithms of YouTube and Dailymotion use the translations to discover videos. All these AI algorithms have been mainly designed for Western-adjacent audiences and trained on their data. Because of this, there is a lot of ‘algorithmic bias’ and some search phrases get lost in translation. Bias cannot be avoided.</p>
				
				<p className="resource-desc"><strong className="vid-play">Choir Text</strong> – The choir text for the music is written around the rhythms of hymns and wants to speak about environmental devastation and political struggle for existence in the sea of data, privacy, and digital consciousness. They are about the past and the future mixed into one, wishing for everyone to become a prophet in their own time.</p>
				
				<p className="resource-desc"><strong className="vid-play">Cori Spezzati</strong> – LAWKI–NOW uses the polyphonic technique cori spezzati (‘split choirs’), a musical style from the 16th century Venetian San Marco Basilica. The Venetian polychoral style was a type of music of the late Renaissance and early Baroque eras which involved spatially separate choirs singing in alternation. This composing and singing technique uses the architecture to create an acoustic delay between choirs. It is a little bit like the first use of a spatial surround sound.</p>
				
				<p className="resource-desc"><strong className="vid-play"><span className="load-video" onClick={() => showVideo('media/videos/Senka_ideal-dataset.mp4')}>Data Fairness <img className="play-button" src="/media/images/icon-play.png"></img></span></strong> – LAWKI–ALIVE consists of videos that anyone can find and access on the internet. New stories are forged from fragments that were originally created in social and cultural contexts but become isolated once scraped into our database. To ensure that all videos’ contexts enjoy equal representation, we explicitly encourage the Random Walk to create streams that are diverse in terms of aspects like language and viewers.</p>
				
				<p className="resource-desc"><strong className="vid-play"><span className="load-video" onClick={() => showVideo('media/videos/Valentin_dataset.mp4')}>Data Load <img className="play-button" src="/media/images/icon-play.png"></img></span></strong> – Every minute, five hundred hours of video are uploaded to YouTube. Every day, two billion active users watch more than a billion hours of video. We have truly arrived in an age where the amount of information is beyond comprehension. LAWKI–ALIVE turns the space into an audiovisual and interactive tool, asking how computers can help us to understand such immense amounts of data by creating new stories from streams of data for and together with us.</p>
				
				<p className="resource-desc"><strong className="vid-play"><span className="load-video" onClick={() => showVideo('media/videos/Arran-soundscape.mp4')}>Generative Loops <img className="play-button" src="/media/images/icon-play.png"></img></span></strong> – Short fragments of audio are sampled from the streaming videos to construct an ever evolving library of sounds that an algorithm uses to construct a repetitive loop of music, which can sometimes be heard like a car radio coming in and out of tune. An algorithm was trained to 'listen’ to audio and clump similar sounds together, and this organises the library of samples. Exploring this library in a circular way produces a sequence of samples to construct the audio loop, and slowly morphing the path progresses the loop into a new, but related, form.</p>
				
				<p className="resource-desc"><strong className="vid-play"><span className="load-video" onClick={() => showVideo('media/videos/Senka_purple-filter.mp4')}>Green/Purple Washing <img className="play-button" src="/media/images/icon-play.png"></img></span></strong> – The videos are infused with the color green (LAWKI-NOW) and purple (LAWKI-ALIVE), according to their online popularity or view count. The more views the video has generated, the more purple you see in the video. Videos that fall through the cracks of the attention economy of streaming platforms are emphasized the further you go through LAWKI–ALIVE.</p>
				
				<p className="resource-desc"><strong className="vid-play"><span className="load-video" onClick={() => showVideo('media/videos/Arran_sensors.mp4')}>Interactivity <img className="play-button" src="/media/images/icon-play.png"></img></span></strong> – LAWKI–ALIVE is not a video with a play button but a living and algorithmically controlled organism that constantly changes under the influence of the presence and movements of the audience. By moving between the screens, the visitors themselves create new compositions. In this 'walk of wonder,' the spectator is a consumer of the spectacle and part of the process as well as a result.</p>
				
				<p className="resource-desc"><strong className="vid-play">LAWKI</strong> – The title 'LAWKI' is an acronym for 'Life As We Know It' and builds on a short film that member’s of ARK collective made for the Nederlands Film Festival in September 2020. This film uses green screen technology and was built in ARK’s studio, using a POV (‘Point Of View’)-camera and reflects on our post-pandemic surveillance culture, with our deep fakes, our dark thoughts, our drone views, our politics, our activism, and our lives in the virtual real. This piece was curated and commissioned by Pauline Dresscher. This film can be watched on our <NavLink to="/">homepage</NavLink>.</p>
				
				<p className="resource-desc"><strong className="vid-play"><span className="load-video" onClick={() => showVideo('media/videos/lawki_now_web.webm')}>LAWKI-NOW <img className="play-button" src="/media/images/icon-play.png"></img></span></strong> – LAWKI–NOW was the second iteration of LAWKI, situated and performed in the Akerk in Groningen for the Noorderlicht Photofestival during the summer of 2021. The installation worked with the church's acoustics to create a polyvocal composition with a choir as a focal element of the score. The choir wore hooded capes with LAWKI–NOW visuals and the text when they were performing. When not performing, these costumes would remain on a kinetic sculpture, signifying the presence of humans among the non-human. Following up on the green screens of the LAWKI film, all of LAWKI–NOW was infused with green.</p>
				
				<p className="resource-desc"><strong className="vid-play">LAWKI–ALIVE</strong> – LAWKI–ALIVE is the third iteration of the LAWKI series. In contrast to LAWKI–NOW, this installation works with the industrial space of MU to create a fragmented, immersive and non-linear broadcasting station. It focuses on movement, choreography and life sciences and the framing of the body in relation to the screens.</p>
				
				<p className="resource-desc"><strong className="vid-play">LAWKI Costumes</strong> – The installation extends to ‘background actors’ of MU that are wearing printed tunics embedded with the LAWKI–ALIVE imagery and text. While wearing these, the actors perform LAWKI by being enveloped by it’s information. The costumes are handmade to order and cost 150,- per piece. Info at the MU desk or via ark@lawki.online.</p>
				
				<p className="resource-desc"><strong className="vid-play">LAWKI in Numbers</strong><br />
					8 LCD screens<br />
					8 speakers<br />
					9 team members of ARK<br />
					9 million characters of metatext<br />
					12 tons of hardware<br />
					13 carpets<br />
					13 languages<br />
					38 meters of ethernet cable<br />
					76 meters of sensor cable<br />
					96 LED tiles<br />
					301 hours of video<br />
					700+ search phrases<br />
					1.238 million words of metatext<br />
					992.7 million total online views<br />
					262 meters of electricity cable<br />
					35 pairs of hands built and maintained LAWKI<br />
					First issue day of LAWKI: 26 September 2020<br />
					First issue day of LAWKI–NOW: 7 August 2021<br />
					First issue day of LAWKI–ALIVE: 14 October 2021<br />
					There are currently 3 iterations of LAWKI (November 2021)<br /></p>
				
				<p className="resource-desc"><strong className="vid-play">Performing the Space</strong> – New Way Vogue-performer and architect <a href="https://www.instagram.com/miilakaarina/?hl=en" target="_blank" rel="noopener noreferrer">Miila Kaarina</a> was asked to perform in the installation. She infuses the sharp architectural forms and angles into the physical and metaphorical flexibility of ‘<a href="https://en.wikipedia.org/wiki/Vogue_(dance)" target="_blank" rel="noopener noreferrer">new way vogue</a>’. The style of New Way takes inspiration from posing and the 2D world of fashion imagery, which it translates into the 3D space through the body. Miila Kaarina offers a queer and experimental perspective to the geometry of the architecture of LAWKI–ALIVE.</p>
				
				<p className="resource-desc"><strong className="vid-play">MIDI</strong> – MIDI is a technical standard that describes a communications protocol, digital interface, and electrical connectors that connect a wide variety of electronic musical instruments, computers, and related audio devices for playing, editing and recording music. The MIDI protocol understands the pitch, length and velocity of the sound but it does not read any information about the graphic notations which frequently indicate the use of unconventional playing techniques.</p>
				
				<p className="resource-desc"><strong className="vid-play">Musical jigsaw</strong> – The music for LAWKI-NOW is created by an algorithm that composes musical passages from short melodic building blocks in order to solve the 'musical jigsaw'. The musical building units are written, composed, and translated into MIDI format - a digital representation of the music that the machine can work with.</p>
				
				<p className="resource-desc"><strong className="vid-play">Musical library</strong> – The algorithms for LAWKI-NOW learned the characteristics and intricacies of the compositions being fed to it. The library contains dozens of different musical units - short fragments of melodies, chords, drone sounds and percussive elements to build music with. Once the algorithms have selected the units to build the music with, the audio is assembled into a breathing piece of music.</p>
				
				<p className="resource-desc"><strong className="vid-play"><span className="load-video" onClick={() => showVideo('media/videos/Valentin_random-walk.mp4')}>Random Walk <img className="play-button" src="/media/images/icon-play.png"></img></span></strong> – Once the AI algorithms have created a high-dimensional space of neighbourhoods, an algorithm walks through it like an imaginary being through a library. In search for metaphorical food like a Slime Mold in its natural environment, the algorithm moves from point to point in the space and creates the stream of videos, as points represent videos. Unlike a real Slime Mold, however, the algorithms’ steps are not driven by intention but by randomness, hence the name Random Walk.</p>
				
				<p className="resource-desc"><strong className="vid-play"><span className="load-video" onClick={() => showVideo('media/videos/Louis_search-phrases.mp4')}>Search Phrases <img className="play-button" src="/media/images/icon-play.png"></img></span></strong> – The very beginning of our process consists of search queries that people look up on the internet. They are terms, events and phenomena written by an imaginary user in order to retrieve video content. The search phrases are exemplary of the way online spaces form and concretize our words to fit an image-led culture. <NavLink to="/searchphrases">Explore...</NavLink></p>
				
				<p className="resource-desc"><strong className="vid-play">Soundscape</strong> – A deep, enveloping soundscape is generated through capturing and modulating the frequencies present in the video's audio stream. Infinite sustains, massive reverbs, pitch shifting and granular synthesis builds a basis of the musical architecture played across 8 speakers surrounding the piece. You can listen to an excerpt of the soundscape by clicking on LAWKI-Radio at the bottom of the page.</p>
				
				<p className="resource-desc"><strong className="vid-play"><span className="load-video" onClick={() => showVideo('media/videos/Teoniki_making-music-with-AI.mp4')}>Score <img className="play-button" src="/media/images/icon-play.png"></img></span></strong> – The music written and sung for LAWKI–ALIVE nestles in the walls and architecture of MU. The space becomes an instrument itself, which deconstructs and digitally digests the musical elements of the score: the rhythmic elements, the singing and the audio snippets of the scraped videos.</p>
				
				<p className="resource-desc"><strong className="vid-play">Slime Mold</strong> – Single-celled slime molds ooze their way across surfaces, searching for food. As they spread and grow, they naturally organize themselves into networks of tube-like structures that quickly and efficiently connect their disparate food sources. We use the foraging skills of the slime mold in our Random Walks, which find their paths through the dataset according to the same principles: efficient exploration of space balanced with rich and diverse sources of food.</p>
				
				<p className="resource-desc"><strong className="vid-play">Systems Overview</strong> – LAWKI-ALIVE is a constellation of algorithms, systems and hardware that communicate and collaborate together to produce the final form. The heart of the installation is a Linux computer that drives the LED tiles and runs the algorithms, and a Raspberry Pi to relay the sensor data forward. The 8-channel audio is generated with a PureData patch and interfaces the speakers via four separate stereo mixers. All other algorithms are coded in Python. <NavLink to="/algorithms">Explore...</NavLink></p>
				
				<p className="resource-desc"><strong className="vid-play">Trilogy</strong> – Each iteration of LAWKI is a reinterpretation of the space it is presented in, as well as the moment it is created. This is visible and audible in the ever-changing data that is used by the LAWKI-algorithms. For MU, the focus of the dataset has a focus on movement, and life sciences.</p>
				
				<p className="resource-desc"><strong className="vid-play"><span className="load-video" onClick={() => showVideo('media/videos/Louis_zuza__non-lineair-stories.mp4')}>Visual Literacy <img className="play-button" src="/media/images/icon-play.png"></img></span></strong> – In a time of visual oversaturation and consumption, LAWKI–ALIVE rejects the capitalist mode of consumption as it doesn't produce more content or tries to form its own style. Instead it changes the lens through which we see the current media by offering a vocabulary of visual media.</p>
				
				<p className="resource-desc"><strong className="vid-play">Vocalists</strong> – Aleksandra Baj has a voice with a bright, sharp and trumpet-like timbre. Her voice was used to record a soprano and alto voice with the intention to sound both innocent and as if she has the knowledge of an ancient witch. Wiktor Gniazdowski’s voice ranges from tenor to bass and his classically trained voice strengthens the bond with choral tradition.</p>
			</div>
			<div className="panel-right">
				<div id="video-container">
					<video id="video-player" controls><source id='video-source' src=''></source></video>
					<div id="video-close" onClick={closeVideo} style={{backgroundImage: "url(/media/images/icon-close.png"}}></div>
				</div>

				{image_elements}

			</div >
		</div>
	);
}

export default Resources;
