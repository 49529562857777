import React from "react";

function Press() {

	function toggleArticle(content) {
		if (content.style.maxHeight) {
			closeArticle(content);
		} else {
			openArticle(content);
		}
	}

	function closeArticle(content) {
		content.style.maxHeight = null;
	}

	function openArticle(content) {
		content.style.maxHeight = content.scrollHeight + "px";
	}

	function titleOnClick(e) {
		const content = e.target.nextElementSibling.nextElementSibling;
		toggleArticle(content);
		setTimeout(() => scrollToContent(content), 500);
	}

	function imageOnClick(id) {
		for (const content of document.getElementsByClassName("collapsible-content")) {
			if (content.getAttribute("id") === id) {
				toggleArticle(content);
				setTimeout(() => scrollToContent(content), 500);
			} else {
				closeArticle(content);
			}
		}
	}

	function scrollToContent(content) {
		const pos = content.parentElement.offsetTop;
		// console.log(pos);

		const panel = document.getElementsByClassName('panel-left')[0];
		panel.scrollTop = pos - 50;

	}

	return (
		<div className="split-panels">
			<div className="panel-left">
				<h2>Press</h2>
				<p className="intro">Select press articles around LAWKI. Click on the titles to expand, or on the images on the right.</p>
				<ul>
					<li className="article">
						<p>NRC, 21-09-2021</p>
						<h3 className="collapsible" onClick={titleOnClick}>At photo festival Noorderlicht the end of the world is postponed until further notice</h3>
						<p>Author: Tracy Metz</p>
						<div className="collapsible-content" id="article-nrc">
							<p><strong>'The Makeable Mind' is the theme of the international photo festival Noorderlicht. There is much beauty to be seen at several locations, but there is a lack of focus.</strong></p>
							<p>'The end of the world is postponed until further notice.' This is the last sentence of the final chapter of the multimedia artwork Life As We Know It - NOW (LAWKI) by the artists' collective ARK. The five-part-installation fills half of the A-Church in Groningen. It is the main part of the international photo festival Noorderlicht, and one of three works commissioned by the festival this year with the theme The Makeable Mind.</p>
							<p>LAWKI is an overwhelming experience in image and sound. Using hundreds of search terms, an algorithm retrieves images from the unlimited data streams around us, from disasters and politicians, migrants and motorways, game characters and faces, crackling explosions and cat videos. The soundscape is 'composed' by another algorithm that superimposes the singing of two singers in a stereo effect.</p>
							<p>Whenever the exhibition space is empty, the screens shift to a bright green resting position. It is the movement of the visitor that brings them to life and makes them produce sound. Visitors set the performance and the composition in motion, with the algorithms as their "personal advisor" as Klap puts it, but these continue in the realm of the artificial intelligence that does its thing entirely without us. It is the world as we know it, intangible, terrifying and poetic at the same time. Goddamn, the end has been postponed for a while.</p>
							<p><strong>Populist Politician</strong></p>
							<p>Artist Daniel Mayrit gives a completely different interpretation of the theme The Makeable Mind. In the ironic project One of Yours, he campaigns as a populist politician with whom the minds of his supporters are like wax in his hands, including a fake biography on his site in which Trump and Brexit obviously feature prominently. Fake it till you make it: Mayrit manages to present himself convincingly as the embodiment of what he calls 'hyper-leadership', including selfies for a staged rally and a photo in which he sits fraternally next to Berlusconi. He has mastered the right gestures and the right look, and has provided his presentation in the A-Church with balloons and a cardboard cut-out.</p>
							<p>After this edition Noorderlicht will continue as the Fries-Groningen biennial, alternating with Breda Photo, the photography biennial in Brabant. Hopefully that will benefit the focus. This year too the event is fascinating, but diffuse in location and theme. There are more than 70 participants at no fewer than 19 locations, including pumping stations along the Frisian coast, a new art centre in Westerwolde in Groningen and soon also in the museum Sound and Vision in The Hague.</p>
							<p>Besides the malleable spirit, another theme runs through Noorderlicht: migration. Perhaps because this is a more tangible subject, these works touch more than the digital ones. That certainly applies to the movingly beautiful animation films by American Tamara Shogaolu, who received a Golden Calf nomination in 2019. Half a Life shows the struggle of a gay activist in Egypt - where Shogaolu spent time as an exchange student - and his love-hate relationship with his country. Another Dream is a VR film about a lesbian couple that flees to the Netherlands after the Egyptian revolution.</p>
							<p>Miraculously, there is one project that manages to unite both themes. In the annex of the Belvedere Museum in Heerenveen, David Magnussen's This Too Shall Pass is on display. His main character is Yashar Maghsoudi, who fled Iran when he was thirteen. Under the name 'Nighetboy', he was a master of the video game World of Warcraft, and managed to cash in his accumulated points to pay his human traffickers.</p>
							<p>Finally, another project commissioned by Noorderlicht, (Un)earth by photographers Awoiska van der Molen and Bart Lunenburg, brings visitors back with their feet in the clay of Groningen. Their collaboration can be seen in the Oude Camerabioscoop in Groningen and at sponsor BPD Cultuurfonds in Amsterdam. Van der Molen grew up in Groningen and went back there to portray the earthquake area. Bart Lunenburg brought bricks from that area to his studio. There he built a wall, which he depicted on a canvas 7 metres wide. In the Oude Camerabioscoop, he placed a layer of red grit of the same bricks, in the patterns of brickwork, under the photographs of Van der Molen. Noorderlicht went around the world and came home again.</p>
						</div>
					</li>

					<li className="article">
						<p>Eindhovens Dagblad, 14-10-2021</p>
						<h3 className="collapsible" onClick={titleOnClick}>LAWKI-Alive at MU: are we still in charge of our imagination?</h3>
						<p>Author: Simone Vos</p>
						<div className="collapsible-content" id="article-dagblad-eindhovens">
							<p><strong>EINDHOVEN - During DDW, the installation LAWKI-Alive at MU presents a totally fragmented video landscape. The question is: how do you move through this as a visitor?</strong></p>
							<p>How do online videos on the craziest topics and the algorithms behind them influence our daily lives? Are we still in control over our own imagination? And what is the role of machines in this? In ARK's exhibition LAWKI (Life As We Know It) - Alive, on view in MU from October 14, these questions are amply being addressed. The answers to these questions? You decide.</p>
							<p>“We want to create awareness among visitors. We all click on all kinds of websites and scroll through social media. We often don't know what's behind it at all", says Roosje Klap, founder of ARK. This international collective consisting of a growing multidisciplinary team of designers, researchers and AI experts set up the installation LAWKI-Alive at MU. Earlier this year, they were at the Noorderlicht Festival in Groningen with LAWKI-Now. The same concept, but then in the A-Church, so a slightly different setting and atmosphere.</p>
							<p>Upon entering the exhibition space in MU, you see various screens showing seemingly random video collages. Besides these videos, you hear a soundscape composed on the spot by a computer. It seems completely random, but it's not. Behind the images and music are algorithms programmed by ARK based on search terms used last year on video channels such as YouTube and Dailymotion. The videos that go with these terms form the basis of the database for the expo. The algorithm connects the images and sounds with each other.</p>
							<p>Complicated? Well, that's how it works in everyday life too, according to ARK. “We show that our knowledge of the world has always been fragmented. The internet reinforces this. It is not possible for us humans to understand exactly how technology works. We find it interesting to do research on that, LAWKI is part of that."</p>
							<p>The music you hear in the exhibition space is constructed in the same way as the videos. ARK put fragments of music pieces in a database and the computer turns them into completely new compositions. Interaction with the audience is clearly necessary here, because as soon as someone enters the space, the music starts. No movement, no music. People give input and the computer gets to work. Just like in real life.</p>
						</div>
					</li>

					<li className="article">
						<p>De Telegraaf, 9-9-2021</p>
						<h3 className="collapsible" onClick={titleOnClick}>Iconoclasm in Groningen: Information avalanche takes centre stage at Noorderlicht Festival</h3>
						<p>Author: Fabian Melchers</p>
						<div className="collapsible-content" id="article-telegraaf">
							<p><strong>Whether you choose Twitter or TikTok, mainstream media or more obscure news platforms; the daily stream of images is barely comprehensible. What does this abundance of impressions do to us? In diverse exhibitions, VR installations and other works of art, this question resonates throughout the Noorderlicht Photo Festival.</strong></p>
							<p>The international festival, which started last weekend in Groningen, concentrates on more than just photography nowadays. Festival director Kees van der Meiden explains how he wants to be part of the contemporary world. "Our focus remains on media that is accessible and recognisable for everyone. Nowadays that includes a lot more than just photography. Not only do we all take pictures, but we also film and photoshop."</p>
							<p>"At school they mostly teach about comprehensive reading," notes van der Meiden. "But in fact this should also include understanding how to look. You get to see such an explosion of images on a daily basis. You have to know what you are seeing. Otherwise you end up believing in the wrong images.</p>
							<p><strong>Capitol storming</strong></p>
							<p>Festival curator Paulien Dresscher nods in agreement. "I was recently watching a short film about the storming of the Capitol. How Trump rushed people to take back their country, followed by real chaos and violence. It doesn't matter anymore if something is initially fake news. Whenever people respond to this fake news in the real world, viewers need their own comprehensive viewing skills."</p>
							<p>Next month Noorderlicht can also be found in Friesland and The Hague, but for now the different locations in Groningen already have a lot to offer. Indeed, the Akerk alone will keep you busy for quite a while. For instance, half of the main location is taken up by LAWKI (Life As We Know It)-Now. With its large screens and sound effects, this multimedia installation draws visitors into a disruptive jumble of Internet videos.</p>
							<p>The technology behind it is perhaps the most stimulating. Roosje Klap and her team at ARK programmed a system that uses artificial intelligence to collect search terms and then convert them into video images. If there are floods in China, there is a good chance that the church in Groningen will be filled with disaster images. At the same time cat videos may pop up, although these are more rare than the addictive misery we all seem to be searching for on the Internet.</p>
							<p>LAWKI-NOW is divided into five chapters. Hidden sensors ensure that splintered pieces of sound and vision only come at you once you are close to the work. An additional element is the poisonous green glow that covers the videos. The greener a video, the more it has been viewed on the Internet. "That way you can see even better which images catch on," Klap explains. "If everyone else apparently sees the same image, what is unique about your personal experience? You allow yourself to be moulded into a certain reality, without realising that reality can be different."</p>
							<p><strong>Other side of technology</strong></p>
							<p>Dresscher explains that the festival theme The makeable mind is not just about the bad side of visual culture. "It is also a wonderful world that you can indulge in. Where inspiring or mind-expanding experiences arise. That's the other side of technology."</p>
							<p>A good example is the VR experience Sacred Hill, found among the exhibited photos and videos in the other half of the Akerk. After taking part in a series of rituals to get into the atmosphere, you are given a pair of glasses with two joysticks which pull you out of reality. You whizz through and over colourful tunnels, sometimes with objects floating around you. You can transform or move the tunnels by your own movements. Swimming through a brilliant illusion; this is what image manipulation can do to you.</p>
						</div>
					</li>

					<li className="article">
						<p>Dagblad van het Noorden, 7-9-2021</p>
						<h3 className="collapsible" onClick={titleOnClick}>Noorderlicht for dummies: everything you need to know about the photo festival at 21 locations in Groningen, Friesland and The Hague. “I would start in the Der Aa-kerk in Groningen.”</h3>
						<p>Author: Joep van Ruiten</p>
						<div className="collapsible-content" id="article-dagblad-noorden1">
							<p><strong>On Saturday Noorderlicht festival begins. Over the coming weeks the festival, with work by more than seventy artists, will explore the relationship between visual culture and reality at 21 locations in Groningen, Friesland and The Hague. Here are six questions on the festival in advance.</strong></p>
							<p><strong>What is it all about this time?</strong></p>
							<p>The theme of the 28th edition is The makeable mind. This refers to the makeable human being: what and who influences what goes on in our heads?</p>
							<p>“How do we interpret what we see? Do we all look at things in the same way, or does this interpretation differ per person?”, begins festival curator Paulien Dresscher, who together with George Knegtel and Wim Melis, selected this year's works. “Since our artists come from all over the world, we chose an English theme.”</p>
							<p><strong>What's on display?</strong></p>
							<p>Although Noorderlicht is still a photo festival in theory, photography will not be the only type of art presented in the coming weeks. Dresscher, who programs digital culture for the Netherlands Film Festival among other things, has been asked to bring the festival “into the new world”.</p>
							<p>“Across the borders of photography. What is happening in the digital and new media: multidisciplinary, interactive, artificial intelligence, computer vision, virtual reality? It's not the first time we've done that, but we've been looking extra hard for it this year."</p>
							<p><strong>Does the Internet define our reality?</strong></p>
							<p>Collective ARK made an interactive media installation for the Der Aa-kerk: Life As We Know It. Now. The installation consists of five parts and is built with screens. The screens show images that are distributed worldwide at that moment via Youtube and also produce choir music.</p>
							<p>A lot of current events and news, a lot of images and sounds. It is good to know that the demand for images via an algorithm determines the supply of those same images and that the audience in the church influences the viewing behavior. The installation raises questions such as ‘is the truth of the Internet taking over our reality?’ and ‘are we actually still in charge of our own imagination?’</p>
							<p><strong>Where do you start as a visitor?</strong></p>
							<p>Noorderlicht is extensive: with hundreds, if you will thousands, of images by more than seventy artists - four hundred contributors sent in work for the festival. And all of this until the end of November, spread over 21 locations in three provinces. A visitor can best find their way around by dividing the festival into sections.</p>
							<p>“I would start in the Der Aa-kerk in Groningen,” Dresscher advises. “Make a weekend out of it and first visit the main venues in the city of Groningen and the one in Onstwedde. In September the exhibition in Friesland starts. You can do a daytrip there as well, starting in Heerenveen and then going to Nes, Wierum, Marrum and Pietersbierum.”</p>
							<p><strong>What can be seen at those other locations?</strong></p>
							<p>Each location has a sub-theme. "Those sub-themes work like a lens, they can help when wondering: what am I looking at? It’s not just about the picture, the eye-candy, but also about the story behind it. At NP3 in Groningen, for example, you can see work that is about politics and activism, about how you can make a social statement with art. In our own Noorderlicht Gallery, young curators have selected work that has to do with diaspora, about people who look at reality in a different way because they come from a different place. At Artphy in Onstwedde, it’s about a nature that exists next to the grace of technology.”</p>
							<p><strong>What does festival Noorderlicht want to achieve?</strong></p>
							<p>“We try to keep the conversation going, in this case about reality and the various parallel worlds we live in. In this way, openness can continue to exist. There is more than meets the eye. My truth is also just my truth. Therefore, try to look through the eyes of others as well. The realization that there are different ways of seeing, that people communicate on different levels and everyone is affected in a different way, calls for conversations about this. What we show can be the beginning of such a conversation."</p>
							<p>Noorderlicht kicks off Saturday at fourteen locations in the province of Groningen where work can be seen through October 3. From September 4 through October 31, work will be shown at six locations in Friesland. In The Hague there is a presentation in the Museum for Sound and Vision from August 28 through November 28. See also www.noorderlicht.com.</p>
						</div>
					</li>

					<li className="article">
						<p>Dagblad van het Noorden, 12-9-2021</p>
						<h3 className="collapsible" onClick={titleOnClick}>Celebration of photography in the Der Aa church</h3>
						<p>Author: Joep van Ruiten</p>
						<div className="collapsible-content" id="article-dagblad-noorden2">
							<p><strong>According to leaflets for tourists, the Der Aa church on the Akerkhof is one of the most beautiful sights in the city of Groningen. This must have to do first and foremost with its yellow tower, which can be seen from a great distance and attracts visitors from the surrounding areas to the center, just as the old gray Martinitoren does.</strong></p>
							<p>Its beauty lies also in its interior. This already becomes apparent in the nave, the largest space in the church, where the pulpit and the impressive organ are located - designed by builder Arp Schnitger (1648 - 1719). Also the choir, where visitors have to walk over centuries-old tombstones and get a view of columns with cartouche-paintings and edifying texts, is impressive.</p>
							<p>On the original function of the church the leaflets are brief. It is true that the building was used for Catholic services and that this work of salvation was continued after the Reformation by the Protestants in an austerely but efficiently structured way.</p>
							<p>There is an explanation for this. Due to increasing secularization, religious services are no longer held in the Der Aa-kerk. Instead, the building can be rented for parties, meetings and other gatherings: dinners, markets, tastings, concerts, receptions and exhibitions.</p>
							<p><strong>The Makeable Mind</strong></p>
							<p>Noorderlicht International Photo Festival 2021: The Makeable Mind can be visited at several locations in Groningen, Friesland and The Hague until the end of October. The installation LAWKI Now by ARK is open from Wednesday to Sunday between 12 and 18 o’ clock in the Der Aa-kerk in Groningen. See also www.noorderlicht.com</p>
							<p>This summer the church is the main location of the Noorderlicht Festival, which is a celebration of photography and new media. In the nave one can see mainly photographic work. The choir deserves special attention. There, Atelier Roosje Klap from Amsterdam (ARK) has set up an interactive media installation, commissioned by the Noorderlicht organization, in five parts: Life As We Know It Now, in short LAWKI-Now.</p>
							<p><strong>Swearing in the Church</strong></p>
							<p>The theme of Noorderlicht is ‘The Makeable Mind’. With attention to both traditional photography and digital and interactive media, the festival aims to explore the relationship between visual culture and reality. The central question being: ‘How is our thinking guided by a runaway visual culture, unprecedented technical possibilities and lightning fast internet connections?’</p>
							<p>LAWKI-Now has something of a curse about it. There are screens with English texts on the headstones. Chasubles, on which keywords and slogans can be read, float in the air. Stacked monitors change color and cause buzzing and sometimes crackling noises. There seems to be no order or direction in the installation, rather chaos and desperation.</p>
							<p>What is somewhat reminiscent of the original function of the church is the choral music written especially for LAWKI-Now. Taking the acoustics into account, the music has been composed in such a way that various choruses follow each other and sing along. Cori spezzati is how Italians call this, split choirs that form a whole and create a reverberating stereo effect.</p>
							<p>It is mainly the image that dominates in the church. A diffuse image, originating from the internet. Considering what the monitors show, LAWKI-Now is an attempt to visualize the confusion that comes to us via computers and smartphones as a dizzying stream of images and messages. More and more, faster and faster, from more and more nooks and crannies.</p>
							<p>LAWKI-Now shows images from YouTube, the website that opened in 2005 and now uploads 500 hours of videos every minute worldwide, 300,000 clips a day. Every day hundreds of millions of active users watch more than a billion hours of this storm of images. No one knows exactly what is being distributed, including the company behind YouTube.</p>
							<p>What YouTube does know is which images are viewed a lot - a matter of registering search queries and counting them. The installation in the Der Aa-kerk presents that outcome: images that have just been put online somewhere in the world and are attracting many viewers. LAWKI-Now does not offer a summary, however, but the constantly changing top of the current click parade. The greener the monitor, the more often the video has been viewed somewhere on the Internet.</p>
							<p>What is striking in the Der Aa-kerk is that the majority of the fragments are not amusing, but have a serious character. It may be because of the upload activities of the moment, and the way LAWKI-Now was put together, but music videos and cat videos seem to lose out to natural disasters and men in suits. Comfort pictures vanish against alarm pictures. What value should be placed on this?</p>
							<p>According to Atelier Roosje Klap, YouTube can sometimes be predictive. At the beginning of this year, for example, before the actual storming of the Capitol building in the American capital of Washington, images were distributed via YouTube that showed preparations for the storming. If guards had taken the images more seriously, it may have never come to an attack on democracy.</p>
							<p>YouTube often precedes reports from the mainstream media. Take for instance the press conference that soccer millionaire Lionel Messi gave last Sunday via YouTube. Even before his answers could be questioned and processed by journalists, an unshakable image had been set over the heads of the commentators: that of the intensely sad son who is forced by the crazy financial system to seek his happiness elsewhere. His exorbitantly paid fortune.</p>
							<p><strong>The twisted reality of 'Koyaanisqatsi</strong></p>
							<p>In the meantime, the installation recalls Koyaanisqatsi, the experimental film with which director Godfrey Reggio created a furor in the cinemas in the mid-80s, partly thanks to the music of Philip Glass. Reggio showed a world that was current at the time by means of a permanent stream of lights and sounds, fast and slow. The film title was borrowed from the language of the endangered Hopi Indians and stands for a restless life, for disintegration and imbalance.</p>
							<p>Whereas Reggio, with Koyaanisqatsi, pronounced judgment on the reality he had filmed, the makers of LAWKI-Now conspicuously omit this. Rather, the makers raise questions such as ‘is the truth of the Internet taking over our reality?’, ‘are we still in control of our imagination?’ and ‘is artificial intelligence working for us or against us?’ Each viewer may decide the answer for themselves.</p>
							<p>Artificial intelligence is a crucial part of the installation. LAWKI-Now is designed in such a way that visitors are not only passive spectators, but they can simultaneously influence what they see. The installation is equipped with sensors that react to the number of people present in the church.</p>
							<p>More visitors result via an algorithm in more images on the monitors. More visitors also result in more sounds, because the choral singing also responds to those present. Aristotle (384 BC - 322 BC) said it all: “Everything you give attention, grows.” Put another way, the viewer is complicit in what he or she sees and wants to see.</p>
							<p>From time immemorial, the question of whether we are in charge of our imagination depends on how much we are carried away by what we see, hear and think. If you sit by a fire in a cave at night, you can let the shadows on the wall frighten you. Another option is to calmly take a cooled piece of charcoal and draw those same shadows on the wall. As an incantation. Or to enjoy them again during the day.</p>
							<p>With LAWKI-Now, Atelier Roosje Klap goes a step further and argues that the Internet has become a new reality. Because of our insatiable use of social media like YouTube, our thinking and feeling is increasingly influenced by what we consume online. According to ARK, we have already reached the point where the reality of the Internet shows us who we have become, as if in a mirror.</p>
							<p>From this point of view, it is not strange to suppose that “the truth of the Internet,” that which overwhelmingly comes at us through the Internet, scares and confuses us afterwards. Consider the parliamentarians who were convinced they were having a conversation with the chief of staff of Russian opposition leader Aleksei Navalny during a video link. In reality, they were dealing with a deep fake, a computer-generated mind.</p>
							<p><strong>The oracle on the internet</strong></p>
							<p>There is little doubt that our thinking is guided by technical possibilities, by images and messages that are spread digitally at lightning speed. We see it in people who do not trust the old media and therefore seek their truth somewhere on the Internet, at the oracle where so much information is gathered that there is something for everyone. It is just a matter of filling in the search form, clicking, choosing and no longer doubting.</p>
							<p>However, the claim that the truth of the Internet is taking over our reality seems exaggerated. There are more sources on which we base our thinking and actions than the World Wide Web. The number of Internet users may be large, companies engaged in big tech may be called economically unprecedentedly successful, so much can be arranged with the click of a mouse, worldwide many more people value, for example, religion higher than the operation of algorithms.</p>
							<p>In addition, perhaps, life as we really know it and experience it intensely on a daily basis does not take place exclusively online, but primarily offline. Not behind a screen at the office and not in church either, but outside, in private at home and eventually in bed. Without an internet connection. Some things stay the same forever. That is what’s so soothing.</p>
						</div>
					</li>

					<li className="article">
						<p>Dutch Design Daily, 21-10-2021 </p>
						<h3 className="collapsible" onClick={titleOnClick}>LAWKI-Alive</h3>
						<p></p>
						<div className="collapsible-content" id="article-ddd">
							<p><strong>One Week About  Hidden Gems of the Dutch Design Week 2021, today we show LAWKI-Alive by ARK Collective</strong></p>

							<p>LAWKI-Alive dives into the digital overload of footage that we throw into the world on a daily basis and the associative way in which we ourselves and an Artificial Intelligence deal with it. Worldwide, we watch a billion hours of online video. A. Day. Games, music, news, make-up, asmr, vlogs, documentaries, tutorials, interviews, reviews, challenges and, of course, cat videos! Each minute, 500 hours of video material are being uploaded to YouTube alone.</p>

							<p>With LAWKI-Alive, the third iteration of Life As We Know It, the ARK collective condenses this unimaginable amount of digital footage to an intuitive dynamic livestream driven by AI and the visitors’ movements. Smartly indexed search results continuously generate new associative visual stories in ten different languages.</p>

							<p>The result is an overpowering stream of images showing what keeps people busy from Boston to Bangalore. World and screen are no longer separate, we are in MU, but we are everywhere and nowhere at once as well. An excellent opportunity to get to know life (all over).</p>

							<p>ARK is: Arran Lyon (GB), Federico Campagna (IT), Louis Braddock Clarke (GB), Roosje Klap (NL), Senka Milutonovic (RS), Teoniki Rozynek (PL), Valentin Vogelmann (DE) en Zuzanna Zgierska (PL)</p>
						</div>
					</li>
				</ul>
			</div>

			<div className="panel-right">
				<img className="press-image" onClick={() => imageOnClick("article-nrc")} src="media/images/NRC_21092021.PNG" alt="NRC" />
				<img className="press-image" onClick={() => imageOnClick("article-dagblad-eindhovens")} src="media/images/Eindhovensdagblad.jpg" alt="Eindhovens Dagblad" />
				<img className="press-image" onClick={() => imageOnClick("article-telegraaf")} src="media/images/Telegraaf09082021.jpg" alt="Telegraaf" />
				<img className="press-image" onClick={() => imageOnClick("article-dagblad-noorden1")} src="media/images/NoorderlichtforDummies.jpeg" alt="Dagblad van het Noorden" />
				<img className="press-image" onClick={() => imageOnClick("article-dagblad-noorden2")} src="media/images/Celebration of photography.png" alt="Dagblad van het Noorden" />
				<img className="press-image" onClick={() => imageOnClick("article-ddd")} src="media/images/Dutch Design Daily.jpg" alt="Dutch Design Daily" />

			</div>
		</div>
	);
}

export default Press;