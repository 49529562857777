import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import AlgorithmDiagram from "./AlgorithmDiagram";


function Algorithms() {
    const [curr_el, setCurrEl] = useState(null);
    const [img, setImg] = useState('media/images/images_algorithms/Diagram_Full.png')

    useEffect(() => {
        for (let desc of document.getElementsByClassName('collapsible-content')) {
            desc.style.maxHeight = "40px";
        }
    }, []);

    const showElement = (el) => {
        if (!curr_el || curr_el != el) {
            setImg('media/images/images_algorithms/Diagram_' + el + '.png');
            setCurrEl(el);

            for (let desc of document.getElementsByClassName('collapsible-content')) {
                if (desc.getAttribute('element') == el) {
                    // open description
                    desc.style.maxHeight = desc.scrollHeight + "px";
                } else {
                    // close description
                    desc.style.maxHeight = "40px";
                }
            }

        } else {
            setImg('media/images/images_algorithms/Diagram_Full.png');
            setCurrEl(null);
            for (let desc of document.getElementsByClassName('collapsible-content')) {
                // const content = desc.children[1];
                if (desc.getAttribute('element') == el) {
                    // close description
                    desc.style.maxHeight = "40px";
                }
            }
        }

        // console.log('showElement: ' + curr_el + ' ' + el);
    }

    const onClickTitle = (e) => {
        const el = e.currentTarget.getAttribute('element');
        showElement(el);
    }

    const onClickImg = (area, index, event) => {
        showElement(area.id);
    }

    const onMouseEnter = (area, index, event) => {
        // show system under cursor
        const hover_el = area.id;
        // console.log('onMouseEnter: ' + hover_el);
        setImg('media/images/images_algorithms/Diagram_' + hover_el + '.png')
    }

    const onMouseLeave = (area, index, event) => {
        // show selected img
        // console.log('onMouseLeave: ' + curr_el);
        if(!curr_el){
            setImg('media/images/images_algorithms/Diagram_Full.png');
        } else {
            setImg('media/images/images_algorithms/Diagram_' + curr_el + '.png');
        }
    }

    const ImageMapComponent = AlgorithmDiagram({ 
        src: img, 
        click_callback: onClickImg,
        enter_callback: onMouseEnter,
        leave_callback: onMouseLeave 
    });


    return (
        <div className="split-panels">
            <div className="panel-left">
                <h2>Algorithms</h2>
                <p className="intro">
                The system that drives LAWKI is a complex web of individual algorithms, pre-processes and interactions that combine together to bring the installation alive. Here you can explore the design decisions and technical details of the various sub-systems that make the latest iteration of the installation LAWKI-ALIVE.
                </p>
                <p>Select a component below to read more about it or click on the sub-system in the image on the right to expand the description.</p>
                <div className="collapsible-content" element="search_topic" onClick={onClickTitle}>
                    <h4>Search Topic</h4>
                    <p className="">First a series of topics are decided.</p>
                </div>
                <div className="collapsible-content" element="google_translate" onClick={onClickTitle}>
                    <h4>Automatic Translation</h4>
                    <p className="">We use Google's translate API.</p>
                </div>
                <div className="collapsible-content" element="search_phrase" onClick={onClickTitle}>
                    <h4>Search Phrase</h4>
                    <p className="">The topics lead to a large collection of search phrases. See <NavLink to="/searchphrases">search phrases</NavLink> to explore the complete list.</p>
                </div>
                <div className="collapsible-content" element="youtube" onClick={onClickTitle}>
                    <h4>Video Search</h4>
                    <p className="">The search terms are queried on YouTube and DailyMotion to produce a series of search results for that topic in the various languages.</p>
                </div>
                <div className="collapsible-content" element="link" onClick={onClickTitle}>
                    <h4>Link</h4>
                    <p className="">The search terms create a database of video links from YouTube and other online resources</p>
                </div>
                <div className="collapsible-content" element="video" onClick={onClickTitle}>
                    <h4>Video</h4>
                    <p className="">All the links are downloaded to obtain all the videos.</p>
                </div>
                <div className="collapsible-content" element="metadata" onClick={onClickTitle}>
                    <h4>Metadata</h4>
                    <p className="">The video also contains metadata - video title and description, view number, publication date etc.</p>
                </div>
                <div className="collapsible-content" element="BERT" onClick={onClickTitle}>
                    <h4>BERT Language Model</h4>
                    <p className="">Metadata is fed into a state of the art language model.</p>
                </div>
                <div className="collapsible-content" element="embedding" onClick={onClickTitle}>
                    <h4>Embedding</h4>
                    <p className="">A language model "embeds" the video into a semantic space depending on its metadata.</p>
                </div>
                <div className="collapsible-content" element="random_walk" onClick={onClickTitle}>
                    <h4>Random Walk</h4>
                    <p className="">The random walk traverses the embedding space to collect similar videos.</p>
                </div>
                <div className="collapsible-content" element="videoplayer" onClick={onClickTitle}>
                    <h4>Video Player</h4>
                    <p className="">A custom designed video player receives the content to display from the random walk. This app is unlike consumer video players - there is no pause button and it is capable of showing multiple streams at once in a single window at specific screen coordinates that are mapped to the many LED displays. A custom API and messaging system controls which videos to play in a stream, and also applies the video filters. The sound of the videos is routed to the Audio Sample Collector and the Audio Mixer. There are some key commands to specify the positions of the streams and to set the parameters of the filters during the development phase of LAWKI. The player is programmed in Python.</p>
                </div>
                <div className="collapsible-content" element="filters" onClick={onClickTitle}>
                    <h4>Filters</h4>
                    <p className="">A green or purple filter is applied to the video to mask the content - the higher the view count the more that is hidden. This mimics the distortion of truth and narrative as content is shared.</p>
                    <p>This filter is produced by first finding the most prominent pixel colour in the video clip, then selecting all the pixels in each frame of video that are within some distance to that colour to switch to purple. This distance in proportional to the number of views the video has, such that the higher view count the more pixels get selected.</p>
                    <p>The filter has some other subtle effects too. The selected pixel each frame seed a <a href="https://ciphrd.com/2020/07/21/cyclic-diffusion-limited-aggregation/">Cyclic Diffusion-Limited Aggregation</a> reaction simulation to add a blurred ripple effect to the outlines of the block purple. This reaction model produces organic, smoke-like patterns, almost like some bacteria growth.</p>
                    <div className="algorithm-vid-container">
                        <img className="algorithm-img" src="media/images/filter_example.png" alt="LAWKI-Alive video filter"></img>
                    </div>
                    <p>When there is no observer present near the screen, the decay of the reaction model is set to zero such that purple pixels are not reset and slowly the whole screen is painted one colour. Only when the sensors are triggered again the decay value is reset, the colour fades and video image is visible again.</p>
                </div>
                <div className="collapsible-content" element="displays" onClick={onClickTitle}>
                    <h4>Display</h4>
                    <p className="">The videos are played over 96 LED panels arranged in a fragmented pattern but organised to form 6 larger screens. Each screen has a deconstructed and broken form, like Tetris pieces, that could in theory be reassembled to complete the image. Through the holes and removed sections in the screens, at particular vantage points the observer can fit together pieces from other screens in the space to construct new images and combinations unique to that percussive.</p>
                    <div className="algorithm-vid-container">
                        <img className="algorithm-img" src="media/images/images_resources/LAWKI - (c) Hanneke Wetzer, 2021-22.jpg" alt="LAWKI-Alive screens"></img>
                    </div>
                </div>
                <div className="collapsible-content" element="audiosamplecollector" onClick={onClickTitle}>
                    <h4>Audio Sample Collector</h4>
                    <p className="">An algorithm listens to the audio of one of the current videos and selects short moments from the sounds. This is done by identifying and cutting up the audio at moments of high onset intensity, and selecting up to 50 of these segments at random. The way the audio is segmented results in samples that start with a loud burst of energy, much like a percussive sound, and results in a library of popping/clicking/hit noises.</p>
                    <p>As the library grows, older samples are removed to keep the size of available units capped at 200.</p>
                </div>
                <div className="collapsible-content" element="ai_loop_maker" onClick={onClickTitle}>
                    <h4>AI Loop Maker</h4>
                    <p>This is an algorithm that creates rhythmic loops from the audio samples collected in the previous step. The idea is to create a sense of pulse and tempo to the soundscape by using chopped up audio fragments.</p>
                    <p className="">First, the samples are arranged in a 2D space such that similarly sounding samples are grouped together. For example, in one corner all the crash sounds could be placed, and in the another place all the bassy/low sounds. This is accomplished with an unsupervised learning algorithm that first takes many measurements of the raw audio sample, compresses this data into a "summary", and reduces this summary to x, y coordinates in a square. Each sample is represented as a cross in the animation below. (Keywords: audio embedding, dimensionality reduction)</p>
                    <div className="algorithm-vid-container">
                        <video className="algorithm-vid" src="media/videos/random_music_loop.mp4" muted autoPlay></video>
                    </div>
                    <p>Next, a random path is generated through this space that forms a loop. This path continuously evolves over time in a random but coherent way. This is illustrated by the purple path in the animation. (Keywords: Perlin Noise)</p>
                    <p>Finally, by walking through the path over time and playing the nearest sample at each step an audio loop is created. Although it sounds quite random at first, as the loop repeats a rhythm emerges to the listener. Since the path slowly evolves over time the samples that get selected begin to change to their neighbours, and since the neighbours sound similar, the audio loop subtly progresses and smoothly transforms into a different rhythm.</p>
                    <p>This whole process is similar to the Random Walk algorithm for selecting videos above, so the Aby Warburg neighbouring system exists in the soundscape as well as the narrative generated in the visuals.</p>
                </div>
                <div className="collapsible-content" element="audiomixer" onClick={onClickTitle}>
                    <h4>Audio Mixer</h4>
                    <p className="">The audio from the videos is heavily processed with effects to generate a full and rich soundscape, and mixed together with the AI loop over an eight channel surround sound system.</p>
                    <p>The processing and effects are created in an audio programming environment known as <a href="https://puredata.info/">PureData</a>. The effects include massive and extended reverb with high wet parameter (i.e. only the reverberations/echoes are forwarded, and very little of the original signal is mixed in), an audio "freezing" (where a slice of sound is stretched out and sustained for a long period), and pitch shifting to create a very deep bass sound.</p>
                    <p>All the parameters for these effects are randomly modulated over time to create a feeling of movement and unrest. At various moments, the direct audio of one of the video streams is mixed in to bring a connection of the sound to the images.</p>
                    <p>The presence of an audience also plays a role in the final sound mix. With no one in the space LAWKI falls silent, but as movement is detected at various spots in the space the sound is sent to the speakers closest to the movement, following the observer's movements.</p>
                </div>
                <div className="collapsible-content" element="sound" onClick={onClickTitle}>
                    <h4>Sound</h4>
                    <p className="">All the sound is played through an eight speaker surround-sound system circling the installation. The sound and music follows the audience members as they move through the space, enveloping their presence with a deep and ever-changing sonic experience.</p>
                </div>
                <div className="collapsible-content" element="sensordata" onClick={onClickTitle}>
                    <h4>Sensor Data</h4>
                    <p className="">As the audience moves through the space, motion sensors trigger and alter the sound and video filters. These are PIR sensors (that are commonly found in automatic hall lighting systems) which uses infrared to detect movement and sends the trigger signal to a RaspberryPi, which in turn relays this information to the relevant systems. If there is no more movement from a sensor after a set amount of time, then another packet of information is sent out to signal there is no longer an observer in the vicinity.</p>
                </div>
                <div className="collapsible-content" element="audience" onClick={onClickTitle}>
                    <h4>Audience</h4>
                    <p className="">The audience members are free to wander and explore the space LAWKI occupies to experience the installation in full. The observer's meandering path throughout the environment as they search for new perspectives of LAWKI is analogous to the movement and exploration of the random walker algorithm through the video embedding space (see above). In this sense, the audience and the machine co-exist in their curiosity of witnessing the video representations of Life As We Know It.</p>
                </div>

            </div>
            <div className="panel-right">
                {/* <img id="algorithm-diagram" alt="Algorithm diagram" src="media/images/images_algorithms/Diagram_Full.png"></img> */}
                {ImageMapComponent}
            </div>
        </div>
    );
}

export default Algorithms;
