import React from 'react';
import ImageMapper from 'react-img-mapper';



const mapAreas = [
    {id: 'search_topic', shape: 'rect', coords: [248, 36, 389, 68]},
    {id: 'google_translate', shape: 'rect', coords: [318, 78, 428, 102]},
    {id: 'search_phrase', shape: 'rect', coords: [237, 111, 395, 143]},
    {id: 'youtube', shape: 'rect', coords: [320, 153, 502, 175]},
    {id: 'link', shape: 'rect', coords: [290, 188, 339, 220]},
    {id: 'video', shape: 'rect', coords: [149, 187, 218, 222]},
    {id: 'metadata', shape: 'rect', coords: [410, 188, 530, 219]},
    {id: 'BERT', shape: 'rect', coords: [489, 262, 588, 299]},
    {id: 'embedding', shape: 'rect', coords: [404, 320, 534, 352]},
    {id: 'random_walk', shape: 'rect', coords: [198, 371, 516, 418]},
    {id: 'videoplayer', shape: 'rect', coords: [116, 424, 267, 455]},
    {id: 'filters', shape: 'rect', coords: [340, 422, 426, 455]},
    {id: 'displays', shape: 'rect', coords: [545, 404, 643, 479]},
    {id: 'audiosamplecollector', shape: 'rect', coords: [111, 572, 255, 630]},
    {id: 'ai_loop_maker', shape: 'rect', coords: [314, 572, 405, 630]},
    {id: 'audiomixer', shape: 'rect', coords: [448, 572, 518, 630]},
    {id: 'sound', shape: 'rect', coords: [548, 572, 626, 630]},
    {id: 'sensordata', shape: 'rect', coords: [246, 668, 382, 701]},
    {id: 'audience', shape: 'rect', coords: [256, 742, 375, 825]},
]


const AlgorithmDiagram = props => {
  const MAP = {
    name: 'algorithms_map',
    // GET JSON FROM BELOW URL AS AN EXAMPLE
    areas: mapAreas,
  };
  
  return <ImageMapper id="algorithm-diagram" src={props.src} map={MAP} 
            onClick={props.click_callback} 
            onMouseEnter={props.enter_callback} 
            onMouseLeave={props.leave_callback} 
            responsive="true" parentWidth="735" 
            fillColor="rgba(255, 255, 255, 0)" lineWidth="0" 
            strokeColor="rgba(255, 255, 255, 0)" />
}

export default AlgorithmDiagram;