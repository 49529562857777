import React from "react";
import { NavLink } from "react-router-dom";

function Navigation() {
	// function activeStyle ({isActive}) {
	// 	return {color: isActive? "#0F0" : "black"}
	// }

	return (
		<div className="navigation">
			<NavLink className="nav-link" to="/">LAWKI</NavLink>
			<div className="nav-links">
				<NavLink className="nav-link" to="/resources">resources</NavLink>
				<NavLink className="nav-link" to="/algorithms">algorithms</NavLink>
				<NavLink className="nav-link" to="/searchphrases">search phrases</NavLink>
				<NavLink className="nav-link" to="/press">press</NavLink>
				<NavLink className="nav-link" to="/about">about</NavLink>
			</div>
		</div>
	);
}

export default Navigation;