import React, { useState, useEffect } from "react";
// import ReactAudioPlayer from "react-audio-player";




// const Radio = (props) => {
//     // state
//     const [trackProgress, setTrackProgress] = useState(0);
//     const [isPlaying, setIsPlaying] = useState(false);

//     // refs
//     const audioRef = useRef(new Audio(props.audioSrc));
//     const intervalRef = useRef();
//     const isReady = useRef(false);

//     const { duration } = audioRef.current;

//     const startTimer = () => {
//         clearInterval(intervalRef.current);

//         intervalRef.current = setInterval(() => {
//             setTrackProgress(audioRef.current.currentTime);
//         }, [1000]);
//     }

//     // useEffect(() => {
//     //     audioRef.current.pause();
//     // })

//     useEffect(() => {
//         if (isPlaying) {
//             audioRef.current.play();
//         } else {
//             audioRef.current.pause();
//         }
//     }, [isPlaying]);

//     const togglePlayback = () => {
//         if (isPlaying) {
//             setIsPlaying(false);
//         } else {
//             setIsPlaying(true);
//         }
//     }

//     const onScrub = (value) => {
//         clearInterval(intervalRef.current);
//         audioRef.current.currentTime = value;
//         setTrackProgress(audioRef.current.currentTime);
//     }

//     const onScrubEnd = () => {
//         if (!isPlaying) {
//             setIsPlaying(true);
//         }
//         startTimer();
//     }

//     return (
//         <div className="radio">
//             {/* <ReactAudioPlayer src="media/audio/lawki_alive_1.mp3" controls autoPlay /> */}
//             <button id="play-icon" onClick={() => togglePlayback()}>{">"}</button>
//             <input type="range" id="seek-slider" max="100" value="0"></input>
//             <span id="duration">0:00</span>
//         </div>
//     )
// }


const useAudio = url => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));

        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    return [playing, toggle];
}



const Radio2 = ({ url }) => {
    const [playing, toggle] = useAudio(url);
    // const [trackProgress, setTrackProgress] = useState(0);

    return (
        <div className="radio">
            <span>LAWKI-Radio</span>
            {/* <div className="radio-controls" onClick={toggle}>{playing ? <i className="fas fa-pause"></i> : <i className="fa fa-play" style={{fontSize : "1.2em" }}></i>}</div> */}
            <div className="radio-controls" onClick={toggle} style={playing ? {backgroundImage: "url(/media/images/icon-pause.png)"} : {backgroundImage: "url(/media/images/icon-play.png)"}}></div>
        </div>
    )
}

// const Radio3 = ({ url }) => {
//     const [percentage, setPercentage] = useState(0)
//     const [isPlaying, setIsPlaying] = useState(false)
//     const [duration, setDuration] = useState(0)
//     const [currentTime, setCurrentTime] = useState(0)

//     const audioRef = useRef()

//     const onChange = (e) => {
//         const audio = audioRef.current
//         audio.currentTime = (audio.duration / 100) * e.target.value
//         setPercentage(e.target.value)
//     }

//     const play = () => {
//         const audio = audioRef.current
//         audio.volume = 0.1

//         if (!isPlaying) {
//             setIsPlaying(true)
//             audio.play()
//         }

//         if (isPlaying) {
//             setIsPlaying(false)
//             audio.pause()
//         }
//     }

//     const getCurrDuration = (e) => {
//         const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(2)
//         const time = e.currentTarget.currentTime

//         setPercentage(+percent)
//         setCurrentTime(time.toFixed(2))
//     }

//     return (
//         <div className='app-container'>
//             <Slider percentage={percentage} onChange={onChange} />
//             <audio
//                 ref={audioRef}
//                 onTimeUpdate={getCurrDuration}
//                 onLoadedData={(e) => {
//                     setDuration(e.currentTarget.duration.toFixed(2))
//                 }}
//                 src={song}
//             ></audio>
//             <div onClick={play}>
//                 {isPlaying ? "Pause" : "Play"}
//             </div>
//         </div>
//     )
// }

// export default Radio;
export default Radio2;