import React, { useRef } from "react";
import shuffleArray from "./shuffleArray";

import all_terms from "./all_terms";


function randomInt(x){
    return Math.floor(Math.random() * x);
}


function SearchTerms() {
    let terms = useRef([]);
    let termslist = useRef([]);

    terms.current = shuffleArray(all_terms.terms);
    // termslist.current = terms.current.map(function (t, index) {
    //     let search_url = "https://www.youtube.com/results?search_query=" + t.replace(/\s/g, "+");
    //     return <li key={index}><a href={search_url} className="search-term" target="_blank" rel="noopener noreferrer">{t}</a></li>
    // })

    termslist.current = terms.current.map(function (t, index) {
        let search_url = "https://www.youtube.com/results?search_query=" + t.replace(/\s/g, "+");

        const x = randomInt(80) + '%';
        return <a key={index} href={search_url} className="search-term" style={{left: x}}target="_blank" rel="noopener noreferrer">{t}</a>
    })

    // return (
    //     <div className="split-panels">
    //         <div className="panel-left">
    //             <h2>Search Phrases</h2>
    //             <p>Below is a list of search phrases used in LAWKI-ALIVE to scrape the video content. Click on them to make a search on YouTube with the phrase.</p>
    //             <ul>{termslist.current}</ul>
    //         </div>
    //         <div className="panel-right">
    //             {/* <iframe name="playlist_iframe" className="playlist" title="youtube search results" src=""></iframe> */}
    //         </div>
    //     </div>2
    // );

    return (
        <div className="phrases-fullpage">
            <h2>Search Phrases</h2>
            <p className="intro">Below is a list of search phrases used in LAWKI-ALIVE to scrape the video content. Click on them to make a search on YouTube with the phrase.</p>
            <div className="phrases-canvas">
                {termslist.current}
            </div>
        </div>
    )
}

export default SearchTerms;
