import React from "react";

import shuffleArray from "./shuffleArray";


function About() {

	let profiles = [
		<p key="roosje"><strong>Roosje Klap</strong> is the founder of Atelier Roosje Klap, also known as ARK. Klap studied Graphic Design at the Gerrit Rietveld Academie in Amsterdam, and fresh out of school began her own studio, quickly developing an international network. Her interests lie in unconventional, artistic research and playful collaborations with artists, curators, architects, (type) designers, and writers. The research-based interdisciplinary approach is reflective of a generation of designers that define their assignments alongside commissions.</p>,

		<p key="arran"><strong>Arran Lyon</strong> is a multi-disciplinary coder who explores the story telling potential of technology, and asks what artifical intelligence can do, rather than what it cannot. As a proponent of open source software, he develops custom solutions for artistic practices and helps to bring the forefront of technology into new domains. Arran graduated with a masters degree in computational science from the University of Amsterdam in 2020.</p>,

		<p key="louis"><strong>Louis Braddock Clarke</strong> moves between researcher and creative practitioner interpreting notions from design, geography, electro-matter, and esoteric philosophies. Via this holistic approach, an experimental inquiry unfolds, blending scientific and conceptual theories to materialize in contemporary mediums. By producing ‘Geo-tools,’ Braddock Clarke measures and records ancient, current, and future surfaces of the ever-shifting Earth.</p>,

		<p key="senka"><strong>Senka Milutinovic</strong> is a designer obsessed with fictional and political storytelling that aims to raise awareness about social issues that are connected to the communities they inhabit. They believe that there is no such thing as apolitical design and use research as the backbone for any hybrid project they start conjuring up. From designing events for the queer community to making exhibitions about the political influence of memes, Senka always tries to find the right medium for the message. Senka is currently studying and working at Willem de Kooning Academy in Rotterdam, and will graduate in June 2022.</p>,

		<p key="valentin"><strong>Valentin Vogelmann</strong> is a computer scientist and a researcher at the KNAW (The Royal Netherlands Academy of Arts and Sciences) for The SociAl BIas Observatory, where they are investigating bias in the digital collections of the members of the Dutch Digital Heritage Network. SABIO makes prejudices and imbalances in the data explicit such that they can be addressed, as well as taken into account by users of the data.</p>,

		<p key="zuzanna"><strong>Zuzanna Zgierska</strong> is a cross-media designer with a strong interest in media theory. She makes science-fiction objects, which challenge the tender interfaces between the human and the technical. Her research invites to view technology in a non-hierarchical, non-binary way: beyond the cold tokens of 0 and 1. Zuzanna has exhibited worldwide, her visual and written work was published in design magazines. Unless caught red-handed hacking a bean sorting machine, she can be found roasting screen surfaces into portions of biological substances.</p>,

		<p key="federico"><strong>Federico Campagna</strong> is an Italian philosopher, writer and educator, based in London. His recent work revolves around the metaphysical and ethical challenges posed by contemporary nihilism, and the possibility of a fundamental philosophical architecture of emancipation. He is the author of Technic and Magic: the reconstruction of reality (Bloomsbury, 2018) and The Last Night: antiwork, atheism, adventure (Zero Books, 2013), and most recently Prophetic Culture: Recreation for Adolescents (Bloomsbury, 2021).</p>,

		<p key="teoniki"><strong>Teoniki Rożynek</strong> is a composer and electronic musician based in Warszawa. She creates both independently and as part of collective work, both in performances and films, as well as in duets and within the composers' collectives. Her works were performed, among others, during the Warsaw Autumn festival (2016, 2017, 2018) and Musica Electronica Nova (2017, Wrocław). She won the music award on Polish Film Festival in Gdynia for music for Prime Time in 2021.</p>
	];

	profiles = shuffleArray(profiles);

	return (
		<div className="split-panels">
			<div className="panel-left">
				<h2>about</h2>
				<p>LAWKI is a nomadic audiovisual instrument by ARK. The screen-based installation collects online videos and combines them with a sound composition that responds to visitor movements, directed by an Artificial Intelligence- and Machine Learning-led algorithm. The first iteration was the short film for NFF, the second the installation in the Akerk at Noorderlicht, using an artificial- and a real choir. Finally, the third iteration took place at MU Hybrid Art House in Eindhoven with dancers.</p>

				<p>LAWKI reflects our Zeitgeist: poignant, generative, and immersive at the intersection of tech, design, and visual arts. LAWKI is connected directly to the main artery of our age: the endless stream where we lose ourselves entirely as a natural part of daily life that seamlessly integrates into the rhythm of eating, working, sleeping, and leisure. The social platforms have life itself disappearing from view and omit our ability to experience and embrace our reality. According to philosopher Federico Campagna, who joined ARK with LAWKI, Technic is best opposed by Magic. The installation is the proxy perspective of life as we know it, and projects its delusions in extreme mastery onto a compositional backdrop with LED tiles.</p>

				<p>The spectator is a consumer of the spectacle and participates in the making process by moving between the screens. During a ‘walk of wonder,’ their presence generates new compositions, triggered directly by their movements, a techno-haptic entanglement. LAWKI is a sensitive, globally branched-out site-specific instrument. It slows down or accelerates on the rhythm of our attention - a live stream formed by association. And if you feel at a loss for words, you’re experiencing the magic. Technic is the new magic.</p><br />


				<h3 style={{textTransform: "uppercase"}}>ARK</h3>
				<p className="intro">For the project LAWKI, ARK Amsterdam extended internationally and interdisciplinary. The group consists of designers and researchers: Louis Braddock Clarke, Roosje Klap, Senka Milutinović, and Zuzanna Zgierska; philosopher Federico Campagna; composer Teoniki Rożynek; computer scientists: Arran Lyon and Valentin Vogelmann. ARK's hybrid method of exchanging knowledge and developing work reflects its artistic ambition to make modern technologies open-ended, inclusive, and fair spaces of participation. Through the process of commoning between humans and algorithms, new knowledges emerge.</p>

				{profiles}
			</div>
			<div className="panel-right">
				<div>
					<img className="ark-image" alt="ARK team" src="media/images/ark-Iris-duvekot-1024x450.jpeg"></img>
					<p className="caption" style={{ height: 'auto' }}>The team of ARK - Photo (c) Iris Duvekot 2021</p>
				</div>
			</div>
		</div>
	);
}

export default About;
