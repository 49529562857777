import React from "react";
import Radio2 from "./Radio";

function Footer() {
	return (
		<div className="footer">
			<h2>contact</h2>
			<div>
				<div className="footer-credits">
					<p><strong>ARK Amsterdam</strong></p>
					<p>Westerdok 610</p>
					<p>1013BV Amsterdam</p>
					<p>The Netherlands</p>
					<p><a href="https://www.ark.amsterdam">www.ark.amsterdam</a></p>
					<p><a href="mailto:lawki@ark.amsterdam">lawki (at) art.amsterdam</a></p>
				</div>
				<div className="footer-credits">
					<p><strong>credits</strong></p>
					<p>commisioned by <a href="https://www.noorderlicht.com" target="blank" rel="noopener noreferrer">Noorderlicht Festival</a> and <a href="https://mu.nl" target="blank" rel="noopener noreferrer">MU Hybrid Art House</a></p>
					<p>supported by <a href="https://stimuleringsfonds.nl" target="blank" rel="noopener noreferrer">Creative Industries Funds NL</a></p>
					<p>supported by <a href="https://www.mondriaanfonds.nl" target="blank" rel="noopener noreferrer">Mondriaan Funds</a></p>
				</div>
				<div className="footer-credits">
					<p><strong>curated by</strong></p>
					<p>Paulien Dresscher</p>
					<p>Angelique Spaninks</p>
				</div>
			</div>
			<Radio2 url="media/audio/lawki_alive_1.ogg" />
		</div>
	)
}

export default Footer;