module.exports = {terms: [
    "ثوران بركان لا بالما",
    "تكثف 'لا بالما'",
    "مؤتمر المناخ COP26",
    "كوفيد داعم بالحقن",
    "لقاح الطفل",
    "حرائق الغابات العالمية",
    "اقدم اثار اقدام في امريكا",
    "أنكيلوصور أفريقي",
    "ثوران بركان فويغو",
    "حشرات صالحة للأكل",
    "أحفورة تيتانوكوريز",
    "نقص حاد في المياه",
    "الطيور المهاجرة تصطدم بناطحات السحاب في مدينة نيويورك",
    "أكبر حريق غابات الأشجار",
    "تحذير من فيضان مفاجئ",
    "صغير الباندا السنغافوري",
    "عاصفة فيتنام",
    "زلزال استراليا",
    "قطيع من الخيول الهاربة",
    "مجرى جنوب أستراليا",
    "تدفق الحطام يسد الطرق",
    "ضربت الفيضانات ألمانيا وبلجيكا",
    "نقص الخشب في خروج بريطانيا من الاتحاد الأوروبي",
    "مجتمع Larose عالق في الوحل",
    "تحولت البحيرة إلى اللون الأحمر في الأردن",
    "العاصفة الاستوائية سام",
    "الاحترار السريع",
    "درجة حرارة قياسية",
    "إعصار إيدا",
    "البديل الجديد لفيروس كوفيد",
    "الدوامة القطبية الستراتوسفير",
    "دولفين ذبح جزر فارو",
    "ثقب الأوزون فوق القارة القطبية الجنوبية",
    "العلاج بالإيفرمكتين كوفيد",
    "الجفاف في العراق وسوريا",
    "اندلعت شقوق جديدة",
    "مراجعة النفايات العالمية",
    "فئران التحرير الجيني",
    "شبكة الاتصالات الكمومية",
    "التمساح",
    "علماء الآثار رموز الإنسان",
    "انخفاض النحل العالمي",
    "فقدان الجليد العالمي",
    "TOI-178 الكواكب الخارجية",
    "مصنعة من خشب شفاف",
    "سبوتنيك- V",
    "فوسفين فينوس",
    "المريخ الميثان",
    "ينبعث من حوض الأمازون",
    "حفر المريخ",
    "روفر تدرب على كوكب المريخ",
    "جرينلاند خالية من الجليد",
    "انبعاثات تعدين البيتكوين",
    "مجد الصباح المنبثقة الربيع",
    "حديقة يلوستون العتيقة",
    "بورغس",
    "الضوء خلف الثقب الأسود",
    "اللحوم المطبعية الحيوية",
    "ققرطاك افنارلق",
    "جزيرة جرينلاند الجديدة",
    "معظم اليابسة الشمالية",
    "أخذ عينات من صخور المريخ",
    "آثار أقدام بشرية نيو مكسيكو",
    "عملية التعدين غير القانوني",
    "تسرب النفط في كاليفورنيا",
    "نقص الفحم في الصين",
    "إعصار شاهين",
    "تحذير من الفيضانات في تايلاند",
    "زراعة طعام الصحراء",
    "نمر عربي نادر",
    "دش نيزك Orionid",
    "السياحة الفضائية",
    "سياح الفضاء سبيس إكس",
    "معسكر المهاجرين الهايتيين بولاية تكساس",
    "أنجيلا ميركل تنحي",
    "خدمات النقل في ساو باولو",
    "ترحيل مهاجرين من حدود بوليفيا",
    "المهاجرون تقطعت بهم السبل على الحدود الكولومبية",
    "أضرم الرجل النار في نفسه أمام الحكومة",
    "الاستثمار الأجنبي لمنع الهجرة",
    "أصبحت راقصة السالسا من المتظاهرين الراديكاليين",
    "أزمة سائق الشاحنة في المملكة المتحدة",
    "شركة الرقص Tiptoeing بالخارج",
    "أعظم مهرجان للرقص في العالم",
    "ساو باولو لعشاق الرقص",
    "أماكن لكسر حركة",
    "تنضم Tela إلى حركة إلغاء مناطق التنمية",
    "بولسونارو يرفض القيود المفروضة على الحركة",
    "فتح الشطرنج سحابة بونغ",
    "كرنفال البرازيلي",
    "متظاهرو ميناء دوفر",
    "تمتع بالمواصلات العامة المجانية",
    "أمستردام متاجر ألبرت هاين مغلقة بالصقها",
    "الاضطرابات المدنية في جنوب أفريقيا",
    "احتجاج تلاميذ المدارس من مجتمع الميم",
    "حصار غزة",
    "مشدود على الحبل برج ايفل",
    "حفل ميت غالا",
    "راقصة محورية",
    "حدود روسيا البيضاء بولندا",
    "تطلق الولايات المتحدة الأمريكية أحداث الجسم الغريب",
    "ممرات الدراجات المنبثقة",
    "تحطم طائرة في ميلانو",
    "مسيرة المناخ قبل الانتخابات",
    "رحلة إخلاء أفغانستان المحظورة",
    "مسيرة الإجهاض في أمريكا اللاتينية",
    "الحجاج يحيون ذكرى الأربعين",
    "بلا مأوى بعد الفيضان",
    "احتجاج ضد لقاح ملبورن",
    "نشطاء يغلقون ميناء شل",
    "المظلة الصفراء للاحتجاج",
    "اعتقل خلال مداهمات في ليبيا",
    "تزايد المجاعة في لبنان",
    "تسمح أوروغواي بدخول الماريجوانا إلى السجون",
    "احتجاج ضد اللقاح في ملبورن",
    "ضعف الحضور في مراكز التطعيم",
    "عالم التكنولوجيا الحيوية يفوز بالجائزة",
    "لقاح كوفيد للأطفال",
    "لقاح كوفيد وتغيرات الدورة الشهرية",
    "'فخ البروتين' هو اختراق علمي",
    "متلازمة هافانا",
    "تتبرع الولايات المتحدة بجرعات لقاح للدول ذات الدخل المنخفض",
    "ظهور اللدائن الدقيقة",
    "Fortnight ممنوع من متجر التطبيقات",
    "البيتكوين محظور في الصين",
    "NFTs ميمي الفيروسية",
    "حركة 'مجهول' تسرب البيانات",
    "الألعاب تشجع المراهقين على القراءة",
    "مختبر الإرهاب البيولوجي في العاصمة",
    "انفجار طواس",
    "التدافع وايومنغ blockchain",
    "محاكمة مؤسس ثيرانوس إليزابيث هولمز",
    "تخطط Apple لفحص صور المستخدم",
    "مصانع النمل الكيماوي",
    "تواجه TikTok مطالبة جماعية",
    "E.coli الطاقة المتجددة",
    "الصين حظر الألعاب",
    "برنامج التجسس بيغاسوس",
    "أصغر مصري متخصص في الذكاء الاصطناعي في Microsoft",
    "التحرير الجيني كعلاج لفيروس نقص المناعة البشرية",
    "ثورة تحرير الجينوم الزراعة",
    "تايوان تطلق تدريبات دفاعية بيولوجية",
    "بيض سائل نباتي جديد",
    "أصل جيني ثلاثي",
    "جنون العملات المشفرة في نيجيريا",
    "مجلس الشيوخ على الفيسبوك يسمع سلامة المراهقين",
    "مفاعل الثوريوم الصيني",
    "المركبة الفضائية جونو",
    "3D مطبوعة تسلا",
    "لعبة بونغ السيطرة على العقل",
    "براعة شبه آلية",
    "واجهة الدماغ والحاسوب",
    "القمر الصناعي لاندسات 9",
    "اختبار إعادة توجيه الكويكب المزدوج",
    "البكتيريا التي تدمر الخلايا السرطانية",
    "الزراعة على المريخ",
    "مجسات عصبية لدرجة الحرارة واللمس",
    "الصور الأولى لعطارد",
    "8 سنوات فلكي برازيلي",
    "هبوط الافعى",
    "جلد الحرباء الاصطناعي",
    "بوت التسليم",
    "حديقة شمسية ذات موقع واحد",
    "استخدام المحاكاة الحيوية للحصول على مياه نظيفة",
    "مركز تقليد الطبيعة",
    "المضلع السيبراني",
    "شاحنة Digi",
    "بطارية ميجا تسلا",
    "أجهزة مطبوعة ثلاثية الأبعاد",
    "التطبيق يعطل الرعاية الصحية",
    "فيلم الفضاء الروسي",
    "اختبار صاروخ تفوق سرعة الصوت",
    "ميثاق AUPUS",
    "شراء طائرات عسكرية من الصين",
    "حلول blockchain للدفاع الوطني",
    "المزيد من المال للدفاع",
    "طالبان تغزو كابول",
    "جماعة متطرفة محلية",
    "برنامج التجسس بيغاسوس",
    "انسحاب الولايات المتحدة من أفغانستان",
    "دعاية داعش",
    "وكالة المخابرات المركزية السرية كابول",
    "كتيبة العمل السريع",
    "مؤامرة فورت ديتريك الصينية",
    "حزمة الضرائب العالمية",
    "الانتخابات الفيدرالية الألمانية",
    "تصنيف أنجيلا ميركل",
    "ضرائب أعلى على الشركات",
    "المزيد من المال للمعلمين",
    "الإجراءات القانونية بشأن صفقات القناع",
    "الجماعات اليهودية تنتقد بودت بسبب ادعاء الحرب العالمية الثانية",
    "يجب أن تسرع NL للوفاء بسياسة المناخ في الاتحاد الأوروبي",
    "صبي يبلغ من العمر 12 عامًا يفوز بحقه في التطعيم",
    "نعم / نعم ملصق البريد غير الهام",
    "مسيرة أزمة الإسكان",
    "انتقال الطاقة ليس آمنًا عبر الإنترنت",
    "دعوة حزب الماوري لإعادة تسمية نيوزيلندا",
    "تمت إعادة تسمية جزيرة فريزر باسم K’gari",
    "حقوق ملكية الأراضي في البرازيل",
    "الدمى المتحركة بوريس جونسون",
    "اختطاف صحفي",
    "قانون الإجهاض في تكساس",
    "نزاع جزيرة اليابان",
    "السودان الانقلاب الفاشل",
    "الموارد الطبيعية غير المستغلة في أفغانستان",
    "إضراب المناخ العالمي",
    "المكسيك تخفض عدد عمليات الإجهاض إلى عشريتها",
    "تكساس أزمات عمليات الإجهاض",
    "اسكتلندا أربعة أيام عمل في الأسبوع",
    "نثر جثث الحيوانات",
    "أوراق باندورا",
    "قافلة الوزير تقتل مزارعين",
    "طائرة بدون طيار باكستانية تسقط أسلحة",
    "تدريب الجيش الإيراني في أذربيجان",
    "إطلاق الغاز المسيل للدموع",
    "تحسب لا بالما التكلفة",
    "روبوكوب حقيقي",
    "تتبع الصلب الصين",
    "بيع غير قانوني غابات الأمازون المطيرة",
    "Facebook يبيع غابات الأمازون المطيرة",
    "الجفاف الضخم في كاليفورنيا",
    "إيبولا الكونغو",
    "محطات توليد الكهرباء اللبنانية",
    "نقص الوقود في لبنان",
    "لا بنزين انجلترا",
    "طائرة بدون طيار تهاجم مطار السعودية",
    "تكثيف القوات الإثيوبية",
    "انقطاع كبير في Facebook",
    "الفيسبوك أسفل مرة أخرى",
    "طالبان 2.0",
    "خطط الإجهاض في تكساس",
    "عبور دارين جاب",
    "غابة الهجرة بنما",
    "مستنقعات الملح تنقذ الأرواح",
    "تسرب النفط في كاليفورنيا",
    "السنفرة الملوثة",
    "التعدين في أعماق البحار",
    "خيار البحر متعدد المعادن",
    "أسماك دايكوكو البحرية المفلطحة",
    "مخلوق بحري ذو مجسات",
    "نازح الأخطبوط دامبو",
    "غرينبيس توقف زيت السمك",
    "صقر منقذ طويل الأرجل",
    "رحلة مؤلمة بنما",
    "ضرب إعصار إيدا",
    "فوائد قرش النمر",
    "ناطحة سحاب يمنية قديمة",
    "شلالات فيكتوريا تتقلص",
    "فريق تنظيف التسرب النفطي",
    "ازدهار الأفوكادو في كولومبيا",
    "الذهب الأخضر كولومبيا",
    "مسابقة التعدين في قاع البحار",
    "عمال مصانع اللحوم",
    "الحياة حفرة Jezero",
    "سبيس- X سبلاش",
    "الرحلات الافتراضية الأثرية",
    "إنتاج حبوب كوفيد -19",
    "الممثل الروسي ISS",
    "عالم آثار النرويج للتزلج",
    "إصابة زلزال باكستان",
    "زيت شاطئ هنتنغتون",
    "شرطة Bodycam أوهايو",
    "البنتاغون سرب مقاطع فيديو",
    "التوترات الصين تايوان",
    "أزمة رقائق إنتل العالمية",
    "يبدأ موسم السيكادا",
    "المنخفض الاستوائي هنري",
    "تحضير نيكولا الاستوائية",
    "قاعدة طالبان الجوية باجرام",
    "انهيار أمواج فلوريدا",
    "برانسون في الفضاء",
    "يطير غزو جامايكا",
    "مسبح الحمم السوداء",
    "بركة ابتلاع الحمم البركانية",
    "توصي منظمة الصحة العالمية بلقاح الملاريا",
    "Chang'e 5 عينات القمر",
    "اعادة توحيد الصين",
    "احتجاجات المزارعين العنف الهند",
    "أزمة الطاقة في دلهي",
    "السعودية تستحوذ على نيوكاسل",
    "زلزال باكستان",
    "أقدم ديناصور ذوات الأقدام",
    "2700 عام مرحاض القدس",
    "الدبابير الآسيوية الغازية",
    "مهرجان الهذيان تسلا gigafactory",
    "جيجاراف",
    "الصين الجدات الرقص",
    "آرب 91 هابل",
    "كينيا تحظر الرقص المحلي",
    "Kwara Kwara رقصة غير أخلاقية",
    "حطام سفن جاليبولي",
    "إشعاع المريخ",
    "লা পালমা আগ্নেয়গিরির অগ্ন্যুৎপাত",
    "লা পালমা তীব্র হয়",
    "COP26 জলবায়ু সম্মেলন",
    "কোভিড বুস্টার জাব",
    "শিশু টিকা",
    "বৈশ্বিক দাবানল",
    "আমেরিকার প্রাচীনতম পায়ের ছাপ",
    "আফ্রিকান অ্যানকাইলোসর",
    "ফুয়েগো আগ্নেয়গিরি বিস্ফোরিত হয়",
    "ভোজ্য পোকামাকড়",
    "টাইটানোকোরিস জীবাশ্ম লাভ করে",
    "পানির তীব্র সংকট",
    "অভিবাসী পাখি NYC এর আকাশচুম্বী ভবনে আছড়ে পড়ে",
    "সবচেয়ে বড় গাছে বনে আগুন",
    "আকস্মিক বন্যা সতর্কতা",
    "সিঙ্গাপুর বেবি পান্ডা",
    "ভিয়েতনাম ঝড়",
    "অস্ট্রেলিয়ার ভূমিকম্প",
    "পালানো ঘোড়ার পাল",
    "দক্ষিণ অস্ট্রেলিয়ান সিনকহোল",
    "ধ্বংসস্তূপ প্রবাহ রাস্তা বন্ধ করে দেয়",
    "জার্মানি ও বেলজিয়ামে বন্যা আঘাত হানে",
    "ব্রেক্সিট কাঠের ঘাটতি",
    "লারোস সম্প্রদায় কাদায় আটকে আছে",
    "জর্ডান লাল হয়ে গেছে",
    "ক্রান্তীয় ঝড় স্যাম",
    "দ্রুত উষ্ণায়ন",
    "রেকর্ড-উচ্চ তাপমাত্রা",
    "হারিকেন ইডা",
    "নতুন কোভিড বৈকল্পিক",
    "স্ট্রাটোস্ফিয়ারিক পোলার ঘূর্ণি",
    "ফরো দ্বীপপুঞ্জকে হত্যা করে ডলফিন",
    "অ্যান্টার্কটিকার ওজোন গর্ত",
    "Ivermectin কোভিড চিকিত্সা",
    "ইরাক ও সিরিয়ায় খরা",
    "নতুন ফাটল দেখা দেয়",
    "বৈশ্বিক বর্জ্য পর্যালোচনা",
    "জিন সম্পাদনা ইঁদুর",
    "কোয়ান্টাম যোগাযোগ নেটওয়ার্ক",
    "কুমির",
    "প্রত্নতাত্ত্বিক মানুষের প্রতীক",
    "বিশ্বব্যাপী মৌমাছির পতন",
    "বৈশ্বিক বরফ ক্ষয়",
    "TOI-178 এক্সোপ্ল্যানেট",
    "স্বচ্ছ কাঠ তৈরি",
    "স্পুটনিক-ভি",
    "ফসফিন ভেনাস",
    "মিথেন মঙ্গল",
    "আমাজন বেসিন নির্গত হয়",
    "ড্রিলিং মঙ্গল",
    "রোভার মঙ্গলে ড্রিল করে",
    "গ্রিনল্যান্ড বরফ মুক্ত",
    "বিটকয়েন খনির নির্গমন",
    "সকালের গৌরব পপ-আপ বসন্ত",
    "আর্কিয়া ইয়েলস্টোন পার্ক",
    "Borgs",
    "কালো গহ্বরের পিছনে আলো",
    "বায়োপ্রিন্টিং মাংস",
    "Qeqertaq Avannarleq",
    "গ্রিনল্যান্ড নতুন দ্বীপ",
    "সবচেয়ে উত্তরের স্থলভাগ",
    "মঙ্গল পাথরের নমুনা",
    "মানুষের পায়ের ছাপ নিউ মেক্সিকো",
    "অবৈধ খনির অপারেশন",
    "ক্যালিফোর্নিয়ার তেল ছিটানো",
    "চীনের কয়লার অভাব",
    "ঘূর্ণিঝড় শাহীন",
    "থাইল্যান্ডে বন্যার সতর্কতা",
    "ক্রমবর্ধমান খাদ্য মরুভূমি",
    "বিরল আরবীয় চিতা",
    "ওরিওনিড উল্কা ঝরনা",
    "স্থান পর্যটন",
    "স্পেসএক্স মহাকাশ পর্যটক",
    "হাইতিয়ান অভিবাসী শিবির টেক্সাস",
    "পদত্যাগ করছেন অ্যাঞ্জেলা মার্কেল",
    "সাও পাওলোতে স্থানান্তর পরিষেবা",
    "বলিভিয়া সীমান্ত থেকে অভিবাসীদের নির্বাসন",
    "কলম্বিয়া সীমান্তে আটকে পড়া অভিবাসীরা",
    "একজন মানুষ সরকারের সামনে নিজেকে পুড়িয়ে দিয়েছে",
    "অভিবাসন রোধে বিদেশী বিনিয়োগ",
    "সালসা নৃত্যশিল্পী হয়ে উঠেছিল মৌলবাদী প্রতিবাদী",
    "যুক্তরাজ্যের লরি চালক সংকট",
    "ডান্স কোম্পানি বিদেশে টিপটয়েং",
    "বিশ্বের সর্বশ্রেষ্ঠ নৃত্য উৎসব",
    "নাচ প্রেমীদের জন্য সাও পাওলো",
    "স্থান সরানো একটি মুভ",
    "ডেভেলপমেন্ট জোন বাতিলের আন্দোলনে যোগ দেয় তেলা",
    "বলসোনারো চলাচলে নিষেধাজ্ঞা প্রত্যাখ্যান করেছেন",
    "বং মেঘ দাবা খোলার",
    "ব্রাজিলিয়ান কার্নভাল",
    "ডোভার বন্দরের বিক্ষোভকারীরা",
    "বিনামূল্যে গণপরিবহন উপভোগ করুন",
    "আমস্টারডাম অ্যালবার্ট হেইনের দোকানগুলি আঠালো বন্ধ",
    "দক্ষিণ আফ্রিকার নাগরিক অস্থিরতা",
    "এলজিবিটি স্কুলের ছাত্ররা প্রতিবাদ করে",
    "গাজায় অবরোধ",
    "টাইট্রপ ওয়াকার আইফেল টাওয়ার",
    "মেট গালা",
    "পিভটিং নর্তকী",
    "বেলারুশ পোল্যান্ড সীমান্ত",
    "ইউএসএ ইউএফও ইভেন্ট প্রকাশ করে",
    "পপ-আপ বাইক লেন",
    "মিলান বিমান দুর্ঘটনা",
    "নির্বাচনের আগে জলবায়ু সমাবেশ",
    "অবরুদ্ধ আফগানিস্তান উচ্ছেদ বিমান",
    "গর্ভপাত মার্চ ল্যাটিন আমেরিকা",
    "তীর্থযাত্রীরা আরবাইনকে স্মরণ করে",
    "বন্যার পর গৃহহীন",
    "মেলবোর্নে ভ্যাকসিন বিরোধী প্রতিবাদ",
    "নেতাকর্মীরা শেল বন্দর অবরোধ করে",
    "হলুদ ছাতার প্রতিবাদ",
    "লিবিয়ায় অভিযানের সময় আটক করা হয়",
    "ক্রমবর্ধমান অনাহার লেবানন",
    "উরুগুয়ে মারিজুয়ানাকে কারাগারে প্রবেশের অনুমতি দেয়",
    "মেলবোর্নে ভ্যাকসিন-বিরোধী প্রতিবাদ",
    "টিকা কেন্দ্রে উপস্থিতি কম",
    "বায়োটেক বিজ্ঞানী পুরস্কার জিতেছেন",
    "শিশুদের কোভিড ভ্যাকসিন",
    "কোভিড ভ্যাকসিন এবং মাসিক পরিবর্তন",
    "'প্রোটিন ফাঁদ' বৈজ্ঞানিক অগ্রগতি",
    "হাভানা সিনড্রোম",
    "যুক্তরাষ্ট্র নিম্ন আয়ের দেশগুলোকে ভ্যাকসিনের মাত্রা দান করে",
    "মাইক্রোপ্লাস্টিকসের উত্থান",
    "অ্যাপ স্টোর থেকে পনেরো রাত নিষিদ্ধ",
    "চীনে বিটকয়েন নিষিদ্ধ",
    "ভাইরাল মেম এনএফটি",
    "'বেনামী' আন্দোলন তথ্য ফাঁস করে",
    "গেমিং কিশোরদের পড়তে উৎসাহিত করে",
    "ডিসিতে বায়োটেরিজম ল্যাব",
    "তুয়াস বিস্ফোরণ",
    "ওয়াইমিং ব্লকচেইনে পদদলিত",
    "থেরানোসের প্রতিষ্ঠাতা এলিজাবেথ হোমসের বিচার",
    "অ্যাপল ব্যবহারকারীদের ছবি স্ক্যান করার পরিকল্পনা করেছে",
    "রাসায়নিক পিঁপড়ার কারখানা",
    "TikTok গণ দাবির মুখোমুখি",
    "E. কোলি নবায়নযোগ্য শক্তি",
    "চীনে গেমিং নিষিদ্ধ",
    "পেগাসাস স্পাইওয়্যার",
    "মাইক্রোসফ্টে মিশরের সর্বকনিষ্ঠ এআই পেশাদার",
    "এইচআইভি নিরাময় হিসাবে জিন সম্পাদনা",
    "জিনোম সম্পাদনা কৃষি বিপ্লব",
    "তাইওয়ান বায়োডেফেন্স ড্রিলস চালু করেছে",
    "নতুন উদ্ভিদ ভিত্তিক তরল ডিম",
    "ত্রিপক্ষীয় জেনেটিক উৎপত্তি",
    "নাইজেরিয়া ক্রিপ্টোকারেন্সির উন্মাদনা",
    "কিশোর নিরাপত্তার কথা শুনছে ফেসবুক সেনেট",
    "চীন থোরিয়াম চুল্লি",
    "জুনো মহাকাশযান",
    "থ্রিডি প্রিন্টেড টেসলা",
    "মন নিয়ন্ত্রণ পং খেলা",
    "সেমি-অটো সহজাত",
    "মস্তিষ্ক-কম্পিউটার ইন্টারফেস",
    "Landsat 9 স্যাটেলাইট",
    "ডবল গ্রহাণু পুনireনির্দেশ পরীক্ষা",
    "ব্যাকটেরিয়া যা ক্যান্সার কোষ ধ্বংস করে",
    "মঙ্গল গ্রহে চাষ",
    "তাপমাত্রা এবং স্পর্শের জন্য স্নায়ু সেন্সর",
    "বুধের প্রথম ছবি",
    "8 বছর বয়সী ব্রাজিলিয়ান জ্যোতির্বিজ্ঞানী",
    "ভাইপার ল্যান্ডিং",
    "গিরগিটি কৃত্রিম ত্বক",
    "ডেলিভারি বট",
    "একক সাইট সৌর পার্ক",
    "পরিষ্কার জলের জন্য বায়োমিমিক্রি ব্যবহার করা",
    "বায়োমাইমিক্রি সেন্টার",
    "সাইবার বহুভুজ",
    "ডিজি ট্রাক",
    "টেসলা মেগা ব্যাটারি",
    "3 ডি মুদ্রিত অঙ্গ",
    "অ্যাপ স্বাস্থ্যসেবা ব্যাহত করে",
    "রাশিয়ান মহাকাশ চলচ্চিত্র",
    "হাইপারসনিক ক্ষেপণাস্ত্র পরীক্ষা",
    "AUPUS চুক্তি",
    "চীন থেকে সামরিক বিমান কেনা",
    "জাতীয় প্রতিরক্ষার জন্য ব্লকচেইন সমাধান",
    "প্রতিরক্ষার জন্য আরো টাকা",
    "তালেবান কাবুলে আক্রমণ করে",
    "দেশীয় চরমপন্থী গোষ্ঠী",
    "পেগাসাস স্পাইওয়্যার",
    "যুক্তরাষ্ট্র আফগানিস্তান প্রত্যাহার করে",
    "আইএসের প্রচার",
    "সিআইএ গোপন কাবুল",
    "র‍্যাপিড অ্যাকশন ব্যাটালিয়ন",
    "ফোর্ট ডেট্রিক চীনা ষড়যন্ত্র",
    "গ্লোবাল ট্যাক্স প্যাক",
    "জার্মান ফেডারেল নির্বাচন",
    "অ্যাঞ্জেলা মার্কেল রেটিং",
    "উচ্চ কর্পোরেট কর",
    "শিক্ষকদের জন্য আরো টাকা",
    "মাস্ক ডিলগুলিতে আইনি ব্যবস্থা",
    "ইহুদি গোষ্ঠীগুলি দ্বিতীয় বিশ্বযুদ্ধের দাবির জন্য বাউডেটকে গালি দেয়",
    "ইউরোপীয় ইউনিয়নের জলবায়ু নীতি পূরণ করতে এনএলকে অবশ্যই গতি বাড়িয়ে তুলতে হবে",
    "12 বছর বয়সী ছেলে টিকা দেওয়ার অধিকার জিতেছে",
    "হ্যাঁ/হ্যাঁ জাঙ্ক মেল স্টিকার",
    "আবাসন সংকট মিছিল",
    "শক্তি পরিবর্তন সাইবার নিরাপদ নয়",
    "নিউজিল্যান্ডের নাম পরিবর্তন করার জন্য মাওরি পার্টির আহ্বান",
    "ফ্রেজার দ্বীপের নামকরণ করা হয়েছে কে’গারি",
    "ব্রাজিল আদিবাসী ভূমির অধিকার",
    "বরিস জনসন ম্যাপেটস",
    "সাংবাদিক অপহরণ",
    "টেক্সাসের গর্ভপাত আইন",
    "জাপানের দ্বীপ বিরোধ",
    "সুদানের ব্যর্থ অভ্যুত্থান",
    "আফগানিস্তানের অব্যবহৃত প্রাকৃতিক সম্পদ",
    "বৈশ্বিক জলবায়ু ধর্মঘট",
    "মেক্সিকো গর্ভপাতকে হ্রাস করে",
    "টেক্সাস গর্ভপাতকে বিকল করে দেয়",
    "স্কটল্যান্ড সপ্তাহে চার দিন কাজ করে",
    "পশুর মৃতদেহ পরিষ্কার করা",
    "প্যান্ডোরা কাগজপত্র",
    "মন্ত্রীর কনভয় কৃষকদের হত্যা করে",
    "পাকিস্তানি ড্রোন অস্ত্র ফেলে দেয়",
    "ইরান সেনাবাহিনী আজারবেজদজান ড্রিল করে",
    "কাঁদানে গ্যাস ছোড়া হয়",
    "লা পালমা খরচ গণনা করে",
    "বাস্তব জীবনের রোবোকপ",
    "ট্র্যাকিং চায়না স্টিল",
    "অ্যামাজন রেইন ফরেস্ট অবৈধ বিক্রয়",
    "ফেসবুক আমাজন রেইন ফরেস্ট বিক্রি করে",
    "ক্যালিফোর্নিয়ার মেগা-খরা",
    "কঙ্গো ইবোলা",
    "লেবাননের বিদ্যুৎ কেন্দ্র",
    "জ্বালানি সংকট লেবানন",
    "পেট্রোল ইংল্যান্ড নেই",
    "সৌদি বিমানবন্দরে ড্রোন হামলা",
    "ইথিওপিয়ার সৈন্যরা তীব্র হয়",
    "ফেসবুকে বড় ধরনের বিভ্রাট",
    "আবার ফেসবুক ডাউন",
    "তালিবান 2.0",
    "টেক্সাসের গর্ভপাতের পরিকল্পনা",
    "ডারিয়েন গ্যাপ অতিক্রম",
    "পানামা মাইগ্রেশন জঙ্গল",
    "লবণ মার্শ জীবন বাঁচায়",
    "ক্যালিফোর্নিয়ার তেল ছিটানো",
    "দূষিত স্যান্ডারলিং",
    "গভীর সমুদ্র খনন",
    "পলিম্যাটালিক সমুদ্রের শসা",
    "Daikoku seamount flatfish",
    "তামাক সমুদ্রের প্রাণী",
    "স্থানচ্যুত ডাম্বো অক্টোপাস",
    "গ্রীনপিস মাছের তেল বন্ধ করে",
    "লম্বা পাওয়ালা বাজদার উদ্ধার",
    "আঘাতমূলক যাত্রা পানামা",
    "হারিকেন আইডা আঘাত হানে",
    "টাইগার হাঙরের সুবিধা",
    "ইয়েমেনের প্রাচীন আকাশচুম্বী ভবন",
    "ভিক্টোরিয়া সঙ্কুচিত হয়",
    "তেল ছিটানো পরিষ্কার দল",
    "কলম্বিয়া অ্যাভোকাডো বুম",
    "কলম্বিয়া সবুজ সোনা",
    "সমুদ্রতল খনন প্রতিযোগিতা",
    "মাংস উদ্ভিদ শ্রমিক",
    "জিজেরো গর্ত জীবন",
    "স্পেস-এক্স স্প্ল্যাশ ডাউন",
    "প্রত্নতাত্ত্বিক ভার্চুয়াল ফ্লাইট",
    "কোভিড -১ pill পিল উৎপাদন",
    "রাশিয়ার অভিনেতা আইএসএস",
    "প্রত্নতত্ত্ববিদ নরওয়ে স্কি",
    "পাকিস্তানের ভূমিকম্পে আহত",
    "হান্টিংটন সৈকত তেল",
    "বডি ক্যাম ওহাইও পুলিশ",
    "পেন্টাগনের ভিডিও ফাঁস",
    "উত্তেজনা চীন তাইওয়ান",
    "ইন্টেল গ্লোবাল চিপ সংকট",
    "সিকাদা মৌসুম শুরু",
    "ক্রান্তীয় বিষণ্নতা হেনরি",
    "ক্রান্তীয় নিকোলাস প্রস্তুত করুন",
    "তালেবান বাগ্রাম বিমানঘাঁটি",
    "সার্ফাইড ফ্লোরিডাকে সঙ্কুচিত করুন",
    "মহাকাশে ব্র্যানসন",
    "ফ্লাই আক্রমণ জ্যামাইকা",
    "কালো লাভা সুইমিং পুল",
    "লাভা গ্রাসকারী পুল",
    "ডাব্লুএইচও ম্যালেরিয়ার ভ্যাকসিনের সুপারিশ করে",
    "Chang'e 5 চাঁদের নমুনা",
    "পুনর্মিলন চীন",
    "ভায়োলেন্স ইন্ডিয়া কৃষক বিক্ষোভ",
    "দিল্লিতে বিদ্যুৎ সংকট",
    "সৌদি দখল নিউক্যাসল",
    "পাকিস্তানের ভূমিকম্প",
    "প্রাচীনতম থেরোপড ডাইনোসর",
    "2 700 বছরের পুরনো টয়লেট জেরুজালেম",
    "আক্রমণাত্মক এশিয়ান হর্নেট",
    "টেসলা গিগাফ্যাক্টরি রেভ উৎসব",
    "গিগারভ",
    "চীন নাচছে নানীরা",
    "অর্প 91 হাবল",
    "কেনিয়া স্থানীয় নৃত্য নিষিদ্ধ করেছে",
    "Kwara Kwara অনৈতিক নৃত্য",
    "গ্যালিপোলি জাহাজের ধ্বংসাবশেষ",
    "বিকিরণ মঙ্গল",
    "Vulkanausbruch auf La Palma",
    "La Palma intensiviert",
    "Klimakonferenz COP26",
    "Covid-Booster-Jab",
    "Kinderimpfstoff",
    "Globale Waldbrände",
    "Älteste Fußabdrücke in Amerika",
    "Afrikanischer Ankylosaurier",
    "Vulkan Fuego bricht aus",
    "Essbare Insekten",
    "Titanokorys Gainesi Fossil",
    "Schwerer Wassermangel",
    "Zugvögel stürzen in NYC-Wolkenkratzer",
    "Größter Baumwaldbrand",
    "Sturzflut Warnung",
    "Singapur Babypanda",
    "Vietnam Sturm",
    "Erdbeben in Australien",
    "Herde entflohener Pferde",
    "Südaustralisches Erdloch",
    "Trümmerstrom blockiert Straßen",
    "Überschwemmungen treffen Deutschland und Belgien",
    "Brexit-Holzknappheit",
    "Larose-Gemeinde steckt im Schlamm fest",
    "See wurde rot Jordan",
    "Tropischer Sturm Sam",
    "Schnelle Erwärmung",
    "Rekordhohe Temperatur",
    "Hurrikan Ida",
    "Neue Covid-Variante",
    "Stratosphärischer Polarwirbel",
    "Delfinschlachten Färöer",
    "Ozonloch über der Antarktis",
    "Ivermectin-Covid-Behandlung",
    "Dürre im Irak und in Syrien",
    "Neue Risse brechen aus",
    "Globale Abfallbewertung",
    "Mäuse mit Gen-Editierung",
    "Quantenkommunikationsnetzwerk",
    "Krokodile",
    "Archäologen menschliche Symbole",
    "Weltweiter Bienenrückgang",
    "Globaler Eisverlust",
    "TOI-178 Exoplaneten",
    "Transparentes Holz gefertigt",
    "Sputnik-V",
    "Phosphin Venus",
    "Methan Mars",
    "Amazonasbecken emittiert",
    "Bohren Mars",
    "Rover bohrt Mars",
    "Grönland eisfrei",
    "Bitcoin-Mining-Emissionen",
    "Morning Glory Pop-up Frühling",
    "Archaea Yellowstone Park",
    "Borgs",
    "Licht hinter Schwarzem Loch",
    "Bioprinting Fleisch",
    "Qeqertaq Avannarleq",
    "Grönland neue Insel",
    "Nördlichste Landmasse",
    "Probenahme von Marsgestein",
    "menschliche Fußabdrücke New Mexico",
    "Illegaler Bergbaubetrieb",
    "Ölpest in Kalifornien",
    "Kohleknappheit in China",
    "Zyklon Shaheen",
    "Flutwarnung in Thailand",
    "Wachsende Nahrungswüste",
    "Seltener arabischer Leopard",
    "Orionid Meteorschauer",
    "Weltraum Tourismus",
    "SpaceX-Weltraumtouristen",
    "Haitianisches Migrantenlager Texas",
    "Angela Merkel tritt zurück",
    "Umzugsservice in São Paulo",
    "Abschiebung von Migranten von der bolivianischen Grenze",
    "Migranten an der Grenze zu Kolumbien gestrandet",
    "Mann hat sich vor der Regierung angezündet",
    "Ausländische Investitionen zur Verhinderung von Migration",
    "Salsa-Tänzerin wurde radikaler Demonstrant",
    "Krise bei Lkw-Fahrern in Großbritannien",
    "Tanzkompanie auf Zehenspitzen im Ausland",
    "das größte Tanzfestival der Welt",
    "São Paulo für Tanzliebhaber",
    "Orte, um einen Zug zu machen",
    "Tela schließt sich der Bewegung an, um Entwicklungszonen aufzuheben",
    "Bolsonaro lehnt Bewegungseinschränkungen ab",
    "Bong Cloud Schach Eröffnung",
    "Brasilianischer Karneval",
    "Demonstranten im Hafen von Dover",
    "Genießen Sie kostenlose öffentliche Verkehrsmittel",
    "Amsterdam Albert Heijn Läden zugeklebt",
    "Unruhen in Südafrika",
    "LGBT-Schüler protestieren",
    "Blockade in Gaza",
    "Seiltänzer Eiffelturm",
    "Die Met Gala",
    "Schwenkender Tänzer",
    "Grenze zu Weißrussland Polen",
    "USA veröffentlichen UFO-Ereignisse",
    "Pop-up-Radwege",
    "Flugzeugabsturz in Mailand",
    "Klimakundgebung vor Wahlen",
    "Evakuierungsflug aus Afghanistan blockiert",
    "Abtreibungsmarsch Lateinamerika",
    "Pilger gedenken Arbaeen",
    "Obdachlos nach Flut",
    "Protest gegen Impfung in Melbourne",
    "Aktivisten blockieren Shells Hafen",
    "Protest gegen den gelben Regenschirm",
    "Bei Razzien in Libyen festgenommen",
    "Wachsender Hunger im Libanon",
    "Uruguay erlaubt Marijiuana in Gefängnissen",
    "Protest gegen Impfung in Melbourne",
    "Geringe Teilnahme an Impfzentren",
    "BioNTech-Wissenschaftler gewinnt Preis",
    "Covid-Impfung für Kinder",
    "Covid-Impfung und Menstruationsänderungen",
    "'Proteinfalle' ist wissenschaftlicher Durchbruch",
    "Havanna-Syndrom",
    "USA spenden Impfstoffdosen an Länder mit niedrigem Einkommen",
    "Anstieg von Mikroplastik",
    "14 Tage im App Store gesperrt",
    "Bitcoin in China verboten",
    "Virale Meme-NFTs",
    "„Anonyme“ Bewegung gibt Daten durch",
    "Gaming ermutigt Jugendliche zum Lesen",
    "Bioterror-Labor in DC",
    "Tuas-Explosion",
    "Blockchain-Ansturm in Wyoming",
    "Theranos-Gründerin Elizabeth Holmes Prozess",
    "Apple plant, Benutzerbilder zu scannen",
    "Chemische Ameisenfabriken",
    "TikTok steht vor Massenanspruch",
    "E.coli erneuerbare Energie",
    "Glücksspielverbot in China",
    "Pegasus-Spyware",
    "Ägyptischer jüngster KI-Profi bei Microsoft",
    "Gene Editing als Heilmittel für HIV",
    "Genome Editing Farming Revolution",
    "Taiwan startet Bioverteidigungsübungen",
    "Neues pflanzliches Flüssigei",
    "Dreigliedriger genetischer Ursprung",
    "Kryptowährungswahn in Nigeria",
    "Facebook-Senat hört Jugendsicherheit an",
    "Thoriumreaktor in China",
    "Juno-Raumschiff",
    "3D gedruckter Tesla",
    "Gedankenkontroll-Pong-Spiel",
    "Halbautomatischer Einfallsreichtum",
    "Gehirn-Computer-Schnittstelle",
    "Landsat 9 Satellit",
    "Doppelter Asteroiden-Umleitungstest",
    "Bakterien, die Krebszellen zerstören",
    "Landwirtschaft auf dem Mars",
    "Nervensensoren für Temperatur und Berührung",
    "Erste Bilder von Merkur",
    "8-jähriger brasilianischer Astronom",
    "Viperlandung",
    "Chamäleon künstliche Haut",
    "Lieferbot",
    "Einzelstandort-Solarpark",
    "Mit Bionik für sauberes Wasser",
    "Zentrum für Bionik",
    "Cyber-Polygon",
    "Digi-Truck",
    "Tesla Mega-Batterie",
    "3D gedruckte Organe",
    "App stört das Gesundheitswesen",
    "Russischer Weltraumfilm",
    "Hyperschall-Raketentests",
    "AUPUS-Pakt",
    "Kauf von Militärflugzeugen aus China",
    "Blockchain-Lösungen für die Landesverteidigung",
    "Mehr Geld für die Verteidigung",
    "Taliban marschieren in Kabul ein",
    "Inländische extremistische Gruppe",
    "Pegasus-Spyware",
    "US-Abzug Afghanistan",
    "IS-Propaganda",
    "CIA-Geheimnis Kabul",
    "Schnelleinsatzbataillon",
    "Fort Detrick chinesische Verschwörung",
    "Globales Steuerpaket",
    "Bundestagswahl",
    "Bewertung von Angela Merkel",
    "Höhere Unternehmenssteuern",
    "Mehr Geld für Lehrer",
    "Rechtliche Schritte bei Maskengeschäften",
    "Jüdische Gruppen verurteilen Baudet wegen Behauptungen über den Zweiten Weltkrieg",
    "NL muss sich beschleunigen, um die EU-Klimapolitik zu erfüllen",
    "Junge, 12, gewinnt das Recht auf Impfung",
    "Ja/Ja Junk-Mail-Aufkleber",
    "Wohnungskrise März",
    "Energiewende nicht cybersicher",
    "Maori-Partei ruft zur Umbenennung Neuseelands auf",
    "Fraser Island in K’gari . umbenannt",
    "Brasilien indigene Landrechte",
    "Boris Johnson-Muppets",
    "Entführung eines Journalisten",
    "Abtreibungsgesetz von Texas",
    "Japans Inselstreit",
    "Sudan gescheiterter Putsch",
    "Afghanistans unerschlossene natürliche Ressourcen",
    "Globaler Klimastreik",
    "Mexiko entkriminalisiert Abtreibungen",
    "Texas kriminalisiert Abtreibungen",
    "Schottland Vier-Tage-Woche",
    "Aufräumen von Tierkadavern",
    "Pandora-Papiere",
    "Ministerkonvoi tötet Bauern",
    "Pakistanische Drohne lässt Waffen fallen",
    "Iranische Armeeübung Aserbejdzan",
    "Tränengas befeuert",
    "La Palma zählt Kosten",
    "Robocop aus dem echten Leben",
    "Porzellanstahl aufspüren",
    "Illegaler Verkauf Amazonas-Regenwald",
    "Facebook verkauft Amazonas-Regenwald",
    "Kalifornien Mega-Dürre",
    "Kongo-Ebola",
    "Libanesische Kraftwerke",
    "Kraftstoffmangel Libanon",
    "Kein Benzin England",
    "Drohne greift saudischen Flughafen an",
    "Äthiopische Truppen verstärken sich",
    "Großer Ausfall von Facebook",
    "Facebook wieder down",
    "Taliban 2.0",
    "Abtreibungspläne in Texas",
    "Überquerung von Darien Gap",
    "Panama-Migrationsdschungel",
    "Salzwiesen rettet Leben",
    "Ölpest in Kalifornien",
    "Kontaminierter Sanderling",
    "Tiefseebergbau",
    "Polymetallische Seegurke",
    "Daikoku Seamount Plattfisch",
    "Tentakel Meeresbewohner",
    "vertriebene Dumbo-Kraken",
    "Greenpeace stoppt Fischöl",
    "Geretteter Langbeinbussard",
    "Traumatische Reise Panama",
    "Hurrikan ida getroffen",
    "Vorteile von Tigerhaien",
    "Jemen alter Wolkenkratzer",
    "Victoria fällt schrumpfen",
    "Ölverschmutzungs-Reinigungsteam",
    "Avocado-Boom in Kolumbien",
    "Kolumbien grünes Gold",
    "Wettbewerb zum Meeresbodenabbau",
    "Arbeiter in der Fleischerei",
    "Leben im Jezero-Krater",
    "Space-X spritzt runter",
    "Archäologische virtuelle Flüge",
    "Herstellung von Covid-19-Pillen",
    "Russland-Schauspieler ISS",
    "Archäologe Norwegen Ski",
    "Verletztes Erdbeben in Pakistan",
    "Huntington Strandöl",
    "Bodycam Ohio Polizei",
    "Pentagon hat Videos durchgesickert",
    "Spannungen China Taiwan",
    "Intel Globale Chipkrise",
    "Die Zikadensaison beginnt",
    "Tropische Depression Henri",
    "Bereiten Sie tropische Nicolas vor",
    "Bagram-Luftwaffenstützpunkt der Taliban",
    "Surfside florida einklappen",
    "Branson im Weltraum",
    "Fliegeninvasion Jamaika",
    "Schwimmbad aus schwarzer Lava",
    "Lavaschluckbecken",
    "WHO empfiehlt Malaria-Impfung",
    "Chang'e 5 Mondproben",
    "Wiedervereinigung China",
    "Gewalt in Indien Bauernproteste",
    "Machtkrise in Delhi",
    "Saudische Übernahme Newcastle",
    "Erdbeben in Pakistan",
    "Ältester Theropoden-Dinosaurier",
    "2 700 Jahre alte Toilette Jerusalem",
    "Invasive asiatische Hornisse",
    "Tesla Gigafactory Rave-Festival",
    "Gigarave",
    "China tanzende Omas",
    "Arp 91 Hubble",
    "Kenia verbietet lokalen Tanz",
    "Kwara Kwara Unmoralischer Tanz",
    "Gallipoli-Schiffswracks",
    "Strahlung Mars",
    "La Palma volcano eruption",
    "La Palma intensifies",
    "COP26 climate conference",
    "Covid booster jab",
    "Child vaccine",
    "Global wildfires ",
    "Oldest footprints in America",
    "African ankylosaur",
    "Fuego volcano erupts",
    "Edible insects",
    "Titanokorys gainesi fossil",
    "Severe water shortage",
    "Migrating birds crash into NYC skyscrapers",
    "Largest tree forest fire",
    "Flash flood warning",
    "Singapore baby panda",
    "Vietnam storm",
    "Australian earthquake",
    "Herd of escaped horses",
    "South Australian sinkhole",
    "Debris flow blocks roads",
    "Floods hit Germany and Belgium",
    "Brexit wood shortage",
    "Larose community stuck in mud",
    "Lake turned red Jordan",
    "Tropical Storm Sam",
    "Rapid Warming",
    "Record-high temperature",
    "Hurricane Ida",
    "New covid variant",
    "Stratospheric polar vortex",
    "Dolphin slaughter Faroe Islands",
    "Ozone hole over antarctica",
    "Ivermectin covid treatment",
    "Drought in Iraq and Syria",
    "New fissures erupt ",
    "Global waste review ",
    "Gene editing mice ",
    "Quantum communication network ",
    "Crocodilia",
    "Archaeologists human symbols",
    "Global bee decline ",
    "Global ice loss ",
    "TOI-178 exoplanets ",
    "Transparent wood manufactured ",
    "Sputnik-V ",
    "Phosphine Venus ",
    "Methane Mars ",
    "Amazon basin emits ",
    "Drilling Mars ",
    "Rover drills Mars ",
    "Greenland ice free ",
    "Bitcoin mining emissions ",
    "Morning glory pop-up spring ",
    "archaea yellowstone park ",
    "Borgs",
    "light behind black hole ",
    "Bioprinting meat ",
    "Qeqertaq Avannarleq",
    "Greenland new island ",
    "Northern most landmass ",
    "sampling Mars rock ",
    "human footprints New Mexico ",
    "Illegal mining operation",
    "California oil spill",
    "China coal shortage",
    "Cyclone Shaheen",
    "Thailand flood warning",
    "Growing food desert",
    "Rare Arabian leopard ",
    "Orionid meteor shower",
    "Space tourism",
    "SpaceX space tourists",
    "Haitian migrant camp Texas ",
    "Angela Merkel stepping down",
    "Relocation Services in São Paulo",
    "Migrant deportation from Bolivia border",
    "Migrants stranded Colombia border",
    "Man set himself on fire in front of government ",
    "Foreign investment to prevent migration",
    "Salsa dancer became radical protester",
    "UK lorry driver crisis ",
    "Dance Company Tiptoeing Abroad",
    "the World’s Greatest Dance Festival",
    "São Paulo for Dance Lovers",
    "Places to Bust a Move",
    "Tela joins movement to repeal Development Zones",
    "Bolsonaro rejects restrictions on movement",
    "Bong cloud chess opening",
    "Brazilian Carnaval",
    "Dover port protestors",
    "Enjoy free public transport",
    "Amsterdam Albert Heijn stores glued shut",
    "South Africa civil unrest",
    "LGBT school pupils protest",
    "Blockade in Gaza",
    "Tightrope walker Eiffel Tower",
    "The Met Gala",
    "Pivoting dancer",
    "Belarus Poland border",
    "USA release UFO events ",
    "Pop-up bike lanes ",
    "Milan plane crash",
    "Climate rally before elections",
    "Blocked Afghanistan evacuation flight",
    "Abortion march Latin America",
    "Pilgrims commemorate Arbaeen",
    "Homeless after flood",
    "Anti vaccine protest Melbourne ",
    "Activists block Shell’s port",
    "Yellow umbrella protest",
    "Detained during raids in Libya",
    "Growing starvation Lebanon",
    "Uruguay allows marijiuana into prisons",
    "Anti-vaccine protest Melbourne",
    "Low attendance at vaccination centers",
    "BioNTech scientist wins prize",
    "Children covid vaccine",
    "Covid vaccine and menstrual changes",
    "'protein trap' is scientific breakthrough",
    "Havana syndrome",
    "US donates vaccine doses to lower-income countries",
    "Rise of microplastics",
    "Fortnight banned from App store ",
    "Bitcoin banned in China",
    "Viral meme NFTs",
    "‘Anonymous’ Movement leaks data",
    "Gaming encourages teens to read",
    "Bioterrorism lab in DC",
    "Tuas explosion",
    "Wyoming blockchain stampede",
    "Theranos founder Elizabeth Holmes trial",
    "Apple plans scanning user images",
    "Chemical ant factories",
    "TikTok faces mass claim",
    "E.coli renewable energy",
    "China gaming ban",
    "Pegasus spyware",
    "Egyptian youngest AI professional at Microsoft",
    "Gene editing as HIV cure",
    "Genome editing farming revolution",
    "Taiwan launches biodefense drills",
    "New plant-based liquid egg",
    "Tripartite genetic origin",
    "Nigeria cryptocurrency craze",
    "Facebook senate hearing teen safety",
    "China thorium reactor",
    "Juno spacecraft ",
    "3D printed Tesla ",
    "Mind-control pong game ",
    "Semi-auto ingenuity ",
    "Brain-computer interface",
    "Landsat 9 satellite ",
    "Double Asteroid Redirection Test",
    "Bacteria that destroy cancer cells",
    "Farming on Mars",
    "Nerve sensors for temperature and touch",
    "First images of Mercury",
    "8 year old Brazilian astronomer",
    "Viper landing",
    "Chameleon artificial skin",
    "Delivery bot",
    "Single-site solar park",
    "Using biomimicry for clean water",
    "Biomimicry Center",
    "Cyber polygon ",
    "Digi Truck",
    "Tesla mega battery",
    "3d printed organs",
    "App disrupts healthcare",
    "Russian space film",
    "Hypersonic missile testing",
    "AUPUS pact",
    "purchase of military aircraft from China",
    "blockchain solutions for national defense",
    "More money for defense",
    "Taliban invade Kabul",
    "Domestic extremist group",
    "Pegasus spyware",
    "US withdrawal Afghanistan",
    "IS propaganda ",
    "CIA secret Kabul",
    "Rapid action battalion ",
    "Fort Detrick Chinese conspiracy",
    "Global tax pack",
    "German federal election",
    "Angela Merkel rating",
    "Higher corporate taxes",
    "More money for teachers",
    "Legal action on mask deals",
    "Jewish groups slam Baudet for WWII claim",
    "NL must speed up to meet EU climate policy",
    "Boy, 12, wins right to be vaccinated",
    "Yes/Yes junk mail sticker",
    "Housing crisis march",
    "Energy transition not cyber secure",
    "Maori party call to rename New Zealand",
    "Fraser island renamed K’gari",
    "Brazil Indigenous land rights",
    "Boris Johnson muppets",
    "Abduction of journalist",
    "Texas’ abortion law",
    "Japan’s island dispute",
    "Sudan failed coup",
    "Afghanistan’s untapped natural resources",
    "Global climate strike",
    "Mexico decrimalises abortions",
    "Texas crimalises abortions",
    "Scotland four day work week",
    "Scavenging animal carcasses ",
    "Pandora papers",
    "Minister’s convoy kills farmers",
    "Pakistani drone drops weapons",
    "Iran army drill Azerbejdzan",
    "Tear gas fired",
    "La Palma counts cost ",
    "Real-life robocop ",
    "Tracking china steel ",
    "Illegal sale Amazon rainforest ",
    "Facebook sells Amazon rainforest ",
    "California mega-drought ",
    "Congo ebola ",
    "Lebanese power plants",
    "Fuel shortage lebanon ",
    "No petrol england ",
    "Drone attacks Saudi airport",
    "Ethiopian troops intensify",
    "Facebook major outage",
    "Facebook down again",
    "Taliban 2.0 ",
    "Texas abortion plans ",
    "crossing Darien Gap  ",
    "panama migration jungle",
    "Salt marsh saves lives ",
    "California oil spill",
    "Contaminated sanderling ",
    "Deep sea mining ",
    "Polymetallic sea cucumber ",
    "Daikoku seamount flatfish ",
    "tentacled sea creature",
    "displaced Dumbo octopuses",
    "Greenpeace stops fish oil",
    "Rescued long-legged buzzard",
    "Traumatic journey Panama",
    "Hurricane ida hit",
    "Tiger shark benefits ",
    "Yemen ancient skyscraper ",
    "Victoria falls shrink",
    "Oil spill clean team",
    "Colombia avocado boom",
    "Colombia green gold",
    "Seabed mining contest ",
    "Meat plant workers ",
    "Jezero crater life ",
    "Space-X splash down",
    "Archaeological virtual flights ",
    "Covid-19 pill production",
    "Russia actor ISS ",
    "Archaeologist norway ski",
    "Injured pakistan earthquake",
    "Huntington beach oil ",
    "Bodycam Ohio police ",
    "Pentagon leaked videos ",
    "Tensions China Taiwan ",
    "Intel Global chip crisis ",
    "Cicada season starts",
    "Tropical depression henri",
    "Prepare tropical nicolas",
    "Taliban bagram air base",
    "Collapse surfside florida",
    "Branson in space",
    "Fly invasion Jamaica",
    "Black lava swimming pool",
    "Lava swallowing pool ",
    "WHO recommends malaria vaccine",
    "Chang'e 5 moon samples",
    "Reunification China",
    "Violence India Farmer protests",
    "Delhi power crisis",
    "Saudi takeover Newcastle",
    "Pakistani earthquake",
    "Oldest theropod dinosaur",
    "2 700 year old toilet Jerusalem",
    "Invasive Asian hornet",
    "Tesla gigafactory rave festival",
    "Gigarave ",
    "China dancing grannies",
    "Arp 91 Hubble ",
    "Kenya bans local dance",
    "Kwara Kwara Immoral dance",
    "Gallipoli shipwrecks",
    "Radiation Mars",
    "Erupción del volcán de la Palma",
    "La Palma se intensifica",
    "Conferencia climática COP26",
    "Covid booster jab",
    "Vacuna infantil",
    "Incendios forestales globales",
    "Huellas más antiguas de América",
    "Anquilosaurio africano",
    "Volcán de fuego entra en erupción",
    "Insectos comestibles",
    "Titanokorys gainesi fósil",
    "Grave escasez de agua",
    "Las aves migratorias chocan contra los rascacielos de Nueva York",
    "El incendio forestal más grande del árbol",
    "Aviso de inundación",
    "Panda bebé de Singapur",
    "Tormenta de vietnam",
    "Terremoto australiano",
    "Manada de caballos escapados",
    "Sumidero de Australia del Sur",
    "El flujo de escombros bloquea las carreteras",
    "Las inundaciones azotaron Alemania y Bélgica",
    "Escasez de madera Brexit",
    "Larose comunidad atrapada en el barro",
    "El lago se volvió rojo Jordan",
    "Tormenta tropical Sam",
    "Calentamiento rápido",
    "Temperatura récord",
    "Huracán ida",
    "Nueva variante de covid",
    "Vórtice polar estratosférico",
    "Masacre de delfines en las Islas Feroe",
    "Agujero de ozono sobre la Antártida",
    "Tratamiento con ivermectina covid",
    "Sequía en Irak y Siria",
    "Nuevas fisuras estallan",
    "Revisión global de residuos",
    "Ratones de edición de genes",
    "Red de comunicación cuántica",
    "Crocodilia",
    "Símbolos humanos arqueólogos",
    "Disminución mundial de las abejas",
    "Pérdida global de hielo",
    "Exoplanetas TOI-178",
    "Fabricado en madera transparente",
    "Sputnik-V",
    "Venus fosfina",
    "Marte metano",
    "Emisiones de la cuenca del Amazonas",
    "Perforando Marte",
    "Rover perfora Marte",
    "Groenlandia libre de hielo",
    "Emisiones mineras de Bitcoin",
    "Primavera emergente de gloria de la mañana",
    "parque archaea yellowstone",
    "Borgs",
    "luz detrás del agujero negro",
    "Carne de bioimpresión",
    "Qeqertaq Avannarleq",
    "Isla nueva de Groenlandia",
    "Masa continental más septentrional",
    "muestreo de roca de Marte",
    "huellas humanas nuevo mexico",
    "Operación de minería ilegal",
    "Derrame de petróleo de California",
    "Escasez de carbón en China",
    "Ciclón Shaheen",
    "Advertencia de inundaciones en Tailandia",
    "Desierto creciente de alimentos",
    "Leopardo árabe raro",
    "Lluvia de meteoritos oriónidas",
    "Turismo espacial",
    "Turistas espaciales SpaceX",
    "Campamento de inmigrantes haitianos Texas",
    "Angela Merkel renuncia",
    "Servicios de reubicación en São Paulo",
    "Deportación de migrantes desde la frontera con Bolivia",
    "Migrantes varados en la frontera de Colombia",
    "Hombre se prendió fuego frente al gobierno",
    "Inversión extranjera para prevenir la migración",
    "Bailarina de salsa se convirtió en manifestante radical",
    "Crisis de los conductores de camiones en el Reino Unido",
    "Compañía de danza de puntillas en el extranjero",
    "el festival de danza más grande del mundo",
    "São Paulo para los amantes de la danza",
    "Lugares para hacer un movimiento",
    "Tela se suma al movimiento para derogar Zonas de Desarrollo",
    "Bolsonaro rechaza restricciones a la circulación",
    "Apertura de ajedrez de nube Bong",
    "Carnaval brasileño",
    "Manifestantes del puerto de Dover",
    "Disfruta del transporte público gratuito",
    "Ámsterdam tiendas Albert Heijn pegadas",
    "Disturbios civiles en Sudáfrica",
    "Alumnos de escuela LGBT protestan",
    "Bloqueo en Gaza",
    "Equilibrista Torre Eiffel",
    "La Gala Met",
    "Bailarina pivotante",
    "Frontera entre Bielorrusia y Polonia",
    "Estados Unidos lanza eventos OVNI",
    "Carriles para bicicletas emergentes",
    "Accidente de avión en Milán",
    "Concentración climática antes de las elecciones",
    "Vuelo de evacuación de Afganistán bloqueado",
    "Marcha del aborto en América Latina",
    "Los peregrinos conmemoran Arbaeen",
    "Sin hogar después de la inundación",
    "Protesta contra las vacunas en Melbourne",
    "Activistas bloquean el puerto de Shell",
    "Protesta del paraguas amarillo",
    "Detenido durante redadas en Libia",
    "Hambruna creciente Líbano",
    "Uruguay permite marijiuana en cárceles",
    "Protesta contra las vacunas en Melbourne",
    "Baja asistencia a los centros de vacunación",
    "Científico de BioNTech gana premio",
    "Vacuna covid para niños",
    "Vacuna covid y cambios menstruales",
    "'trampa de proteínas' es un avance científico",
    "Síndrome de la Habana",
    "Estados Unidos dona dosis de vacunas a países de bajos ingresos",
    "Aumento de los microplásticos",
    "Quincena prohibida en la tienda de aplicaciones",
    "Bitcoin prohibido en China",
    "NFT de memes virales",
    "El movimiento 'anónimo' filtra datos",
    "Los juegos alientan a los adolescentes a leer",
    "Laboratorio de bioterrorismo en DC",
    "Explosión de Tuas",
    "Estampida de blockchain de Wyoming",
    "El juicio de Elizabeth Holmes, fundadora de Theranos",
    "Apple planes escaneando imágenes de usuario",
    "Fábricas de hormigas químicas",
    "TikTok se enfrenta a un reclamo masivo",
    "E. coli energías renovables",
    "Prohibición de juegos en China",
    "Software espía Pegasus",
    "El profesional de inteligencia artificial más joven de Egipto en Microsoft",
    "La edición de genes como cura para el VIH",
    "Revolución de la agricultura de edición del genoma",
    "Taiwán lanza simulacros de biodefensa",
    "Nuevo huevo líquido a base de plantas",
    "Origen genético tripartito",
    "Locura de las criptomonedas en Nigeria",
    "El Senado de Facebook escucha la seguridad de los adolescentes",
    "China reactor de torio",
    "Nave espacial Juno",
    "Tesla impreso en 3D",
    "Juego de pong de control mental",
    "Ingenio semiautomático",
    "Interfaz cerebro-computadora",
    "Satélite Landsat 9",
    "Prueba de redireccionamiento de doble asteroide",
    "Bacterias que destruyen las células cancerosas.",
    "Agricultura en Marte",
    "Sensores nerviosos de temperatura y tacto.",
    "Primeras imágenes de Mercurio",
    "Astrónomo brasileño de 8 años",
    "Aterrizaje de víbora",
    "Piel artificial de camaleón",
    "Bot de entrega",
    "Parque solar de un solo sitio",
    "Usando biomimetismo para agua limpia",
    "Centro de biomimetismo",
    "Polígono cibernético",
    "Camión Digi",
    "Tesla mega batería",
    "Órganos impresos en 3d",
    "La aplicación interrumpe la atención médica",
    "Película espacial rusa",
    "Prueba de misiles hipersónicos",
    "Pacto AUPUS",
    "compra de aviones militares de China",
    "soluciones blockchain para la defensa nacional",
    "Más dinero para defensa",
    "Los talibanes invaden Kabul",
    "Grupo extremista nacional",
    "Software espía Pegasus",
    "Retirada de Estados Unidos Afganistán",
    "Es propaganda",
    "El secreto de la CIA en Kabul",
    "Batallón de acción rápida",
    "Conspiración china de Fort Detrick",
    "Paquete fiscal global",
    "Elecciones federales alemanas",
    "Calificación de Angela Merkel",
    "Impuestos corporativos más altos",
    "Más dinero para los profesores",
    "Acción legal sobre tratos de máscaras",
    "Grupos judíos critican a Baudet por reclamo de la Segunda Guerra Mundial",
    "NL debe acelerar para cumplir con la política climática de la UE",
    "Niño de 12 años gana el derecho a ser vacunado",
    "Sí / Sí pegatina de correo basura",
    "Marcha por la crisis de la vivienda",
    "La transición energética no es cibersegura",
    "Llamada de partido maorí para cambiar el nombre de Nueva Zelanda",
    "La isla de Fraser pasó a llamarse K'gari",
    "Brasil Derechos a la tierra de los indígenas",
    "Muppets de Boris Johnson",
    "Secuestro de periodista",
    "Ley de aborto de Texas",
    "Disputa de la isla de Japón",
    "Golpe fallido en Sudán",
    "Los recursos naturales sin explotar del Afganistán",
    "Huelga climática global",
    "México despenaliza los abortos",
    "Texas critica los abortos",
    "Escocia cuatro días de semana laboral",
    "Recolección de cadáveres de animales",
    "Papeles de Pandora",
    "El convoy del ministro mata a los agricultores",
    "Drone paquistaní lanza armas",
    "Ejercicio del ejército de Irán Azerbejdzan",
    "Disparo de gas lacrimógeno",
    "La Palma cuenta el costo",
    "Robocop de la vida real",
    "Seguimiento de acero de China",
    "Venta ilegal selva amazónica",
    "Facebook vende selva amazónica",
    "Mega-sequía de California",
    "Ébola del Congo",
    "Centrales eléctricas libanesas",
    "Escasez de combustible líbano",
    "Inglaterra sin gasolina",
    "Drone ataca aeropuerto saudí",
    "Las tropas etíopes se intensifican",
    "Interrupción importante de Facebook",
    "Facebook abajo de nuevo",
    "Talibanes 2.0",
    "Planes de aborto en Texas",
    "cruzando Darien Gap",
    "selva migratoria de panamá",
    "La marisma salva vidas",
    "Derrame de petróleo de California",
    "Lijadora contaminada",
    "Minería de aguas profundas",
    "Pepino de mar polimetálico",
    "Pez plano del monte submarino Daikoku",
    "criatura marina con tentáculos",
    "pulpos Dumbo desplazados",
    "Greenpeace detiene el aceite de pescado",
    "Ratonero de patas largas rescatado",
    "Viaje traumático Panamá",
    "Golpe del huracán ida",
    "Beneficios del tiburón tigre",
    "Antiguo rascacielos de Yemen",
    "Contracción de las cataratas victoria",
    "Equipo de limpieza de derrames de petróleo",
    "Auge de la palta en Colombia",
    "Colombia oro verde",
    "Concurso de minería de los fondos marinos",
    "Trabajadores de la planta de carne",
    "Vida del cráter Jezero",
    "Salpicaduras de Space-X",
    "Vuelos virtuales arqueológicos",
    "Producción de píldoras Covid-19",
    "Actor ruso ISS",
    "Esquí arqueólogo noruega",
    "Terremoto de Pakistán herido",
    "Aceite de Huntington Beach",
    "Policía de Bodycam Ohio",
    "Videos filtrados del Pentágono",
    "Tensiones China Taiwán",
    "Crisis global de chips de Intel",
    "Comienza la temporada de cigarras",
    "Depresión tropical henri",
    "Prepara nicolas tropical",
    "Base aérea de los talibanes en bagram",
    "Colapsar surfside florida",
    "Branson en el espacio",
    "Invasión de moscas Jamaica",
    "Piscina de lava negra",
    "Piscina para tragar lava",
    "La OMS recomienda la vacuna contra la malaria",
    "Muestras de la luna Chang'e 5",
    "Reunificación China",
    "Violencia India protestas de agricultores",
    "Crisis de energía de Delhi",
    "Toma de posesión saudita de Newcastle",
    "Terremoto de Pakistán",
    "El dinosaurio terópodo más antiguo",
    "Aseo de 2700 años Jerusalén",
    "Avispón asiático invasivo",
    "Festival rave de la gigafábrica de Tesla",
    "Gigarave",
    "China bailando abuelas",
    "Arp 91 Hubble",
    "Kenia prohíbe la danza local",
    "Danza inmoral de Kwara Kwara",
    "Naufragios de Gallipoli",
    "Radiación Marte",
    "Éruption du volcan La Palma",
    "La Palma s'intensifie",
    "Conférence sur le climat COP26",
    "jab de rappel de covid",
    "Vaccin enfant",
    "Feux de forêt mondiaux",
    "Les empreintes les plus anciennes d'Amérique",
    "Ankylosaure africain",
    "Le volcan Fuego entre en éruption",
    "Insectes comestibles",
    "Fossile de Titanokorys gainesi",
    "Grave pénurie d'eau",
    "Des oiseaux migrateurs s'écrasent sur les gratte-ciel de New York",
    "Le plus grand feu de forêt d'arbres",
    "Avertissement de crue éclair",
    "Bébé panda de Singapour",
    "tempête au Vietnam",
    "tremblement de terre australien",
    "Troupeau de chevaux en fuite",
    "Gouffre d'Australie du Sud",
    "Les flux de débris bloquent les routes",
    "Des inondations frappent l'Allemagne et la Belgique",
    "Pénurie de bois du Brexit",
    "La communauté de Larose coincée dans la boue",
    "Le lac est devenu rouge Jordan",
    "La tempête tropicale Sam",
    "Réchauffement rapide",
    "Température record",
    "Ouragan Ida",
    "Nouvelle variante covid",
    "Vortex polaire stratosphérique",
    "Abattage de dauphins Îles Féroé",
    "Trou d'ozone au-dessus de l'Antarctique",
    "Traitement covid à l'ivermectine",
    "Sécheresse en Irak et en Syrie",
    "De nouvelles fissures éclatent",
    "Revue mondiale des déchets",
    "Souris d'édition de gènes",
    "Réseau de communication quantique",
    "Crocodilia",
    "Archéologues symboles humains",
    "Le déclin mondial des abeilles",
    "Perte de glace mondiale",
    "TOI-178 exoplanètes",
    "Bois transparent fabriqué",
    "Spoutnik-V",
    "Phosphine Vénus",
    "Méthane Mars",
    "Le bassin amazonien émet",
    "Forer Mars",
    "Rover perce Mars",
    "Groenland sans glace",
    "Émissions minières de Bitcoin",
    "Morning glory pop-up printemps",
    "parc archaea yellowstone",
    "Borgs",
    "lumière derrière le trou noir",
    "Viande bio-imprimée",
    "Qeqertaq Avannarleq",
    "Nouvelle île du Groenland",
    "Masse continentale la plus au nord",
    "échantillonner la roche de Mars",
    "empreintes humaines Nouveau-Mexique",
    "Exploitation minière illégale",
    "Déversement de pétrole en Californie",
    "Pénurie de charbon en Chine",
    "Cyclone Shaheen",
    "Alerte aux inondations en Thaïlande",
    "Désert alimentaire en pleine croissance",
    "Rare léopard d'Arabie",
    "Pluie de météores orionides",
    "Tourisme spatial",
    "Les touristes de l'espace SpaceX",
    "Camp de migrants haïtiens Texas",
    "Angela Merkel se retire",
    "Services de déménagement à São Paulo",
    "Expulsion de migrants de la frontière bolivienne",
    "Des migrants bloqués à la frontière colombienne",
    "Un homme s'est immolé par le feu devant le gouvernement",
    "L'investissement étranger pour empêcher la migration",
    "Une danseuse de salsa devenue manifestante radicale",
    "Crise des camionneurs au Royaume-Uni",
    "Compagnie de danse sur la pointe des pieds à l'étranger",
    "le plus grand festival de danse du monde",
    "São Paulo pour les amateurs de danse",
    "Endroits pour faire un pas en avant",
    "Tela rejoint le mouvement pour abroger les zones de développement",
    "Bolsonaro rejette les restrictions de mouvement",
    "Ouverture d'échecs de nuage de bang",
    "Carnaval brésilien",
    "Les manifestants du port de Douvres",
    "Profitez des transports en commun gratuits",
    "Les magasins Albert Heijn d'Amsterdam collés",
    "Troubles civils en Afrique du Sud",
    "Des écoliers LGBT manifestent",
    "Blocus à Gaza",
    "Funambule Tour Eiffel",
    "Le gala du Met",
    "Danseuse pivotante",
    "Biélorussie Pologne frontière",
    "Les États-Unis publient des événements sur les ovnis",
    "Pistes cyclables pop-up",
    "Accident d'avion à Milan",
    "Rassemblement pour le climat avant les élections",
    "Vol d'évacuation en Afghanistan bloqué",
    "Marche de l'avortement Amérique latine",
    "Les pèlerins commémorent Arbaeen",
    "Sans-abri après l'inondation",
    "Manifestation anti-vaccin à Melbourne",
    "Des militants bloquent le port de Shell",
    "Manifestation parapluie jaune",
    "Détenu lors de raids en Libye",
    "Famine croissante au Liban",
    "L'Uruguay autorise la marijuana dans les prisons",
    "Manifestation anti-vaccin à Melbourne",
    "Faible fréquentation des centres de vaccination",
    "Un scientifique de BioNTech remporte un prix",
    "Vaccin contre le covid des enfants",
    "Vaccin contre le Covid et changements menstruels",
    "Le 'piège à protéines' est une percée scientifique",
    "syndrome de La Havane",
    "Les États-Unis font don de doses de vaccins aux pays à faible revenu",
    "L'essor des microplastiques",
    "Quinzaine bannie de l'App Store",
    "Bitcoin interdit en Chine",
    "NFT de mème viral",
    "Le mouvement « anonyme » fait fuiter des données",
    "Le jeu encourage les adolescents à lire",
    "Laboratoire de bioterrorisme à DC",
    "Explosion de Tuas",
    "La bousculade de la blockchain du Wyoming",
    "Procès d'Elizabeth Holmes, fondatrice de Theranos",
    "Apple envisage de numériser les images des utilisateurs",
    "Usines de fourmis chimiques",
    "TikTok fait face à une revendication de masse",
    "E.coli énergie renouvelable",
    "Interdiction des jeux en Chine",
    "Logiciel espion Pégase",
    "Le plus jeune professionnel égyptien de l'IA chez Microsoft",
    "L'édition de gènes comme remède contre le VIH",
    "Révolution agricole de l'édition du génome",
    "Taïwan lance des exercices de biodéfense",
    "Nouvel œuf liquide végétal",
    "Origine génétique tripartite",
    "L'engouement pour la crypto-monnaie au Nigeria",
    "Le Sénat de Facebook entend parler de la sécurité des adolescents",
    "Chine réacteur au thorium",
    "vaisseau spatial Juno",
    "Tesla imprimée en 3D",
    "Jeu de pong de contrôle mental",
    "L'ingéniosité semi-automatique",
    "Interface cerveau-ordinateur",
    "Satellite Landsat 9",
    "Test de redirection double astéroïde",
    "Des bactéries qui détruisent les cellules cancéreuses",
    "L'agriculture sur Mars",
    "Capteurs nerveux pour la température et le toucher",
    "Premières images de Mercure",
    "astronome brésilien de 8 ans",
    "Atterrissage de vipère",
    "Peau artificielle caméléon",
    "Robot de livraison",
    "Parc solaire mono-site",
    "Utiliser le biomimétisme pour de l'eau propre",
    "Centre de biomimétisme",
    "Cyberpolygone",
    "Camion Digi",
    "méga batterie Tesla",
    "organes imprimés en 3D",
    "L'application perturbe les soins de santé",
    "Film spatial russe",
    "Essais de missiles hypersoniques",
    "Pacte AUPUS",
    "achat d'avions militaires en provenance de Chine",
    "solutions blockchain pour la défense nationale",
    "Plus d'argent pour la défense",
    "Les talibans envahissent Kaboul",
    "Groupe extrémiste national",
    "Logiciel espion Pégase",
    "retrait américain d'Afghanistan",
    "La propagande de l'EI",
    "Kaboul secrète de la CIA",
    "Bataillon d'action rapide",
    "Complot chinois de Fort Detrick",
    "Pack fiscal mondial",
    "Élection fédérale allemande",
    "Évaluation d'Angela Merkel",
    "Des impôts sur les sociétés plus élevés",
    "Plus d'argent pour les enseignants",
    "Action en justice sur les offres de masques",
    "Des groupes juifs critiquent Baudet pour sa revendication sur la Seconde Guerre mondiale",
    "Les Pays-Bas doivent accélérer pour répondre à la politique climatique de l'UE",
    "Un garçon de 12 ans gagne le droit d'être vacciné",
    "Autocollant de courrier indésirable Oui/Oui",
    "Marche de crise du logement",
    "La transition énergétique n'est pas cyber sécurisée",
    "Les Maoris appellent à renommer la Nouvelle-Zélande",
    "L'île Fraser rebaptisée K'gari",
    "Brésil Droits fonciers autochtones",
    "Les marionnettes de Boris Johnson",
    "Enlèvement d'un journaliste",
    "La loi du Texas sur l'avortement",
    "Le différend insulaire au Japon",
    "Coup d'État raté au Soudan",
    "Les ressources naturelles inexploitées de l'Afghanistan",
    "Grève mondiale pour le climat",
    "Le Mexique dépénalise les avortements",
    "Le Texas criminalise les avortements",
    "semaine de travail de quatre jours en Écosse",
    "Ramasser les carcasses d'animaux",
    "papiers de Pandore",
    "Le convoi du ministre tue des agriculteurs",
    "Un drone pakistanais lâche des armes",
    "Exercice de l'armée iranienne en Azerbaïdjan",
    "Gaz lacrymogène",
    "La Palma compte le coût",
    "Robocop réel",
    "Suivi de l'acier de Chine",
    "Vente illégale forêt amazonienne",
    "Facebook vend la forêt amazonienne",
    "Méga-sécheresse en Californie",
    "Congo Ebola",
    "centrales électriques libanaises",
    "Pénurie de carburant au Liban",
    "Pas d'essence en Angleterre",
    "Un drone attaque l'aéroport saoudien",
    "Les troupes éthiopiennes intensifient",
    "Panne majeure de Facebook",
    "Facebook à nouveau en panne",
    "Taliban 2.0",
    "Plans d'avortement au Texas",
    "traversée de Darien Gap",
    "jungle migratoire du Panama",
    "Les marais salants sauvent des vies",
    "Déversement de pétrole en Californie",
    "Ponceuse contaminée",
    "Exploitation minière en haute mer",
    "Concombre de mer polymétallique",
    "Poisson plat du mont sous-marin Daikoku",
    "créature marine tentaculaire",
    "poulpes Dumbo déplacés",
    "Greenpeace arrête l'huile de poisson",
    "Buse à longues pattes sauvée",
    "Voyage traumatisant Panama",
    "L'ouragan ida a frappé",
    "Avantages du requin tigre",
    "Gratte-ciel antique du Yémen",
    "Victoria tombe rétrécir",
    "Équipe de nettoyage des déversements d'hydrocarbures",
    "Boom de l'avocat en Colombie",
    "Colombie or vert",
    "Concours d'exploitation minière des fonds marins",
    "Ouvriers d'usine de viande",
    "La vie du cratère Jezero",
    "Space-X éclabousse",
    "Vols virtuels archéologiques",
    "Production de pilules Covid-19",
    "Acteur russe ISS",
    "Archéologue norvège ski",
    "Blessé au Pakistan tremblement de terre",
    "Huile de plage Huntington",
    "Bodycam police de l'Ohio",
    "Le Pentagone a divulgué des vidéos",
    "Tensions Chine Taïwan",
    "Crise mondiale des puces Intel",
    "La saison des cigales commence",
    "Dépression tropicale henri",
    "Préparer des nicolas tropicaux",
    "Base aérienne des talibans de Bagram",
    "Effondrement surfside floride",
    "Branson dans l'espace",
    "Invasion de mouches en Jamaïque",
    "Piscine de lave noire",
    "Bassin d'avalement de lave",
    "L'OMS recommande un vaccin contre le paludisme",
    "Chang'e 5 échantillons de lune",
    "Réunification Chine",
    "Violence Inde Manifestations d'agriculteurs",
    "Crise de l'électricité à Delhi",
    "Rachat saoudien de Newcastle",
    "tremblement de terre pakistanais",
    "Le plus vieux dinosaure théropode",
    "Toilettes vieilles de 2 700 ans Jérusalem",
    "Frelon asiatique envahissant",
    "Festival rave de la gigafactory Tesla",
    "Gigarave",
    "chine danse mamies",
    "Arp 91 Hubble",
    "Le Kenya interdit la danse locale",
    "Kwara Kwara Danse immorale",
    "naufrages de Gallipoli",
    "Rayonnement Mars",
    "ला पाल्मा ज्वालामुखी विस्फोट",
    "ला पाल्मा तेज करता है",
    "COP26 जलवायु सम्मेलन",
    "कोविड बूस्टर जब",
    "बाल टीका",
    "वैश्विक जंगल की आग",
    "अमेरिका में सबसे पुराने पैरों के निशान",
    "अफ्रीकी एंकिलोसॉर",
    "फुएगो ज्वालामुखी फूटता है",
    "खाने योग्य कीड़े",
    "टाइटेनोकोरिस गेनेसी फॉसिल",
    "पानी की भारी कमी",
    "प्रवासी पक्षी NYC गगनचुंबी इमारतों में दुर्घटनाग्रस्त हो जाते हैं",
    "सबसे बड़ा पेड़ जंगल की आग",
    "अचानक बाढ़ की चेतावनी",
    "सिंगापुर बेबी पांडा",
    "वियतनाम तूफान",
    "ऑस्ट्रेलियाई भूकंप",
    "भागे हुए घोड़ों का झुंड",
    "दक्षिण ऑस्ट्रेलियाई सिंकहोल",
    "मलबे का प्रवाह सड़कों को अवरुद्ध करता है",
    "जर्मनी और बेल्जियम में बाढ़",
    "ब्रेक्सिट लकड़ी की कमी",
    "कीचड़ में फंसा लारोज समुदाय",
    "झील लाल हो गई जॉर्डन",
    "उष्णकटिबंधीय तूफान सामी",
    "रैपिड वार्मिंग",
    "रिकॉर्ड-उच्च तापमान",
    "तूफान Ida",
    "नया कोविड संस्करण",
    "समताप मंडल ध्रुवीय भंवर",
    "डॉल्फिन वध फरो आइलैंड्स",
    "अंटार्कटिका के ऊपर ओजोन छिद्र",
    "आइवरमेक्टिन कोविड उपचार",
    "इराक और सीरिया में सूखा",
    "नई दरारें फूटती हैं",
    "वैश्विक अपशिष्ट समीक्षा",
    "जीन संपादन चूहे",
    "क्वांटम संचार नेटवर्क",
    "क्रोकोडिलिया",
    "पुरातत्वविद मानव प्रतीक",
    "वैश्विक मधुमक्खी गिरावट",
    "वैश्विक बर्फ नुकसान",
    "TOI-178 एक्सोप्लैनेट",
    "पारदर्शी लकड़ी निर्मित",
    "स्पुतनिक-वी",
    "फॉस्फीन वीनस",
    "मीथेन मंगल",
    "अमेज़ॅन बेसिन उत्सर्जित करता है",
    "ड्रिलिंग मंगल",
    "रोवर ने मंगल का अभ्यास किया",
    "ग्रीनलैंड बर्फ मुक्त",
    "बिटकॉइन खनन उत्सर्जन",
    "मॉर्निंग ग्लोरी पॉप-अप स्प्रिंग",
    "आर्किया येलोस्टोन पार्क",
    "बोर्गो",
    "ब्लैक होल के पीछे का प्रकाश",
    "बायोप्रिंटिंग मांस",
    "क्यूकरताक अवन्नारलेक़",
    "ग्रीनलैंड न्यू आइलैंड",
    "सबसे उत्तरी भूभाग",
    "नमूना मार्स रॉक",
    "मानव पैरों के निशान न्यू मैक्सिको",
    "अवैध खनन कार्य",
    "कैलिफोर्निया तेल रिसाव",
    "चीन कोयले की कमी",
    "चक्रवात शाहीन",
    "थाईलैंड बाढ़ चेतावनी",
    "बढ़ता हुआ भोजन रेगिस्तान",
    "दुर्लभ अरब तेंदुआ",
    "ओरियनिड उल्का बौछार",
    "अंतरिक्ष पर्यटन",
    "स्पेसएक्स अंतरिक्ष पर्यटक",
    "हाईटियन प्रवासी शिविर टेक्सास",
    "एंजेला मर्केल का इस्तीफा",
    "साओ पाउलो में पुनर्वास सेवाएं",
    "बोलीविया सीमा से प्रवासी निर्वासन",
    "प्रवासी फंसे कोलंबिया सीमा",
    "सरकार के सामने आदमी ने खुद को आग लगाई",
    "पलायन रोकने के लिए विदेशी निवेश",
    "साल्सा नर्तकी बन गई कट्टरपंथी रक्षक",
    "यूके लॉरी चालक संकट",
    "डांस कंपनी विदेश में टिपटोइंग",
    "दुनिया का सबसे बड़ा नृत्य महोत्सव",
    "नृत्य प्रेमियों के लिए साओ पाउलो",
    "एक कदम बस्ट करने के लिए स्थान",
    "विकास क्षेत्रों को निरस्त करने के आंदोलन में शामिल हुआ तेला",
    "बोल्सोनारो ने आवाजाही पर प्रतिबंध को खारिज किया",
    "बोंग क्लाउड शतरंज का उद्घाटन",
    "ब्राज़ीलियाई कार्निवल",
    "डोवर बंदरगाह प्रदर्शनकारी",
    "मुफ्त सार्वजनिक परिवहन का आनंद लें",
    "एम्सटर्डम अल्बर्ट हाइजन स्टोर चिपके हुए बंद",
    "दक्षिण अफ्रीका नागरिक अशांति",
    "LGBT स्कूल के छात्रों का विरोध प्रदर्शन",
    "गाज़ा में नाकाबंदी",
    "टाइट्रोप वॉकर एफिल टॉवर",
    "मेट गला",
    "पिवोटिंग डांसर",
    "बेलारूस पोलैंड सीमा",
    "यूएसए ने जारी किया यूएफओ इवेंट",
    "पॉप-अप बाइक लेन",
    "मिलान विमान दुर्घटना",
    "चुनाव से पहले जलवायु रैली",
    "अवरुद्ध अफगानिस्तान निकासी उड़ान",
    "गर्भपात मार्च लैटिन अमेरिका",
    "तीर्थयात्री अरबाईन को मनाते हैं",
    "बाढ़ के बाद बेघर",
    "एंटी वैक्सीन विरोध मेलबर्न",
    "कार्यकर्ताओं ने शेल के पोर्ट को ब्लॉक कर दिया",
    "पीला छाता विरोध",
    "लीबिया में छापेमारी के दौरान हिरासत में लिया गया",
    "बढ़ती भुखमरी लेबनान",
    "उरुग्वे ने मारिजुआना को जेलों में बंद करने की अनुमति दी",
    "टीका विरोधी विरोध मेलबर्न",
    "टीकाकरण केंद्रों पर कम उपस्थिति",
    "बायोएनटेक वैज्ञानिक ने जीता पुरस्कार",
    "बच्चों की कोविड वैक्सीन",
    "कोविड वैक्सीन और मासिक धर्म परिवर्तन",
    "'प्रोटीन ट्रैप' है वैज्ञानिक सफलता",
    "हवाना सिंड्रोम",
    "अमेरिका ने कम आय वाले देशों को वैक्सीन की खुराक दान की",
    "माइक्रोप्लास्टिक का उदय",
    "ऐप स्टोर से पखवाड़े पर प्रतिबंध",
    "चीन में बिटकॉइन पर प्रतिबंध",
    "वायरल मेम एनएफटी",
    "'बेनामी' आंदोलन ने डेटा लीक किया",
    "गेमिंग किशोरों को पढ़ने के लिए प्रोत्साहित करता है",
    "डीसी में जैव आतंकवाद प्रयोगशाला",
    "तुआस विस्फोट",
    "व्योमिंग ब्लॉकचेन भगदड़",
    "थेरानोस के संस्थापक एलिजाबेथ होम्स का परीक्षण",
    "Apple उपयोगकर्ता छवियों को स्कैन करने की योजना बना रहा है",
    "रासायनिक चींटी कारखाने",
    "टिकटोक को बड़े पैमाने पर दावे का सामना करना पड़ रहा है",
    "ई.कोलाई अक्षय ऊर्जा",
    "चीन गेमिंग प्रतिबंध",
    "पेगासस स्पाइवेयर",
    "माइक्रोसॉफ्ट में मिस्र का सबसे युवा एआई पेशेवर",
    "एचआईवी इलाज के रूप में जीन संपादन",
    "जीनोम एडिटिंग फार्मिंग क्रांति",
    "ताइवान ने जैव रक्षा अभ्यास शुरू किया",
    "नया पौधा-आधारित तरल अंडा",
    "त्रिपक्षीय आनुवंशिक उत्पत्ति",
    "नाइजीरिया क्रिप्टोक्यूरेंसी सनक",
    "किशोर सुरक्षा सुनवाई फेसबुक सीनेट",
    "चीन थोरियम रिएक्टर",
    "जूनो अंतरिक्ष यान",
    "3डी प्रिंटेड टेस्ला",
    "माइंड-कंट्रोल पोंग गेम",
    "सेमी-ऑटो सरलता",
    "मस्तिष्क-कंप्यूटर इंटरफ़ेस",
    "लैंडसैट 9 उपग्रह",
    "डबल क्षुद्रग्रह पुनर्निर्देशन परीक्षण",
    "कैंसर कोशिकाओं को नष्ट करने वाले बैक्टीरिया",
    "मंगल ग्रह पर खेती",
    "तापमान और स्पर्श के लिए तंत्रिका सेंसर",
    "बुध की पहली तस्वीरें",
    "8 वर्षीय ब्राजीलियाई खगोलशास्त्री",
    "वाइपर लैंडिंग",
    "गिरगिट कृत्रिम त्वचा",
    "डिलीवरी बॉट",
    "सिंगल साइट सोलर पार्क",
    "स्वच्छ जल के लिए बायोमिमिक्री का प्रयोग",
    "बायोमिमिक्री सेंटर",
    "साइबर बहुभुज",
    "डिजी ट्रक",
    "टेस्ला मेगा बैटरी",
    "3डी प्रिंटेड अंग",
    "ऐप स्वास्थ्य सेवा को बाधित करता है",
    "रूसी अंतरिक्ष फिल्म",
    "हाइपरसोनिक मिसाइल परीक्षण",
    "औपस संधि",
    "चीन से सैन्य विमान की खरीद",
    "राष्ट्रीय रक्षा के लिए ब्लॉकचेन समाधान",
    "रक्षा के लिए अधिक पैसा",
    "तालिबान ने काबुल पर आक्रमण किया",
    "घरेलू चरमपंथी समूह",
    "पेगासस स्पाइवेयर",
    "अमेरिका की वापसी अफगानिस्तान",
    "प्रचार है",
    "सीआईए गुप्त काबुली",
    "रैपिड एक्शन बटालियन",
    "फोर्ट डेट्रिक चीनी साजिश",
    "वैश्विक कर पैक",
    "जर्मन संघीय चुनाव",
    "एंजेला मर्केल रेटिंग",
    "उच्च कॉर्पोरेट कर",
    "शिक्षकों के लिए अधिक पैसा",
    "मुखौटा सौदों पर कानूनी कार्रवाई",
    "द्वितीय विश्व युद्ध के दावे के लिए यहूदी समूहों ने बॉडेट की खिंचाई की",
    "यूरोपीय संघ की जलवायु नीति को पूरा करने के लिए NL को गति देनी चाहिए",
    "12 साल का लड़का टीकाकरण का अधिकार जीतता है",
    "हां/हां जंक मेल स्टिकर",
    "आवास संकट मार्च",
    "ऊर्जा संक्रमण साइबर सुरक्षित नहीं",
    "माओरी पार्टी ने न्यूजीलैंड का नाम बदलने का आह्वान किया",
    "फ्रेजर द्वीप का नाम बदलकर K'gari कर दिया गया",
    "ब्राजील स्वदेशी भूमि अधिकार",
    "बोरिस जॉनसन मपेट्स",
    "पत्रकार का अपहरण",
    "टेक्सास का गर्भपात कानून",
    "जापान का द्वीप विवाद",
    "सूडान असफल तख्तापलट",
    "अफगानिस्तान के अप्रयुक्त प्राकृतिक संसाधन",
    "वैश्विक जलवायु हड़ताल",
    "मेक्सिको गर्भपात को अपराध की श्रेणी से बाहर करता है",
    "टेक्सास गर्भपात को अपराध करता है",
    "स्कॉटलैंड चार दिवसीय कार्य सप्ताह",
    "जानवरों के शवों की सफाई",
    "भानुमती के कागजात",
    "मंत्री के काफिले ने की किसानों की हत्या",
    "पाकिस्तानी ड्रोन ने गिराए हथियार",
    "ईरान सेना की ड्रिल अज़रबेजदज़ान",
    "आंसू गैस के गोले छोड़े",
    "ला पाल्मा लागत की गणना करता है",
    "वास्तविक जीवन रोबोकॉप",
    "ट्रैकिंग चीन स्टील",
    "अवैध बिक्री अमेज़न वर्षावन",
    "फेसबुक अमेज़न वर्षावन बेचता है",
    "कैलिफ़ोर्निया मेगा-सूखा",
    "कांगो इबोला",
    "लेबनान के बिजली संयंत्र",
    "ईंधन की कमी लेबनान",
    "नो पेट्रोल इंग्लैंड",
    "सऊदी हवाईअड्डे पर ड्रोन से हमला",
    "इथियोपिया की सेना तेज",
    "फेसबुक प्रमुख आउटेज",
    "फेसबुक फिर से डाउन",
    "तालिबान 2.0",
    "टेक्सास गर्भपात योजना",
    "डेरियन गैप को पार करना",
    "पनामा प्रवास जंगल",
    "नमक दलदल जीवन बचाता है",
    "कैलिफोर्निया तेल रिसाव",
    "दूषित सैंडरलिंग",
    "गहरे समुद्र में खनन",
    "पॉलीमेटेलिक समुद्री ककड़ी",
    "डाइकोकू सीमाउंट फ्लैटफिश",
    "तना हुआ समुद्री जीव",
    "विस्थापित डंबो ऑक्टोपस",
    "ग्रीनपीस ने बंद किया मछली का तेल",
    "लंबी टांगों वाला गुलदार बचाया गया",
    "दर्दनाक यात्रा पनामा",
    "तूफान आईडीए हिट",
    "टाइगर शार्क लाभ",
    "यमन प्राचीन गगनचुंबी इमारत",
    "विक्टोरिया फॉल्स सिकुड़ता है",
    "ऑयल स्पिल क्लीन टीम",
    "कोलंबिया एवोकैडो बूम",
    "कोलंबिया हरा सोना",
    "समुद्र तल खनन प्रतियोगिता",
    "मांस संयंत्र श्रमिक",
    "जेज़ेरो क्रेटर लाइफ",
    "स्पेस-एक्स स्प्लैश डाउन",
    "पुरातत्व आभासी उड़ानें",
    "कोविड -19 गोली उत्पादन",
    "रूस अभिनेता ISS",
    "पुरातत्वविद् नॉर्वे स्की",
    "घायल पाकिस्तान भूकंप",
    "हटिंगटन बीच तेल",
    "बॉडीकैम ओहियो पुलिस",
    "पेंटागन लीक वीडियो",
    "तनाव चीन ताइवान",
    "इंटेल ग्लोबल चिप संकट",
    "सिकाडा सीजन शुरू",
    "उष्णकटिबंधीय अवसाद हेनरी",
    "उष्णकटिबंधीय निकोलस तैयार करें",
    "तालिबान बगराम हवाई अड्डा",
    "सर्फ़साइड फ़्लोरिडा को संक्षिप्त करें",
    "अंतरिक्ष में ब्रैनसन",
    "फ्लाई आक्रमण जमैका",
    "काला लावा स्विमिंग पूल",
    "लावा निगलने वाला पूल",
    "डब्ल्यूएचओ ने मलेरिया के टीके की सिफारिश की",
    "चांग'ई 5 चंद्रमा के नमूने",
    "पुनर्मिलन चीन",
    "हिंसा भारत किसान विरोध",
    "दिल्ली बिजली संकट",
    "सऊदी अधिग्रहण न्यूकैसल",
    "पाकिस्तानी भूकंप",
    "सबसे पुराना थेरोपोड डायनासोर",
    "२७०० साल पुराना शौचालय जेरूसलम",
    "आक्रामक एशियाई हॉर्नेट",
    "टेस्ला गिगाफैक्ट्री रेव फेस्टिवल",
    "गिगारावे",
    "चीन नृत्य दादी",
    "एआरपी 91 हबल",
    "केन्या ने स्थानीय नृत्य पर प्रतिबंध लगाया",
    "कवारा कवारा अनैतिक नृत्य",
    "गैलीपोली जलपोत",
    "विकिरण मंगल",
    "Letusan gunung berapi La Palma",
    "La Palma mengintensifkan",
    "Konferensi iklim COP26",
    "jab penambah covid",
    "Vaksin anak",
    "Kebakaran hutan global",
    "Jejak kaki tertua di Amerika",
    "ankilosaurus Afrika",
    "Gunung berapi Fuego meletus",
    "Serangga yang bisa dimakan",
    "Fosil gainesi Titanokory",
    "Kekurangan air yang parah",
    "Burung yang bermigrasi menabrak gedung pencakar langit NYC",
    "Kebakaran hutan pohon terbesar",
    "Peringatan banjir",
    "bayi panda singapura",
    "badai vietnam",
    "gempa bumi australia",
    "Kawanan kuda yang melarikan diri",
    "lubang pembuangan Australia Selatan",
    "Aliran puing menghalangi jalan",
    "Banjir melanda Jerman dan Belgia",
    "Kekurangan kayu Brexit",
    "Komunitas Larose terjebak dalam lumpur",
    "Danau menjadi merah Jordan",
    "Badai Tropis Sam",
    "Pemanasan Cepat",
    "Rekor suhu tinggi",
    "Badai Ida",
    "Varian covid baru",
    "Pusaran kutub stratosfer",
    "Lumba-lumba membantai Kepulauan Faroe",
    "Lubang ozon di atas Antartika",
    "pengobatan covid ivermectin",
    "Kekeringan di Irak dan Suriah",
    "Celah baru meletus",
    "Tinjauan limbah global",
    "Tikus pengedit gen",
    "Jaringan komunikasi kuantum",
    "buaya",
    "Simbol manusia arkeolog",
    "Penurunan lebah global",
    "Hilangnya es global",
    "eksoplanet TOI-178",
    "Kayu transparan diproduksi",
    "Sputnik-V",
    "Venus fosfin",
    "Metana Mars",
    "Cekungan Amazon memancarkan",
    "Pengeboran Mars",
    "Rover melatih Mars",
    "Bebas es Greenland",
    "Emisi penambangan Bitcoin",
    "Musim semi pop-up morning glory",
    "taman batu kuning archaea",
    "Borgs",
    "cahaya di balik lubang hitam",
    "Daging bioprinting",
    "Qeqertaq Avannarleq",
    "Pulau baru Greenland",
    "Daratan paling utara",
    "pengambilan sampel batuan Mars",
    "jejak kaki manusia New Mexico",
    "Operasi penambangan ilegal",
    "tumpahan minyak California",
    "kekurangan batubara Cina",
    "Topan Shaheen",
    "peringatan banjir thailand",
    "Tumbuhan gurun makanan",
    "Macan tutul arab langka",
    "Hujan meteor Orionid",
    "Wisata luar angkasa",
    "Turis luar angkasa SpaceX",
    "Kamp migran Haiti Texas",
    "Angela Merkel mengundurkan diri",
    "Layanan Relokasi di São Paulo",
    "Deportasi migran dari perbatasan Bolivia",
    "Migran terdampar di perbatasan Kolombia",
    "Seorang pria membakar dirinya sendiri di depan pemerintah",
    "Investasi asing untuk mencegah migrasi",
    "Penari salsa menjadi pemrotes radikal",
    "Krisis pengemudi truk Inggris",
    "Perusahaan Tari Berjingkat-Jingkat di Luar Negeri",
    "Festival Tari Terhebat di Dunia",
    "São Paulo untuk Pecinta Tari",
    "Tempat untuk Menghancurkan Pindah",
    "Tela bergabung dengan gerakan untuk mencabut Zona Pengembangan",
    "Bolsonaro menolak pembatasan pergerakan",
    "Pembukaan catur awan Bong",
    "Karnaval Brasil",
    "Pemrotes pelabuhan Dover",
    "Nikmati transportasi umum gratis",
    "Toko Amsterdam Albert Heijn tertutup rapat",
    "Kerusuhan sipil Afrika Selatan",
    "Siswa sekolah LGBT protes",
    "Blokade di Gaza",
    "Menara Eiffel pejalan kaki di atas tali",
    "Gala Met",
    "penari berputar",
    "Perbatasan Belarusia Polandia",
    "AS merilis acara UFO",
    "Jalur sepeda pop-up",
    "Kecelakaan pesawat Milan",
    "Reli iklim sebelum pemilihan",
    "Penerbangan evakuasi Afghanistan yang diblokir",
    "Pawai aborsi Amerika Latin",
    "Peziarah memperingati Arbain",
    "Gelandangan setelah banjir",
    "Protes anti vaksin Melbourne",
    "Aktivis memblokir port Shell",
    "protes payung kuning",
    "Ditahan selama penggerebekan di Libya",
    "Meningkatnya kelaparan Lebanon",
    "Uruguay mengizinkan ganja ke dalam penjara",
    "Protes anti-vaksin Melbourne",
    "Rendahnya kehadiran di pusat vaksinasi",
    "Ilmuwan BioNTech memenangkan hadiah",
    "vaksin covid anak",
    "Vaksin Covid dan perubahan menstruasi",
    "'perangkap protein' adalah terobosan ilmiah",
    "Sindrom Havana",
    "AS menyumbangkan dosis vaksin ke negara-negara berpenghasilan rendah",
    "Munculnya mikroplastik",
    "Dua minggu dilarang dari App store",
    "Bitcoin dilarang di Cina",
    "NFT meme viral",
    "Gerakan 'Anonim' membocorkan data",
    "Game mendorong remaja untuk membaca",
    "Laboratorium bioterorisme di DC",
    "Ledakan tuas",
    "Penyerbuan blockchain Wyoming",
    "Pengadilan pendiri Theranos, Elizabeth Holmes",
    "Apple berencana memindai gambar pengguna",
    "Pabrik semut kimia",
    "TikTok menghadapi klaim massal",
    "E.coli energi terbarukan",
    "larangan bermain game di Cina",
    "Spyware Pegasus",
    "Profesional AI termuda Mesir di Microsoft",
    "Pengeditan gen sebagai obat HIV",
    "Revolusi pertanian pengeditan genom",
    "Taiwan meluncurkan latihan pertahanan hayati",
    "Telur cair nabati baru",
    "Asal genetik tripartit",
    "Kegilaan cryptocurrency Nigeria",
    "Senat Facebook mendengarkan keselamatan remaja",
    "Reaktor Thorium China",
    "Pesawat ruang angkasa Juno",
    "Tesla cetak 3D",
    "Permainan pong yang mengendalikan pikiran",
    "Kecerdasan semi-otomatis",
    "Antarmuka otak-komputer",
    "Satelit Landsat 9",
    "Tes Pengalihan Asteroid Ganda",
    "Bakteri yang menghancurkan sel kanker",
    "Bertani di Mars",
    "Sensor saraf untuk suhu dan sentuhan",
    "Gambar pertama Merkurius",
    "Astronom Brasil berusia 8 tahun",
    "Pendaratan ular berbisa",
    "Kulit buatan bunglon",
    "bot pengiriman",
    "Taman surya satu situs",
    "Menggunakan biomimikri untuk air bersih",
    "Pusat Biomimikri",
    "Poligon dunia maya",
    "Truk Digi",
    "Baterai Tesla mega",
    "organ cetak 3d",
    "Aplikasi mengganggu perawatan kesehatan",
    "Film luar angkasa Rusia",
    "Uji coba rudal hipersonik",
    "Pakta AUPUS",
    "pembelian pesawat militer dari China",
    "solusi blockchain untuk pertahanan nasional",
    "Lebih banyak uang untuk pertahanan",
    "Taliban menyerang Kabul",
    "Kelompok ekstremis domestik",
    "Spyware Pegasus",
    "Penarikan AS Afghanistan",
    "propaganda IS",
    "Rahasia CIA Kabul",
    "batalyon aksi cepat",
    "Fort Detrick konspirasi Cina",
    "Paket pajak global",
    "pemilihan federal Jerman",
    "Peringkat Angela Merkel",
    "Pajak perusahaan yang lebih tinggi",
    "Lebih banyak uang untuk guru",
    "Tindakan hukum atas kesepakatan masker",
    "Kelompok-kelompok Yahudi mengecam Baudet atas klaim Perang Dunia II",
    "NL harus mempercepat untuk memenuhi kebijakan iklim UE",
    "Anak laki-laki, 12, menang hak untuk divaksinasi",
    "Ya/Ya stiker surat sampah",
    "Pawai krisis perumahan",
    "Transisi energi tidak aman di dunia maya",
    "Panggilan pihak Maori untuk mengganti nama Selandia Baru",
    "Pulau Fraser berganti nama menjadi K'gari",
    "Hak atas tanah adat Brasil",
    "Boris Johnson muppet",
    "Penculikan wartawan",
    "Hukum aborsi Texas",
    "Sengketa pulau Jepang",
    "Sudan gagal kudeta",
    "Sumber daya alam Afghanistan yang belum dimanfaatkan",
    "Pemogokan iklim global",
    "Meksiko mendekriminalisasi aborsi",
    "Texas mengkriminalkan aborsi",
    "Skotlandia empat hari kerja seminggu",
    "Memulung bangkai hewan",
    "makalah pandora",
    "Konvoi menteri bunuh petani",
    "Drone Pakistan menjatuhkan senjata",
    "Latihan militer Iran di Azerbejdzan",
    "Gas air mata ditembakkan",
    "La Palma menghitung biaya",
    "Robocop kehidupan nyata",
    "Pelacakan baja cina",
    "Penjualan ilegal hutan hujan Amazon",
    "Facebook menjual hutan hujan Amazon",
    "Kekeringan besar California",
    "ebola kongo",
    "pembangkit listrik Lebanon",
    "Libanon kekurangan bahan bakar",
    "Tidak ada bensin inggris",
    "Drone serang bandara Saudi",
    "Pasukan Ethiopia mengintensifkan",
    "Pemadaman besar-besaran Facebook",
    "Facebook down lagi",
    "Taliban 2.0",
    "Rencana aborsi Texas",
    "melintasi Darien Gap",
    "hutan migrasi panama",
    "Rawa asin menyelamatkan nyawa",
    "tumpahan minyak California",
    "Pengamplasan yang terkontaminasi",
    "Penambangan laut dalam",
    "Teripang polimetalik",
    "Ikan pipih gunung laut Daikoku",
    "makhluk laut tentakel",
    "gurita Dumbo yang terlantar",
    "Greenpeace menghentikan minyak ikan",
    "Buzzard berkaki panjang yang diselamatkan",
    "Perjalanan traumatis Panama",
    "Badai ida melanda",
    "Manfaat hiu macan",
    "Pencakar langit kuno Yaman",
    "Victoria jatuh menyusut",
    "Tim pembersih tumpahan minyak",
    "Ledakan alpukat Kolombia",
    "Emas hijau Kolombia",
    "Kontes penambangan dasar laut",
    "Pekerja pabrik daging",
    "Kehidupan kawah Jezero",
    "Space-X percikan ke bawah",
    "Penerbangan virtual arkeologi",
    "Produksi pil Covid-19",
    "Aktor Rusia ISS",
    "Arkeolog norwegia ski",
    "Gempa pakistan yang terluka",
    "Minyak pantai Huntington",
    "Bodycam polisi Ohio",
    "Video bocoran Pentagon",
    "Ketegangan Cina Taiwan",
    "Krisis chip global Intel",
    "Musim jangkrik dimulai",
    "Depresi tropis henri",
    "Siapkan nicolas tropis",
    "Pangkalan Udara Bagram Taliban",
    "Runtuh di tepi selancar florida",
    "Branson di luar angkasa",
    "Invasi terbang Jamaika",
    "Kolam renang lava hitam",
    "Kolam menelan lava",
    "WHO merekomendasikan vaksin malaria",
    "Chang'e 5 sampel bulan",
    "Reunifikasi Tiongkok",
    "Kekerasan Protes Petani India",
    "Krisis listrik Delhi",
    "Saudi mengambil alih Newcastle",
    "gempa bumi pakistan",
    "Dinosaurus theropoda tertua",
    "2 toilet berusia 700 tahun Yerusalem",
    "Lebah Asia invasif",
    "Festival rave gigafactory Tesla",
    "Gigarave",
    "nenek menari cina",
    "Arp 91 Hubble",
    "Kenya melarang tarian lokal",
    "Tarian Amoral Kwara Kwara",
    "Bangkai kapal Gallipoli",
    "Radiasi Mars",
    "ラパルマ火山の噴火",
    "ラパルマが激化",
    "COP26気候会議",
    "Covidブースタージャブ",
    "子供のワクチン",
    "世界的な山火事",
    "アメリカで最も古い足跡",
    "アフリカのアンキロサウルス",
    "フエゴ火山が噴火",
    "食用昆虫",
    "ティタノコリスゲインシ化石",
    "深刻な水不足",
    "渡り鳥がニューヨークの高層ビルに激突",
    "最大の木の森林火災",
    "鉄砲水警報",
    "シンガポールの赤ちゃんパンダ",
    "ベトナムの嵐",
    "オーストラリアの地震",
    "逃げた馬の群れ",
    "南オーストラリアの陥没穴",
    "土石流が道路を塞ぐ",
    "洪水がドイツとベルギーを襲った",
    "Brexitの木材不足",
    "ラローズコミュニティは泥で立ち往生",
    "湖が赤くなったヨルダン",
    "熱帯低気圧サム",
    "急速な温暖化",
    "記録的な高温",
    "温帯低気圧イダ",
    "新しいcovidバリアント",
    "成層圏極渦",
    "イルカ虐殺フェロー諸島",
    "南極上のオゾンホール",
    "イベルメクチンcovid治療",
    "イラクとシリアの干ばつ",
    "新しい亀裂が噴火する",
    "グローバル廃棄物レビュー",
    "遺伝子編集マウス",
    "量子通信ネットワーク",
    "ワニ",
    "考古学者の人間のシンボル",
    "世界的なミツバチの衰退",
    "世界的な氷の喪失",
    "TOI-178太陽系外惑星",
    "透明な木材を製造",
    "スプートニク-V",
    "ホスフィン金星",
    "メタン火星",
    "アマゾン盆地は放出します",
    "火星の掘削",
    "ローバーは火星を訓練します",
    "グリーンランドアイスフリー",
    "ビットコインマイニング排出量",
    "アサガオポップアップ春",
    "古細菌イエローストーン公園",
    "ボーグ",
    "ブラックホールの後ろの光",
    "肉のバイオプリンティング",
    "Qeqertaq Avannarleq",
    "グリーンランド新島",
    "最北端の陸地",
    "火星の岩のサンプリング",
    "人間の足跡ニューメキシコ",
    "違法な採掘作業",
    "カリフォルニアの油流出",
    "中国の石炭不足",
    "サイクロンシャヒーン",
    "タイの洪水警報",
    "成長する食の砂漠",
    "珍しいアラビアヒョウ",
    "オリオン座流星群",
    "宇宙旅行",
    "SpaceX宇宙旅行者",
    "ハイチ移民キャンプテキサス",
    "アンゲラ・メルケル首相が辞任",
    "サンパウロのリロケーションサービス",
    "ボリビア国境からの移民強制送還",
    "移民はコロンビア国境を立ち往生",
    "男は政府の前で火をつけた",
    "移住を防ぐための外国投資",
    "サルサダンサーは過激な抗議者になりました",
    "英国のトラック運転手の危機",
    "海外のダンスカンパニーつま先立ち",
    "世界最大のダンスフェスティバル",
    "ダンス愛好家のためのサンパウロ",
    "動きをバストする場所",
    "テラは開発ゾーンを廃止する運動に参加します",
    "ボルソナロは動きの制限を拒否します",
    "ボンクラウドチェスオープニング",
    "ブラジルのカーニバル",
    "ドーバー港の抗議者",
    "無料の公共交通機関をお楽しみください",
    "アムステルダムアルバートハインの店舗は接着剤で閉められました",
    "南アフリカの市民不安",
    "LGBTの学校の生徒が抗議する",
    "ガザの封鎖",
    "綱渡りエッフェル塔",
    "メットガラ",
    "ピボットダンサー",
    "ベラルーシポーランド国境",
    "アメリカはUFOイベントをリリースします",
    "ポップアップ自転車レーン",
    "ミラノの飛行機墜落事故",
    "選挙前の気候ラリー",
    "アフガニスタンの避難飛行が阻止された",
    "中絶行進ラテンアメリカ",
    "巡礼者はアルバインを記念します",
    "洪水後のホームレス",
    "抗ワクチン抗議メルボルン",
    "活動家はシェルの港を封鎖する",
    "黄色い傘の抗議",
    "リビアでの襲撃中に拘留された",
    "飢餓の拡大レバノン",
    "ウルグアイはマリジウアナを刑務所に入れることを許可します",
    "抗ワクチン抗議メルボルン",
    "予防接種センターへの出席が少ない",
    "BioNTechの科学者が賞を受賞",
    "子供のコビッドワクチン",
    "コロナワクチンと月経の変化",
    "「プロテイントラップ」は科学的な進歩です",
    "ハバナ症候群",
    "米国は低所得国にワクチン用量を寄付します",
    "マイクロプラスチックの台頭",
    "2週間はAppStoreから禁止されました",
    "中国で禁止されているビットコイン",
    "ウイルスミームNFT",
    "「匿名」の動きがデータを漏らす",
    "ゲームは10代の若者に読書を勧めます",
    "ワシントンDCのバイオテロラボ",
    "トゥアス爆発",
    "ワイオミングブロックチェーンスタンピード",
    "Theranosの創設者エリザベスホームズの裁判",
    "Appleはユーザー画像のスキャンを計画しています",
    "化学アリ工場",
    "TikTokは大量請求に直面しています",
    "E.coli再生可能エネルギー",
    "中国のゲーム禁止",
    "Pegasusスパイウェア",
    "マイクロソフトでエジプト最年少のAIプロフェッショナル",
    "HIV治療としての遺伝子編集",
    "ゲノム編集農業革命",
    "台湾が生物防御訓練を開始",
    "新しい植物ベースの液体卵",
    "三者の遺伝的起源",
    "ナイジェリアの暗号通貨ブーム",
    "Facebook上院聴聞会の十代の安全",
    "中国のトリウム原子炉",
    "ジュノー宇宙船",
    "3Dプリントされたテスラ",
    "マインドコントロールポンゲーム",
    "半自動の創意工夫",
    "ブレイン・コンピューター・インターフェース",
    "ランドサット9号衛星",
    "ダブルアステロイドリダイレクションテスト",
    "がん細胞を破壊するバクテリア",
    "火星での農業",
    "温度と接触のための神経センサー",
    "水星の最初の画像",
    "8歳のブラジルの天文学者",
    "バイパーランディング",
    "カメレオン人工皮膚",
    "配信ボット",
    "シングルサイトソーラーパーク",
    "きれいな水に生体模倣を使用する",
    "生体模倣センター",
    "サイバーポリゴン",
    "デジトラック",
    "テスラメガバッテリー",
    "3Dプリントされた臓器",
    "アプリはヘルスケアを混乱させる",
    "ロシアの宇宙映画",
    "極超音速ミサイルテスト",
    "AUPUS協定",
    "中国からの軍用機の購入",
    "国防のためのブロックチェーンソリューション",
    "防衛のためのより多くのお金",
    "タリバンがカブールに侵入",
    "国内過激派グループ",
    "Pegasusスパイウェア",
    "米国の撤退アフガニスタン",
    "ISプロパガンダ",
    "CIAの秘密のカブール",
    "即応大隊",
    "フォートデトリック中国の陰謀",
    "グローバルタックスパック",
    "ドイツ連邦選挙",
    "アンゲラ・メルケル首相の評価",
    "法人税の引き上げ",
    "教師のためのより多くのお金",
    "マスク取引に関する法的措置",
    "ユダヤ人グループは第二次世界大戦の主張のためにボーデを非難する",
    "NLはEUの気候政策を満たすためにスピードアップする必要があります",
    "12歳の少年がワクチン接種を受ける権利を勝ち取る",
    "はい/はいジャンクメールステッカー",
    "住宅危機の行進",
    "サイバーセキュアではないエネルギー転換",
    "ニュージーランドの名前を変更するマオリ党の呼びかけ",
    "フレーザー島はK’gariに改名",
    "ブラジル先住民の土地の権利",
    "ボリスジョンソンマペット",
    "ジャーナリストの拉致",
    "テキサス州の中絶法",
    "日本の島紛争",
    "スーダンはクーデターに失敗しました",
    "アフガニスタンの未開発の天然資源",
    "世界的な気候ストライキ",
    "メキシコは中絶を非難します",
    "テキサスは中絶を批判します",
    "スコットランドの4日間の労働週",
    "動物の死骸の清掃",
    "パンドラペーパー",
    "大臣の護送船団が農民を殺害",
    "パキスタンのドローンが武器を落とす",
    "イラン軍の訓練アゼルバイジャン",
    "催涙ガスが発射された",
    "ラパルマはコストを数えます",
    "実生活のロボコップ",
    "中国鋼鉄の追跡",
    "違法販売アマゾン熱帯雨林",
    "Facebookはアマゾンの熱帯雨林を販売しています",
    "カリフォルニアの大干ばつ",
    "コンゴエボラ",
    "レバノンの発電所",
    "燃料不足レバノン",
    "ガソリンイングランドなし",
    "ドローンがサウジ空港を攻撃",
    "エチオピア軍が激化",
    "Facebookの大規模な停止",
    "Facebookが再びダウン",
    "タリバン2.0",
    "テキサスの中絶計画",
    "ダリエンギャップを越える",
    "パナマ移民ジャングル",
    "塩性湿地は命を救う",
    "カリフォルニアの油流出",
    "汚染されたミユビシギ",
    "深海採鉱",
    "多金属ナマコ",
    "大黒海山ヒラメ",
    "触手海の生き物",
    "ジュウモンジダコ",
    "グリーンピースは魚油を止めます",
    "ニシオオノスリを救出した",
    "外傷性の旅パナマ",
    "温帯低気圧イダヒット",
    "イタチザメのメリット",
    "イエメンの古代の超高層ビル",
    "ビクトリアの滝が縮む",
    "油流出クリーンチーム",
    "コロンビアのアボカドブーム",
    "コロンビアグリーンゴールド",
    "海底採鉱コンテスト",
    "食肉工場労働者",
    "ジェゼロクレーターライフ",
    "スペースXスプラッシュダウン",
    "考古学的な仮想飛行",
    "Covid-19ピルの製造",
    "ロシアの俳優ISS",
    "考古学者ノルウェースキー",
    "負傷したパキスタン地震",
    "ハンティントンビーチオイル",
    "ボディカメラオハイオ警察",
    "ペンタゴンがビデオを漏らした",
    "緊張中国台湾",
    "インテルグローバルチップ危機",
    "蝉の季節が始まる",
    "熱帯低気圧アンリ",
    "熱帯のニコラを準備する",
    "タリバンバグラム空軍基地",
    "フロリダ州サーフサイドを崩壊",
    "宇宙のブランソン",
    "ハエの侵入ジャマイカ",
    "黒い溶岩スイミングプール",
    "溶岩嚥下プール",
    "WHOはマラリアワクチンを推奨しています",
    "嫦娥5月のサンプル",
    "中国統一",
    "暴力インド農民の抗議",
    "デリー電力危機",
    "サウジアラビアの買収ニューカッスル",
    "パキスタンの地震",
    "最古の獣脚類恐竜",
    "2700年前のトイレエルサレム",
    "侵略的なツマアカスズメバチ",
    "テスラギガファクトリーレイブフェスティバル",
    "ギガラベ",
    "おばあちゃんを踊る中国",
    "Arp91ハッブル",
    "ケニアは地元のダンスを禁止します",
    "クワラクワラ不道徳なダンス",
    "ガリポリ難破船",
    "放射線火星",
    "Erupção do vulcão La Palma",
    "La Palma intensifica",
    "Conferência do clima COP26",
    "Covid booster jab",
    "Vacina infantil",
    "Incêndios florestais globais",
    "Pegadas mais antigas na América",
    "Anquilossauro africano",
    "Vulcão Fuego entra em erupção",
    "Insetos comestíveis",
    "Fóssil de Titanokorys gainesi",
    "Grave falta de água",
    "Pássaros em migração caem em arranha-céus de Nova York",
    "Maior incêndio florestal",
    "Alerta de enchente",
    "Bebê panda de Singapura",
    "Tempestade vietnamita",
    "Terremoto australiano",
    "Manada de cavalos fugitivos",
    "Sumidouro da Austrália do Sul",
    "Fluxo de detritos bloqueia estradas",
    "Inundações atingem Alemanha e Bélgica",
    "Falta de madeira Brexit",
    "Comunidade de Larose presa na lama",
    "Lake ficou vermelho Jordan",
    "Tempestade tropical Sam",
    "Aquecimento rápido",
    "Temperatura alta recorde",
    "Furacão Ida",
    "Nova variante covid",
    "Vórtice polar estratosférico",
    "Abate de golfinhos nas Ilhas Faroé",
    "Buraco de ozônio sobre a Antártica",
    "Tratamento covídeo com ivermectina",
    "Seca no Iraque e na Síria",
    "Novas fissuras surgem",
    "Revisão global de resíduos",
    "Ratos de edição de genes",
    "Rede de comunicação quântica",
    "Crocodilia",
    "Símbolos humanos de arqueólogos",
    "Declínio global das abelhas",
    "Perda global de gelo",
    "Exoplanetas TOI-178",
    "Madeira transparente fabricada",
    "Sputnik-V",
    "Fosfina Vênus",
    "Metano marte",
    "Bacia amazônica emite",
    "Perfurando Marte",
    "Rover perfura Marte",
    "Gelo da Groenlândia livre",
    "Emissões de mineração de Bitcoin",
    "Manhã de primavera pop-up",
    "Parque Archaea Yellowstone",
    "Borgs",
    "luz atrás do buraco negro",
    "Biimpressão de carne",
    "Qeqertaq Avannarleq",
    "Nova ilha da Groenlândia",
    "Mais ao norte da massa de terra",
    "amostragem de rocha de Marte",
    "pegadas humanas Novo México",
    "Operação de mineração ilegal",
    "Derramamento de óleo na Califórnia",
    "Escassez de carvão na China",
    "Ciclone Shaheen",
    "Alerta de inundação na Tailândia",
    "Crescendo deserto de comida",
    "Leopardo árabe raro",
    "Chuva de meteoros orionídeos",
    "Turismo espacial",
    "Turistas espaciais SpaceX",
    "Campo de migrantes haitianos no Texas",
    "Angela Merkel deixando o cargo",
    "Serviços de Relocação em São Paulo",
    "Deportação de migrantes da fronteira com a Bolívia",
    "Migrantes retidos na fronteira com a Colômbia",
    "Homem ateou fogo a si mesmo na frente do governo",
    "Investimento estrangeiro para evitar a migração",
    "A dançarina de salsa tornou-se um manifestante radical",
    "Crise do motorista de caminhão no Reino Unido",
    "Companhia de dança na ponta dos pés",
    "o maior festival de dança do mundo",
    "São Paulo para os amantes da dança",
    "Lugares para estourar uma mudança",
    "Tela junta-se ao movimento para revogar Zonas de Desenvolvimento",
    "Bolsonaro rejeita restrições ao movimento",
    "Bong na nuvem de xadrez de abertura",
    "Carnaval brasileiro",
    "Manifestantes do porto de Dover",
    "Aproveite o transporte público gratuito",
    "Lojas Amsterdam Albert Heijn coladas",
    "Agitação civil na África do Sul",
    "Alunos de escolas LGBT protestam",
    "Bloqueio em Gaza",
    "Equador na corda bamba Torre Eiffel",
    "The Met Gala",
    "Dançarina pivotante",
    "Fronteira da Bielorrússia com a Polônia",
    "EUA lançam eventos de OVNIs",
    "Ciclovias emergentes",
    "Acidente de avião em Milão",
    "Comício climático antes das eleições",
    "Voo de evacuação do Afeganistão bloqueado",
    "Marcha do aborto na América Latina",
    "Os peregrinos comemoram Arbaeen",
    "Sem-teto após inundação",
    "Protesto anti-vacina em Melbourne",
    "Ativistas bloqueiam a porta de Shell",
    "Protesto de guarda-chuva amarelo",
    "Detido durante invasões na Líbia",
    "Crescente fome no Líbano",
    "Uruguai permite maconha nas prisões",
    "Protesto antivacinas Melbourne",
    "Baixa frequência nos postos de vacinação",
    "Cientista da BioNTech ganha prêmio",
    "vacina covid para crianças",
    "Vacina Covid e alterações menstruais",
    "'armadilha de proteína' é um avanço científico",
    "Síndrome de Havana",
    "EUA doam doses de vacina para países de baixa renda",
    "Aumento de microplásticos",
    "Quinze dias banidos da App Store",
    "Bitcoin banido na China",
    "NFTs de meme viral",
    "Movimento ‘Anônimo’ vaza dados",
    "Os jogos incentivam os adolescentes a ler",
    "Laboratório de bioterrorismo em DC",
    "Explosão de Tuas",
    "Corrida de blocos de Wyoming",
    "Julgamento da fundadora da Theranos, Elizabeth Holmes",
    "Apple planeja escanear imagens de usuários",
    "Fábricas de formigas químicas",
    "TikTok enfrenta reivindicação em massa",
    "Energia renovável de E.coli",
    "Proibição de jogos na China",
    "Spyware Pegasus",
    "O mais jovem profissional de IA do Egito na Microsoft",
    "Edição de genes como cura para o HIV",
    "Revolução agrícola de edição de genoma",
    "Taiwan lança exercícios de biodefesa",
    "Novo ovo líquido à base de plantas",
    "Origem genética tripartida",
    "Mania de criptomoeda da Nigéria",
    "Senado do Facebook ouvindo segurança para adolescentes",
    "Reator de tório da china",
    "Nave espacial Juno",
    "Tesla impresso em 3D",
    "Jogo de pong de controle mental",
    "Engenhosidade semi-automática",
    "Interface cérebro-computador",
    "Satélite Landsat 9",
    "Teste de Redirecionamento de Asteróide Duplo",
    "Bactérias que destroem células cancerosas",
    "Cultivando em Marte",
    "Sensores nervosos para temperatura e toque",
    "Primeiras imagens de mercúrio",
    "Astrônomo brasileiro de 8 anos",
    "Aterrissagem da víbora",
    "Pele artificial camaleão",
    "Bot de entrega",
    "Parque solar de local único",
    "Usando biomimética para água limpa",
    "Centro de Biomimética",
    "Polígono cibernético",
    "Caminhão digi",
    "Mega bateria tesla",
    "Órgãos impressos em 3D",
    "App atrapalha a saúde",
    "Filme espacial russo",
    "Teste de míssil hipersônico",
    "Pacto AUPUS",
    "compra de aeronave militar da China",
    "soluções de blockchain para defesa nacional",
    "Mais dinheiro para defesa",
    "Talibã invade Cabul",
    "Grupo extremista doméstico",
    "Spyware Pegasus",
    "Retirada dos EUA do Afeganistão",
    "É propaganda",
    "Segredo da CIA em Cabul",
    "Batalhão de ação rápida",
    "Conspiração chinesa de Fort Detrick",
    "Pacote fiscal global",
    "Eleição federal alemã",
    "Avaliação de Angela Merkel",
    "Impostos corporativos mais altos",
    "Mais dinheiro para professores",
    "Ação legal sobre ofertas de máscaras",
    "Grupos judaicos batem Baudet por reivindicação da Segunda Guerra Mundial",
    "NL deve acelerar para cumprir a política climática da UE",
    "Menino, 12, ganha o direito de ser vacinado",
    "Sim / Sim adesivo de lixo eletrônico",
    "Marcha pela crise imobiliária",
    "Transição de energia não cibernética",
    "Chamada maori para renomear Nova Zelândia",
    "Ilha Fraser renomeada K’gari",
    "Direitos à terra indígena no Brasil",
    "Muppets Boris Johnson",
    "Rapto de jornalista",
    "Lei de aborto do Texas",
    "Disputa da ilha do Japão",
    "Sudão fracassou no golpe",
    "Recursos naturais inexplorados do Afeganistão",
    "Greve climática global",
    "México descrimaliza abortos",
    "Texas crimaliza abortos",
    "Escócia quatro dias semanais de trabalho",
    "Carcaças de animais necrófagos",
    "Papéis Pandora",
    "Comboio do ministro mata fazendeiros",
    "Drone do Paquistão deixa cair armas",
    "Exército iraniano treina no Azerbejdzan",
    "Gás lacrimogêneo disparado",
    "La Palma conta o custo",
    "Robocop da vida real",
    "Rastreamento de aço da China",
    "Venda ilegal da floresta amazônica",
    "Facebook vende floresta amazônica",
    "Mega-seca da Califórnia",
    "Ebola congo",
    "Usinas libanesas",
    "Escassez de combustível no Líbano",
    "Sem gasolina inglaterra",
    "Drone ataca aeroporto saudita",
    "Tropas etíopes intensificam",
    "Grande interrupção do Facebook",
    "Facebook caiu novamente",
    "Taliban 2.0",
    "Planos de aborto no Texas",
    "cruzando Darien Gap",
    "selva de migração do panamá",
    "Pântano salgado salva vidas",
    "Derramamento de óleo na Califórnia",
    "Sanderling contaminado",
    "Mineração em alto mar",
    "Pepino do mar polimetálico",
    "Peixe chato Daikoku submarino",
    "criatura marinha com tentáculos",
    "polvos Dumbo deslocados",
    "Greenpeace para de óleo de peixe",
    "Urubu de pernas compridas resgatado",
    "Viagem traumática Panamá",
    "Furacão Ida atingiu",
    "Benefícios do tubarão tigre",
    "Arranha-céu antigo do Iêmen",
    "Victoria Falls encolher",
    "Equipe de limpeza de derramamento de óleo",
    "Boom do abacate na Colômbia",
    "Ouro verde colombia",
    "Concurso de mineração do fundo do mar",
    "Trabalhadores da fábrica de carne",
    "Vida na cratera de Jezero",
    "Space-X splash down",
    "Voos virtuais arqueológicos",
    "Produção de comprimidos Covid-19",
    "Ator da Rússia ISS",
    "Arqueólogo esqui norueguês",
    "Terremoto no Paquistão ferido",
    "Óleo de Huntington Beach",
    "Polícia Bodycam Ohio",
    "Vídeos vazados do Pentágono",
    "Tensões China Taiwan",
    "Crise global do chip Intel",
    "A temporada da cigarra começa",
    "Depressão tropical Henri",
    "Prepare nicolas tropicais",
    "Base aérea de bagram do Talibã",
    "Colapso surfside florida",
    "Branson no espaço",
    "Invasão de moscas Jamaica",
    "Piscina de lava negra",
    "Piscina para engolir lava",
    "OMS recomenda vacina contra malária",
    "Amostras de 5 luas de Chang'e",
    "Reunificação China",
    "Protestos de fazendeiros de violência na Índia",
    "Crise de energia em Delhi",
    "Aquisição saudita de Newcastle",
    "Terremoto do Paquistão",
    "Dinossauro terópode mais antigo",
    "2 banheiros de 700 anos em Jerusalém",
    "Vespa asiática invasora",
    "Tesla gigafactory rave festival",
    "Gigarave",
    "Avós dançantes chinesas",
    "Arp 91 Hubble",
    "Quênia proíbe dança local",
    "Kwara Kwara dança imoral",
    "Gallipoli naufrágios",
    "Radiação de Marte",
    "Извержение вулкана Ла Пальма",
    "Ла Пальма усиливается",
    "Конференция по климату COP26",
    "Укол ракеты-носителя Covid",
    "Детская вакцина",
    "Глобальные лесные пожары",
    "Самые старые следы в Америке",
    "Африканский анкилозавр",
    "Извержение вулкана Фуэго",
    "Съедобные насекомые",
    "Окаменелость Titanokorys Gainesi",
    "Острая нехватка воды",
    "Перелетные птицы врезаются в небоскребы Нью-Йорка",
    "Крупнейший лесной пожар на дереве",
    "Экстренное предупреждение о наводнениях",
    "Сингапурская панда",
    "Вьетнамский шторм",
    "Землетрясение в Австралии",
    "Табун сбежавших лошадей",
    "Воронка в Южной Австралии",
    "Селевой поток блокирует дороги",
    "Наводнения обрушились на Германию и Бельгию",
    "Брексит нехватка древесины",
    "Сообщество Лароз застряло в грязи",
    "Озеро стало красным Иордан",
    "Тропический шторм Сэм",
    "Быстрое нагревание",
    "Рекордно высокая температура",
    "Ураган Ида",
    "Новый вариант covid",
    "Стратосферный полярный вихрь",
    "Убийство дельфинов Фарерские острова",
    "Озоновая дыра над антарктидой",
    "Лечение ивермектина covid",
    "Засуха в Ираке и Сирии",
    "Новые трещины прорываются",
    "Глобальный обзор отходов",
    "Мыши, редактирующие ген",
    "Квантовая сеть связи",
    "Крокодилы",
    "Археологи человеческие символы",
    "Глобальный спад пчел",
    "Глобальная потеря льда",
    "Экзопланеты TOI-178",
    "Изготовлено из прозрачного дерева",
    "Спутник-В",
    "Фосфин Венера",
    "Метан Марс",
    "Бассейн Амазонки излучает",
    "Бурение на Марс",
    "Ровер сверлит Марс",
    "Гренландия без льда",
    "Выбросы от майнинга биткойнов",
    "Ипомея всплывающая весна",
    "архей йеллоустонский парк",
    "Борги",
    "свет за черной дырой",
    "Биопечать мяса",
    "Qeqertaq Avannarleq",
    "Новый остров Гренландия",
    "Самый северный континент",
    "отбор проб Марса",
    "человеческие следы Нью-Мексико",
    "Незаконная добыча полезных ископаемых",
    "Разлив нефти в Калифорнии",
    "Дефицит угля в Китае",
    "Циклон Шахин",
    "Предупреждение о наводнении в Таиланде",
    "Растущая продовольственная пустыня",
    "Редкий арабский леопард",
    "Метеоритный дождь Орионид",
    "Космический туризм",
    "SpaceX космические туристы",
    "Лагерь гаитянских мигрантов Техас",
    "Ангела Меркель уходит в отставку",
    "Услуги по переезду в Сан-Паулу",
    "Депортация мигрантов из границы с Боливией",
    "Мигранты застряли на границе с Колумбией",
    "Человек поджег себя перед правительством",
    "Иностранные инвестиции для предотвращения миграции",
    "Танцовщица сальсы стала радикальной протестующей",
    "Кризис водителя грузовика в Великобритании",
    "Танцевальная компания на цыпочках за границу",
    "величайший танцевальный фестиваль в мире",
    "Сан-Паулу для любителей танцев",
    "Места для переезда",
    "Tela присоединяется к движению за отмену зон развития",
    "Болсонару отвергает ограничения на передвижение",
    "Дебют Bong Cloud Chess",
    "Бразильский карнавал",
    "Протестующие в порту Дувра",
    "Наслаждайтесь бесплатным общественным транспортом",
    "Амстердамские магазины Albert Heijn закрыты",
    "Гражданские беспорядки в Южной Африке",
    "Протест школьников ЛГБТ",
    "Блокада в Газе",
    "Канатоходец Эйфелева башня",
    "Met Gala",
    "Вращающийся танцор",
    "Беларусь Польша граница",
    "США выпускают события НЛО",
    "Всплывающие велосипедные дорожки",
    "Авиакатастрофа в Милане",
    "Климатический митинг перед выборами",
    "Заблокирован эвакуационный рейс из Афганистана",
    "Марш абортов в Латинской Америке",
    "Паломники поминают Арбаин",
    "Бездомный после наводнения",
    "Протест против вакцины Мельбурн",
    "Активисты блокируют порт Shell",
    "Протест желтого зонтика",
    "Задержан во время рейдов в Ливии",
    "Растущий голод Ливан",
    "В Уругвае марихуана разрешена в тюрьмах",
    "Протест против вакцины Мельбурн",
    "Низкая посещаемость центров вакцинации",
    "Ученый BioNTech получил приз",
    "Детская вакцина против COVID",
    "Вакцина против Covid и изменения менструального цикла",
    "«белковая ловушка» - это научный прорыв",
    "Синдром Гаваны",
    "США жертвуют дозы вакцины странам с низкими доходами",
    "Рост микропластика",
    "Две недели забанены в магазине приложений",
    "Биткойн запрещен в Китае",
    "Вирусные мемы NFT",
    "«Анонимное» движение утекает данные",
    "Игры побуждают подростков читать",
    "Лаборатория биотерроризма в округе Колумбия",
    "Взрыв Туаса",
    "Паника блокчейна в Вайоминге",
    "Суд над основателем Theranos Элизабет Холмс",
    "Apple планирует сканировать изображения пользователей",
    "Химические муравьиные фабрики",
    "TikTok столкнулся с массовыми претензиями",
    "E.coli возобновляемые источники энергии",
    "Запрет на азартные игры в Китае",
    "Шпионское ПО Pegasus",
    "Самый молодой египтянин в области искусственного интеллекта в Microsoft",
    "Редактирование генов как лекарство от ВИЧ",
    "Революция в области редактирования генома",
    "Тайвань запускает учения по биозащите",
    "Новое жидкое яйцо на растительной основе",
    "Трехстороннее генетическое происхождение",
    "Повальное увлечение криптовалютой в Нигерии",
    "Сенат Facebook слышит о безопасности подростков",
    "Китайский ториевый реактор",
    "Космический корабль Юнона",
    "3D-печать Tesla",
    "Игра в понг с контролем над разумом",
    "Полуавтоматическая изобретательность",
    "Интерфейс мозг-компьютер",
    "Спутник Landsat 9",
    "Двойной тест перенаправления астероидов",
    "Бактерии, разрушающие раковые клетки",
    "Фермерство на Марсе",
    "Нервные датчики температуры и прикосновения",
    "Первые изображения Меркурия",
    "8-летний бразильский астроном",
    "Посадка гадюки",
    "Искусственная кожа хамелеон",
    "Бот доставки",
    "Односайтовый солнечный парк",
    "Использование биомимикрии для получения чистой воды",
    "Центр биомимикрии",
    "Кибер-полигон",
    "Digi Truck",
    "Тесла мега аккумулятор",
    "3d печатные органы",
    "Приложение разрушает здравоохранение",
    "Русский космический фильм",
    "Испытания гиперзвуковых ракет",
    "Пакт AUPUS",
    "закупка военных самолетов из Китая",
    "блокчейн-решения для национальной обороны",
    "Больше денег на оборону",
    "Талибан вторгается в Кабул",
    "Отечественная экстремистская группа",
    "Шпионское ПО Pegasus",
    "Вывод США из Афганистана",
    "Пропаганда ИГ",
    "ЦРУ секретный Кабул",
    "Батальон быстрого действия",
    "Форт Детрик Китайский заговор",
    "Глобальный налоговый пакет",
    "Федеральные выборы в Германии",
    "Рейтинг Ангелы Меркель",
    "Более высокие корпоративные налоги",
    "Больше денег учителям",
    "Судебные иски по сделкам с масками",
    "Еврейские группы критикуют Боде за претензии во время Второй мировой войны",
    "Нидерланды должны ускориться, чтобы соответствовать климатической политике ЕС",
    "Мальчик, 12 лет, получает право на вакцинацию",
    "Да / Да стикер нежелательной почты",
    "Марш жилищного кризиса",
    "Энергетический переход не кибербезопасен",
    "Партия маори призывает переименовать Новую Зеландию",
    "Остров Фрейзер переименован в Кгари.",
    "Бразилия Права коренных народов на землю",
    "Борис Джонсон куклы",
    "Похищение журналиста",
    "Закон Техаса об абортах",
    "Островной спор Японии",
    "Судан неудачный переворот",
    "Неосвоенные природные ресурсы Афганистана",
    "Глобальная климатическая забастовка",
    "Мексика запрещает аборты",
    "Техас криминализирует аборты",
    "Шотландия четырехдневная рабочая неделя",
    "Сбор туш животных",
    "Бумаги Пандоры",
    "Колонна министра убивает фермеров",
    "Пакистанский дрон сбрасывает оружие",
    "Учения армии Ирана в Азербайджане",
    "Слезоточивый газ запущен",
    "Ла Пальма считает стоимость",
    "Реальный робокоп",
    "Отслеживание китайской стали",
    "Незаконная продажа тропических лесов Амазонки",
    "Facebook продает тропические леса Амазонки",
    "Калифорнийская мега-засуха",
    "Конго Эбола",
    "Ливанские электростанции",
    "Дефицит топлива в Ливане",
    "Англия без бензина",
    "Дрон атакует аэропорт Саудовской Аравии",
    "Эфиопские войска усиливаются",
    "Серьезный сбой в работе Facebook",
    "Facebook снова упал",
    "Талибан 2.0",
    "Планы по аборту в Техасе",
    "пересечение Darien Gap",
    "Панамские миграционные джунгли",
    "Солёное болото спасает жизни",
    "Разлив нефти в Калифорнии",
    "Загрязненный сандерлинг",
    "Глубоководная добыча",
    "Полиметаллический морской огурец",
    "Подводная камбала дайкоку",
    "морское существо с щупальцами",
    "перемещенные осьминоги Дамбо",
    "Гринпис прекращает употребление рыбьего жира",
    "Спасенный длинноногий канюк",
    "Травматическое путешествие по Панаме",
    "Ураган ида ударил",
    "Преимущества тигровой акулы",
    "Йемен древний небоскреб",
    "Виктория падает",
    "Команда по ликвидации разливов нефти",
    "Бум авокадо в Колумбии",
    "Колумбия зеленое золото",
    "Конкурс по добыче морского дна",
    "Рабочие мясокомбината",
    "Жизнь кратера Езеро",
    "Space-X приводнение",
    "Археологические виртуальные полеты",
    "Производство таблеток Covid-19",
    "Россия актер ISS",
    "Археолог норвегия на лыжах",
    "Пострадавшие в результате землетрясения в пакистане",
    "Масло Хантингтон-Бич",
    "Bodycam полиция Огайо",
    "Пентагон слил видео",
    "Напряженность Китай Тайвань",
    "Глобальный кризис чипов Intel",
    "Сезон цикады начинается",
    "Тропическая депрессия Анри",
    "Приготовьте тропический никола",
    "Авиабаза талибов баграм",
    "Свернуть серфсайд Флорида",
    "Брэнсон в космосе",
    "Вторжение мух на Ямайку",
    "Бассейн из черной лавы",
    "Бассейн для проглатывания лавы",
    "ВОЗ рекомендует вакцину против малярии",
    "Образцы луны Chang'e 5",
    "Воссоединение Китая",
    "Насилие Индия Фермеры протестуют",
    "Энергетический кризис в Дели",
    "Поглощение Саудовской Аравией Ньюкасл",
    "Пакистанское землетрясение",
    "Самый старый динозавр-теропод",
    "2700-летний туалет Иерусалим",
    "Инвазивный азиатский шершень",
    "Рейв-фестиваль Tesla gigafactory",
    "Gigarave",
    "Китайские танцующие бабушки",
    "Арп 91 Хаббл",
    "Кения запрещает местные танцы",
    "Квара Квара аморальный танец",
    "Кораблекрушения Галлиполи",
    "Излучение Марса",
    "لا پالما آتش فشاں پھٹنا۔",
    "لا پالما شدت اختیار کرتا ہے۔",
    "COP26 آب و ہوا کانفرنس",
    "کوویڈ بوسٹر جب۔",
    "بچوں کی ویکسین۔",
    "عالمی جنگل کی آگ۔",
    "امریکہ میں قدیم ترین نشانات",
    "افریقی اینکیلوسور۔",
    "فیوگو آتش فشاں پھٹ گیا۔",
    "خوردنی کیڑے۔",
    "Titanokorys حاصل شدہ جیواشم",
    "پانی کی شدید قلت۔",
    "ہجرت کرنے والے پرندے نیویارک کے فلک بوس عمارتوں سے ٹکرا گئے۔",
    "درختوں کے جنگل کی سب سے بڑی آگ۔",
    "پہاڑی علاقے کے سیلاب کی وارننگ",
    "سنگاپور بیبی پانڈا",
    "ویت نام کا طوفان۔",
    "آسٹریلیا کا زلزلہ",
    "فرار گھوڑوں کا ریوڑ۔",
    "جنوبی آسٹریلوی سنک ہول۔",
    "ملبے کا بہاؤ سڑکوں کو روکتا ہے۔",
    "سیلاب نے جرمنی اور بیلجیم کو متاثر کیا۔",
    "بریکسٹ لکڑی کی کمی۔",
    "لاروس کمیونٹی کیچڑ میں پھنس گئی۔",
    "جھیل سرخ ہو گئی اردن۔",
    "اشنکٹبندیی طوفان سیم۔",
    "تیز گرمی",
    "ریکارڈ اعلی درجہ حرارت۔",
    "سمندری طوفان ایڈا۔",
    "کوویڈ کا نیا روپ۔",
    "سٹراسٹوفیرک قطبی بھنور۔",
    "ڈولفن نے جزائر فیرو کو ذبح کیا۔",
    "انٹارکٹیکا کے اوپر اوزون سوراخ",
    "Ivermectin covid کا علاج۔",
    "عراق اور شام میں خشک سالی۔",
    "نئی دراڑیں پھوٹ پڑتی ہیں۔",
    "فضلہ کا عالمی جائزہ",
    "جین ایڈیٹنگ چوہے۔",
    "کوانٹم مواصلاتی نیٹ ورک",
    "مگرمچھ۔",
    "ماہرین آثار قدیمہ انسانی علامات",
    "مکھیوں کی عالمی کمی۔",
    "عالمی برفانی نقصان۔",
    "TOI-178 exoplanets۔",
    "شفاف لکڑی تیار۔",
    "Sputnik-V",
    "فاسفین وینس۔",
    "میتھین مریخ",
    "ایمیزون بیسن خارج کرتا ہے۔",
    "مریخ کی سوراخ کرنا۔",
    "روور مریخ پر ڈرل کرتا ہے۔",
    "گرین لینڈ آئس فری۔",
    "بٹ کوائن کان کنی کا اخراج۔",
    "صبح کی شان پاپ اپ موسم بہار۔",
    "آرکیا یلو اسٹون پارک۔",
    "بورگس۔",
    "بلیک ہول کے پیچھے روشنی",
    "بایوپرنٹنگ گوشت۔",
    "Qeqertaq Avannarleq",
    "گرین لینڈ نیا جزیرہ۔",
    "شمالی سب سے زیادہ زمین کا علاقہ۔",
    "مریخ کی چٹان کا نمونہ",
    "انسانی پاؤں کے نشانات نیو میکسیکو",
    "غیر قانونی کان کنی آپریشن۔",
    "کیلیفورنیا میں تیل پھیلنا۔",
    "چین کوئلے کی قلت",
    "سمندری طوفان شاہین",
    "تھائی لینڈ میں سیلاب کی وارننگ",
    "بڑھتا ہوا خوراک صحرا۔",
    "نایاب عرب چیتا۔",
    "اوریونیڈ الکا شاور۔",
    "خلائی سیاحت",
    "SpaceX خلائی سیاح۔",
    "ہیٹی کا مہاجر کیمپ ٹیکساس۔",
    "انجیلا مرکل اپنا عہدہ چھوڑ رہی ہیں۔",
    "ساؤ پالو میں نقل مکانی کی خدمات۔",
    "بولیویا کی سرحد سے تارکین وطن کی ملک بدری۔",
    "کولمبیا کی سرحد پر پھنسے تارکین وطن۔",
    "ایک شخص نے حکومت کے سامنے خود کو آگ لگا لی۔",
    "ہجرت کو روکنے کے لیے غیر ملکی سرمایہ کاری",
    "سالسا ڈانسر بنیاد پرست مظاہرین بن گئیں۔",
    "برطانیہ میں لاری ڈرائیور کا بحران",
    "بیرون ملک ڈانس کمپنی۔",
    "دنیا کا سب سے بڑا ڈانس فیسٹیول",
    "رقص سے محبت کرنے والوں کے لیے ساؤ پالو۔",
    "مقامات کو چکنا چور کرنے کے لیے۔",
    "ٹیلا ترقیاتی زونوں کو منسوخ کرنے کی تحریک میں شامل ہو گیا۔",
    "بولسنارو نقل و حرکت پر پابندی کو مسترد کرتے ہیں۔",
    "بونگ کلاؤڈ شطرنج کا افتتاح۔",
    "برازیلی کارنیول۔",
    "ڈوور پورٹ پر مظاہرین",
    "مفت پبلک ٹرانسپورٹ سے لطف اٹھائیں۔",
    "ایمسٹرڈیم البرٹ ہیجن اسٹورز بند بند ہیں۔",
    "جنوبی افریقہ میں شہری بدامنی",
    "ایل جی بی ٹی سکول کے طلباء کا احتجاج",
    "غزہ میں ناکہ بندی۔",
    "ٹائٹروپ واکر ایفل ٹاور۔",
    "میٹ گالا۔",
    "پیوٹنگ ڈانسر۔",
    "بیلاروس پولینڈ کی سرحد۔",
    "امریکہ UFO ایونٹس جاری کرتا ہے۔",
    "پاپ اپ موٹر سائیکل لین۔",
    "میلان طیارہ حادثہ۔",
    "انتخابات سے پہلے موسمی ریلی۔",
    "افغانستان سے نکلنے والی پرواز کو مسدود کر دیا۔",
    "اسقاط حمل مارچ لاطینی امریکہ۔",
    "حجاج اربعین کی یاد میں",
    "سیلاب کے بعد بے گھر",
    "اینٹی ویکسین احتجاج میلبورن۔",
    "کارکن شیل کی بندرگاہ کو روکتے ہیں۔",
    "زرد چھتری کا احتجاج۔",
    "لیبیا میں چھاپوں کے دوران حراست میں لیا گیا۔",
    "بڑھتی ہوئی بھوک لبنان",
    "یوراگوئے نے مریجیوانا کو جیلوں میں جانے کی اجازت دی۔",
    "اینٹی ویکسین احتجاج میلبورن۔",
    "ویکسینیشن مراکز میں کم حاضری۔",
    "بائیو ٹیک سائنسدان نے انعام جیت لیا",
    "بچوں کو کوڈ ویکسین۔",
    "کوویڈ ویکسین اور ماہواری میں تبدیلی",
    "'پروٹین ٹریپ' سائنسی پیش رفت ہے۔",
    "ہوانا سنڈروم۔",
    "امریکہ کم آمدنی والے ممالک کو ویکسین کی خوراک عطیہ کرتا ہے۔",
    "مائکرو پلاسٹک کا عروج۔",
    "ایپ سٹور پر پندرہ رات کی پابندی",
    "چین میں بٹ کوائن پر پابندی",
    "وائرل میم این ایف ٹی۔",
    "'گمنام' تحریک ڈیٹا لیک کرتی ہے۔",
    "گیمنگ نوجوانوں کو پڑھنے کی ترغیب دیتی ہے۔",
    "ڈی سی میں بائیو ٹیررازم لیب۔",
    "تواس دھماکہ۔",
    "وومنگ بلاکچین بھگدڑ مچ گئی۔",
    "تھیرانوس کی بانی الزبتھ ہومز کی آزمائش",
    "ایپل صارف کی تصاویر کو اسکین کرنے کا ارادہ رکھتا ہے۔",
    "کیمیائی چیونٹی فیکٹریاں۔",
    "ٹک ٹاک کو بڑے پیمانے پر دعوے کا سامنا ہے۔",
    "E. کولی قابل تجدید توانائی۔",
    "چین میں گیمنگ پر پابندی",
    "پیگاسس سپائی ویئر",
    "مائیکروسافٹ میں مصری کم عمر AI پیشہ ور۔",
    "ایچ آئی وی علاج کے طور پر جین میں ترمیم",
    "جینوم ایڈیٹنگ کاشتکاری انقلاب۔",
    "تائیوان نے بائیو ڈیفنس مشقیں شروع کیں",
    "پودوں پر مبنی نیا مائع انڈا۔",
    "سہ فریقی جینیاتی اصل۔",
    "نائیجیریا cryptocurrency کا جنون۔",
    "فیس بک سینیٹ نے نوعمروں کی حفاظت کی سماعت کی۔",
    "چین تھوریم ری ایکٹر۔",
    "جونو خلائی جہاز۔",
    "تھری ڈی پرنٹ شدہ ٹیسلا۔",
    "مائنڈ کنٹرول پونگ گیم۔",
    "نیم آٹو آسانی۔",
    "برین کمپیوٹر انٹرفیس۔",
    "لینڈ سیٹ 9 سیٹلائٹ",
    "ڈبل کشودرگرہ ری ڈائریکشن ٹیسٹ۔",
    "بیکٹیریا جو کینسر کے خلیوں کو تباہ کرتے ہیں۔",
    "مریخ پر کاشتکاری۔",
    "درجہ حرارت اور ٹچ کے لیے اعصابی سینسر۔",
    "مرکری کی پہلی تصاویر۔",
    "8 سالہ برازیلی ماہر فلکیات۔",
    "وائپر لینڈنگ۔",
    "گرگٹ مصنوعی جلد۔",
    "ڈیلیوری بوٹ۔",
    "سنگل سائٹ سولر پارک۔",
    "صاف پانی کے لیے بائیومیکری کا استعمال۔",
    "بائیومیکری سینٹر",
    "سائبر کثیرالاضلاع",
    "ڈیجی ٹرک۔",
    "ٹیسلا میگا بیٹری۔",
    "3D پرنٹ شدہ اعضاء۔",
    "ایپ صحت کی دیکھ بھال میں خلل ڈالتی ہے۔",
    "روسی خلائی فلم",
    "ہائپرسونک میزائل کا تجربہ",
    "AUPUS معاہدہ",
    "چین سے فوجی طیاروں کی خریداری",
    "قومی دفاع کے لیے بلاکچین حل",
    "دفاع کے لیے مزید رقم۔",
    "طالبان کا کابل پر حملہ",
    "گھریلو انتہا پسند گروپ",
    "پیگاسس سپائی ویئر",
    "افغانستان سے امریکی انخلا",
    "آئی ایس کا پروپیگنڈا",
    "سی آئی اے خفیہ کابل۔",
    "ریپڈ ایکشن بٹالین۔",
    "فورٹ ڈیٹریک چینی سازش",
    "عالمی ٹیکس پیک۔",
    "جرمن وفاقی انتخابات",
    "انجیلا مرکل کی درجہ بندی",
    "زیادہ کارپوریٹ ٹیکس۔",
    "اساتذہ کے لیے مزید رقم۔",
    "ماسک سودوں پر قانونی کارروائی۔",
    "یہودی گروہوں نے دوسری جنگ عظیم کے دعوے کے لیے باؤڈیٹ کو تنقید کا نشانہ بنایا۔",
    "یورپی یونین کی آب و ہوا کی پالیسی کو پورا کرنے کے لیے این ایل کو تیز کرنا ہوگا۔",
    "12 سالہ لڑکا ویکسین لگانے کا حق جیت گیا۔",
    "ہاں/ہاں جنک میل اسٹیکر۔",
    "ہاؤسنگ بحران مارچ۔",
    "توانائی کی منتقلی سائبر محفوظ نہیں ہے۔",
    "ماؤری پارٹی نے نیوزی لینڈ کا نام تبدیل کرنے کا مطالبہ کیا۔",
    "فریزر جزیرے کا نام کاگری رکھا گیا۔",
    "برازیل دیسی زمین کے حقوق",
    "بورس جانسن مپیٹس۔",
    "صحافی کا اغوا۔",
    "ٹیکساس کا اسقاط حمل کا قانون",
    "جاپان کے جزیرے کا تنازعہ",
    "سوڈان کی ناکام بغاوت",
    "افغانستان کے غیر استعمال شدہ قدرتی وسائل",
    "عالمی موسمیاتی ہڑتال۔",
    "میکسیکو اسقاط حمل کو کم کرتا ہے۔",
    "ٹیکساس اسقاط حمل کو کمزور کرتا ہے۔",
    "سکاٹ لینڈ میں ہفتے میں چار دن کام۔",
    "صفائی ستھرائی کے جانوروں کی لاشیں۔",
    "پنڈورا پیپرز۔",
    "وزیر کا قافلہ کسانوں کو مارتا ہے۔",
    "پاکستانی ڈرون ہتھیار گراتا ہے۔",
    "ایرانی فوج نے آذربایجان کو ڈرل کیا۔",
    "آنسو گیس فائر کی گئی۔",
    "لا پالما لاگت کا حساب کرتا ہے۔",
    "حقیقی زندگی کا روبوکوپ۔",
    "ٹریکنگ چین سٹیل۔",
    "غیر قانونی فروخت ایمیزون رین فاریسٹ۔",
    "فیس بک ایمیزون کے جنگلات کو فروخت کرتا ہے۔",
    "کیلیفورنیا میگا قحط۔",
    "کانگو ایبولا۔",
    "لبنانی پاور پلانٹس",
    "ایندھن کی قلت لبنان",
    "کوئی پیٹرول انگلینڈ نہیں۔",
    "سعودی ائیر پورٹ پر ڈرون حملہ",
    "ایتھوپیا کی فوجوں نے شدت اختیار کر لی۔",
    "فیس بک کی بڑی بندش",
    "فیس بک ایک بار پھر نیچے۔",
    "طالبان 2.0",
    "ٹیکساس اسقاط حمل کے منصوبے",
    "ڈیرین گیپ کو عبور کرنا۔",
    "پاناما ہجرت کا جنگل",
    "نمک دلدل جان بچاتا ہے۔",
    "کیلیفورنیا میں تیل پھیلنا۔",
    "آلودہ سینڈرلنگ۔",
    "گہری سمندری کان کنی۔",
    "پولیمیٹالک سمندری ککڑی۔",
    "ڈائیکو سمندری فلیٹ فش۔",
    "خشک سمندری مخلوق",
    "بے گھر ڈمبو آکٹوپس",
    "گرین پیس مچھلی کا تیل روکتا ہے۔",
    "لمبی ٹانگوں والا گونگا بچایا۔",
    "تکلیف دہ سفر پاناما۔",
    "سمندری طوفان آئیڈا نے تباہی مچا دی۔",
    "ٹائیگر شارک کے فوائد",
    "یمن قدیم فلک بوس عمارت",
    "وکٹوریہ سکڑ جاتا ہے۔",
    "آئل سپل کلین ٹیم۔",
    "کولمبیا ایوکاڈو بوم۔",
    "کولمبیا سبز سونا۔",
    "سمندر کے کنارے کان کنی کا مقابلہ۔",
    "میٹ پلانٹ ورکرز۔",
    "جیزرو کرٹر لائف۔",
    "اسپیس ایکس سپلیش نیچے۔",
    "آثار قدیمہ ورچوئل پروازیں۔",
    "کوویڈ 19 گولی کی پیداوار",
    "روسی اداکار آئی ایس ایس۔",
    "ماہر آثار قدیمہ ناروے سکی",
    "پاکستان کا زلزلہ زخمی۔",
    "ہنٹنگٹن بیچ آئل۔",
    "باڈی کیم اوہائیو پولیس۔",
    "پینٹاگون نے ویڈیو لیک کردی۔",
    "کشیدگی چین تائیوان۔",
    "انٹیل گلوبل چپ بحران",
    "Cicada موسم شروع ہوتا ہے",
    "اشنکٹبندیی ڈپریشن ہینری۔",
    "اشنکٹبندیی نکولس تیار کریں۔",
    "طالبان بگرام ایئر بیس",
    "فلوریڈا کے سرفسائیڈ کو سکیڑیں۔",
    "خلا میں برینسن۔",
    "فلائی یلغار جمیکا۔",
    "سیاہ لاوا سوئمنگ پول۔",
    "لاوا نگلنے والا پول۔",
    "ڈبلیو ایچ او نے ملیریا ویکسین تجویز کی ہے۔",
    "چانگ 5 چاند کے نمونے",
    "چین کا دوبارہ اتحاد",
    "وائلنس انڈیا کسان احتجاج",
    "دہلی بجلی کا بحران",
    "سعودی قبضہ نیو کاسل",
    "پاکستانی زلزلہ",
    "پرانا تھراپوڈ ڈایناسور۔",
    "2 700 سال پرانا بیت القدس۔",
    "حملہ آور ایشین ہارنیٹ۔",
    "ٹیسلا گیگا فیکٹری ریو فیسٹیول۔",
    "گیگراوے۔",
    "چین رقص کرنے والی دادی",
    "آر پی 91 ہبل۔",
    "کینیا نے مقامی رقص پر پابندی عائد کردی۔",
    "کوارا کوارا غیر اخلاقی رقص۔",
    "گیلیپولی جہاز کا حادثہ",
    "تابکاری مریخ۔",
    "拉帕尔马火山喷发",
    "拉帕尔马加剧",
    "COP26气候大会",
    "Covid 助推器",
    "儿童疫苗",
    "全球野火",
    "美国最古老的脚印",
    "非洲甲龙",
    "Fuego火山喷发",
    "食用昆虫",
    "Titanokorys Gainesi 化石",
    "严重缺水",
    "候鸟撞上纽约摩天大楼",
    "最大的树木森林火灾",
    "山洪警报",
    "新加坡熊猫宝宝",
    "越南风暴",
    "澳大利亚地震",
    "逃跑的马群",
    "南澳大利亚天坑",
    "泥石流阻塞道路",
    "洪水袭击了德国和比利时",
    "英国脱欧木材短缺",
    "拉罗斯社区陷入泥潭",
    "湖水变红乔丹",
    "热带风暴山姆",
    "快速升温",
    "创纪录的高温",
    "飓风艾达",
    "新的covid变种",
    "平流层极涡",
    "海豚屠杀法罗群岛",
    "南极上空的臭氧空洞",
    "伊维菌素治疗",
    "伊拉克和叙利亚的干旱",
    "新的裂缝爆发",
    "全球废物审查",
    "基因编辑小鼠",
    "量子通讯网络",
    "鳄鱼",
    "考古学家人类符号",
    "全球蜜蜂衰退",
    "全球冰损失",
    "TOI-178系外行星",
    "制造透明木材",
    "人造卫星-V",
    "磷化氢金星",
    "甲烷火星",
    "亚马逊盆地排放",
    "钻火星",
    "火星车钻探火星",
    "格陵兰无冰",
    "比特币挖矿排放",
    "牵牛花弹出春天",
    "古细菌黄石公园",
    "博格斯",
    "黑洞背后的光",
    "生物打印肉",
    "Qeqertaq 阿凡纳莱克",
    "格陵兰新岛",
    "大陆最北端",
    "取样火星岩石",
    "人类足迹新墨西哥",
    "非法采矿作业",
    "加州漏油",
    "中国煤炭短缺",
    "气旋沙欣",
    "泰国洪水警告",
    "不断增长的食物沙漠",
    "罕见的阿拉伯豹",
    "猎户座流星雨",
    "太空旅游",
    "SpaceX 太空游客",
    "海地移民营得克萨斯州",
    "安格拉·默克尔下台",
    "圣保罗的搬迁服务",
    "从玻利维亚边境驱逐移民",
    "移民滞留在哥伦比亚边境",
    "男子在政府面前自焚",
    "外国投资防止移民",
    "萨尔萨舞者成为激进的抗议者",
    "英国货车司机危机",
    "国外舞团踮起脚尖",
    "世界上最盛大的舞蹈节",
    "圣保罗舞蹈爱好者",
    "动起来的地方",
    "特拉加入废除开发区的运动",
    "博尔索纳罗拒绝限制行动",
    "奉云棋开局",
    "巴西狂欢节",
    "多佛港抗议者",
    "享受免费公共交通",
    "阿姆斯特丹 Albert Heijn 商店关闭",
    "南非内乱",
    "LGBT学校学生抗议",
    "封锁加沙",
    "走钢丝的人埃菲尔铁塔",
    "大都会晚会",
    "旋转舞者",
    "白俄罗斯波兰边境",
    "美国发布不明飞行物事件",
    "弹出式自行车道",
    "米兰飞机失事",
    "选举前的气候集会",
    "阿富汗撤离航班受阻",
    "拉丁美洲的堕胎游行",
    "朝圣者纪念阿尔巴恩",
    "洪水过后无家可归",
    "墨尔本反疫苗抗议",
    "激进分子封锁壳牌的港口",
    "黄伞抗议",
    "在利比亚突袭中被拘留",
    "日益饥饿的黎巴嫩",
    "乌拉圭允许大麻进入监狱",
    "墨尔本反疫苗抗议",
    "疫苗接种中心出勤率低",
    "BioNTech 科学家获奖",
    "儿童新型冠状病毒疫苗",
    "Covid疫苗和月经变化",
    "“蛋白质陷阱”是科学突破",
    "哈瓦那综合症",
    "美国向低收入国家捐赠疫苗剂量",
    "微塑料的兴起",
    "应用商店禁止两周",
    "比特币在中国被禁止",
    "病毒性模因 NFT",
    "“匿名”运动泄露数据",
    "游戏鼓励青少年阅读",
    "华盛顿特区的生物恐怖主义实验室",
    "大士爆炸",
    "怀俄明州区块链踩踏事件",
    "Theranos 创始人伊丽莎白·霍姆斯受审",
    "苹果计划扫描用户图像",
    "化学蚂蚁工厂",
    "TikTok 面临大规模索赔",
    "大肠杆菌可再生能源",
    "中国游戏禁令",
    "飞马间谍软件",
    "埃及最年轻的微软人工智能专业人士",
    "基因编辑治疗艾滋病",
    "基因组编辑农业革命",
    "台湾展开生物防御演习",
    "新型植物性液态蛋",
    "三方遗传起源",
    "尼日利亚加密货币热潮",
    "Facebook参议院听证会青少年安全",
    "中国钍反应堆",
    "朱诺号飞船",
    "3D打印特斯拉",
    "意念控制乒乓球游戏",
    "半自动匠心",
    "脑机接口",
    "Landsat 9 卫星",
    "双小行星重定向测试",
    "破坏癌细胞的细菌",
    "在火星上耕种",
    "温度和触觉的神经传感器",
    "水星的第一张照片",
    "8岁巴西天文学家",
    "蝰蛇登陆",
    "变色龙人造皮肤",
    "送货机器人",
    "单站点太阳能园区",
    "将仿生学用于清洁水",
    "仿生中心",
    "网络多边形",
    "数码卡车",
    "特斯拉巨型电池",
    "3D打印器官",
    "应用程序扰乱医疗保健",
    "俄罗斯太空电影",
    "高超音速导弹试验",
    "AUPUS 协议",
    "从中国购买军用飞机",
    "国防区块链解决方案",
    "更多的钱用于防御",
    "塔利班入侵喀布尔",
    "国内极端组织",
    "飞马间谍软件",
    "美国撤军阿富汗",
    "是宣传",
    "中央情报局秘密喀布尔",
    "快速行动营",
    "德特里克堡中国阴谋",
    "全球税务包",
    "德国联邦选举",
    "安格拉·默克尔评级",
    "更高的公司税",
    "给老师更多的钱",
    "就口罩交易采取法律行动",
    "犹太团体抨击鲍德二战主张",
    "NL必须加快满足欧盟气候政策",
    "男孩，12 岁，赢得接种疫苗的权利",
    "是/是垃圾邮件贴纸",
    "住房危机大游行",
    "能源转型并非网络安全",
    "毛利党呼吁重命名新西兰",
    "弗雷泽岛更名为 K'gari",
    "巴西土著土地权",
    "鲍里斯约翰逊布偶",
    "绑架记者",
    "德克萨斯州的堕胎法",
    "日本岛屿争端",
    "苏丹政变失败",
    "阿富汗未开发的自然资源",
    "全球气候罢工",
    "墨西哥将堕胎合法化",
    "德克萨斯州将堕胎定为犯罪",
    "苏格兰四天工作周",
    "清除动物尸体",
    "潘多拉纸",
    "部长的车队杀死农民",
    "巴基斯坦无人机投放武器",
    "伊朗军队在阿塞拜疆军演",
    "催泪瓦斯发射",
    "拉帕尔马计算成本",
    "现实生活中的机器人警察",
    "跟踪中钢",
    "非法出售亚马逊雨林",
    "Facebook出售亚马逊雨林",
    "加州大旱",
    "刚果埃博拉",
    "黎巴嫩发电厂",
    "燃料短缺黎巴嫩",
    "英国没有汽油",
    "无人机袭击沙特机场",
    "埃塞俄比亚军队加强",
    "脸书大停电",
    "脸书又挂了",
    "塔利班 2.0",
    "德克萨斯州堕胎计划",
    "穿越达连峡",
    "巴拿马移民丛林",
    "盐沼拯救生命",
    "加州漏油",
    "受污染的桑德林",
    "深海采矿",
    "多金属海参",
    "大黑海山比目鱼",
    "触手可及的海洋生物",
    "流离失所的小飞象章鱼",
    "绿色和平组织停止使用鱼油",
    "获救的长腿秃鹰",
    "巴拿马的创伤之旅",
    "飓风伊达袭击",
    "虎鲨的好处",
    "也门古老的摩天大楼",
    "维多利亚瀑布收缩",
    "漏油清洁队",
    "哥伦比亚鳄梨热潮",
    "哥伦比亚绿金",
    "海底采矿大赛",
    "肉厂工人",
    "杰泽罗火山口生活",
    "Space-X 溅落",
    "考古虚拟飞行",
    "Covid-19药丸生产",
    "俄罗斯演员国际空间站",
    "考古学家挪威滑雪",
    "巴基斯坦地震受伤",
    "亨廷顿沙滩油",
    "Bodycam 俄亥俄州警察",
    "五角大楼泄露的视频",
    "紧张局势中国台湾",
    "英特尔全球芯片危机",
    "蝉的季节开始",
    "热带低气压亨利",
    "准备热带尼古拉斯",
    "塔利班巴格拉姆空军基地",
    "折叠冲浪佛罗里达州",
    "布兰森在太空",
    "苍蝇入侵牙买加",
    "黑色熔岩游泳池",
    "熔岩吞池",
    "世卫组织推荐疟疾疫苗",
    "嫦娥五号月球样本",
    "统一中国",
    "暴力印度农民抗议",
    "德里电力危机",
    "沙特收购纽卡斯尔",
    "巴基斯坦地震",
    "最古老的兽脚亚目恐龙",
    "2 700 年历史的耶路撒冷厕所",
    "入侵的亚洲大黄蜂",
    "特斯拉超级工厂狂欢节",
    "吉加拉夫",
    "中国 跳舞 奶奶",
    "Arp 91 哈勃",
    "肯尼亚禁止当地舞蹈",
    "Kwara Kwara 不道德的舞蹈",
    "加里波利沉船",
    "辐射火星",
    ]
}